<ng-container *ngIf="notification">
    <div class="row">
        <div class="col-lg-12">
            <span class="fs-5">Hier kan je een lijst samenstellen van alle gemeenten waarvan je op de hoogte wil gehouden worden via het e-mailadres <strong>{{notification.email}}</strong>.</span>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-lg-12">
            <table class="table table-striped mb-0">
                <thead>
                    <tr>
                        <th scope="col" class="fs-5">Gemeente</th>
                        <th scope="col" class="text-end">
                            <button class="btn btn-sm btn-danger rounded-pill px-3" (click)="delete()" [disabled]="isLoading">
                                <span *ngIf="isLoading"><i class="fas fa-circle-notch fa-spin me-2"></i></span>
                                <span *ngIf="!isLoading"><i class="fas fa-trash me-2"></i></span>Alles wissen
                          </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let njp of notification.notificationJobPostcodes">
                        <td class="ps-2">{{njp.jobPostcode.postcode}}&nbsp;&nbsp;{{njp.jobPostcode.name}}</td>
                        <td class="text-end pe-3">
                            <a (click)="deletePostcode(njp.jobPostcodeId)" class="ms-3">
                                <i class="fas fa-trash text-secondary" title="{{njp.jobPostcode.postcode}} {{njp.jobPostcode.name}} verwijderen"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr class="border-bottom-0">
                        <td colspan="3" class="border-bottom-0">
                            <app-postcode-select [navigateOnSelect]="false" [showButton]="true" [inputClass]="'input-sm w-100'" [buttonName]="'Toevoegen'" [buttonClass]="'btn pxp-section-cta rounded-pill px-3 py-1'" (onSelect)="addPostcode($event)"></app-postcode-select>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</ng-container>