<div *ngIf="organization">
    <div *ngIf="organization.facebookUrl" class="me-4 fs-3 d-inline-block">
        <a [attr.href]="organization.facebookUrl" target="_blank"><span class="fa-brands fa-facebook"></span></a>
    </div>
    <div *ngIf="organization.twitterUrl" class="me-4 fs-3 d-inline-block">
        <a [attr.href]="organization.twitterUrl" target="_blank"><span class="fa-brands fa-twitter"></span></a>
    </div>
    <div *ngIf="organization.instagramUrl" class="me-4 fs-3 d-inline-block">
        <a [attr.href]="organization.instagramUrl" target="_blank"><span class="fa-brands fa-instagram"></span></a>
    </div>
    <div *ngIf="organization.linkedIn" class="me-4 fs-3 d-inline-block">
        <a [attr.href]="organization.linkedIn" target="_blank"><span class="fa-brands fa-linkedin"></span></a>
    </div>
</div>