import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { JobAudience } from 'src/models/jobAudience';
import { JobSector } from 'src/models/jobSector';
import { NotificationDto } from '../app/main/notification/models/notification';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService<NotificationDto, NotificationDto> {

  constructor(protected override http: HttpClient, @Inject('BASE_URL') protected override baseUrl: string) {
    super(http, baseUrl, 'Notification');
  }

  getById(key: string): Observable<NotificationDto> {
    return super.get(`/bykey/${key}`);
  }

  add(item: NotificationDto) {
    return this.http.post(this.baseUrl + this.controller + "", item);
  }

  deleteByKey(accessKey: string): Observable<NotificationDto> {
    return this.http.delete<NotificationDto>(this.baseUrl + this.controller + `/${accessKey}`);
  }
  addPostcode(accessKey: string, postcodeId: number): Observable<NotificationDto> {
    return this.http.post<NotificationDto>(this.baseUrl + this.controller + `/${accessKey}/postcode/${postcodeId}`, null);
  }

  deletePostcode(accessKey: string, postcodeId: number): Observable<NotificationDto> {
    return this.http.delete<NotificationDto>(this.baseUrl + this.controller + `/${accessKey}/postcode/${postcodeId}`);
  }

  confirm(accessKey: string): Observable<NotificationDto> {
    return this.http.put<NotificationDto>(this.baseUrl + this.controller + `/${accessKey}/confirm`, null);
  }
}
