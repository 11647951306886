import { Injectable } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { WidgetData } from "../models/jobOfferWidgetData";
import { LocationService } from "./location.service";


@Injectable({
  providedIn: 'root'
})
export class FilterFormService {

  searchForm!: SearchForm;
  constructor() {
  }

  getFilterForm(latitude = 50.85045, longitude = 4.34878): SearchForm {
    if (!this.searchForm) {
      this.searchForm = new SearchForm(latitude, longitude);
    } else {
      this.searchForm.latitude.patchValue(latitude);
      this.searchForm.longitude.patchValue(longitude);
    }
    return this.searchForm;
  }
}


export class SearchForm extends FormGroup {

  private defaultFom: FormGroup;
  constructor(latitude = 50.85045, longitude = 4.34878, readonly fb: FormBuilder = new FormBuilder()) {
    let defaultFom = new FormGroup({
      //zoekterm
      name: new FormControl(''),
      orderBy: new FormControl(''),

      //locatie
      location: new FormControl(''),
      latitude: new FormControl(latitude.toString()),
      longitude: new FormControl(longitude.toString()),
      distance: new FormControl(20),
      fromHome: new FormControl(false),
      onLocation: new FormControl(true),
      withDistance: new FormControl(false),
      post: new FormControl('', { updateOn: 'blur' }),
      locations: new FormControl(''),
      organizations: new FormControl(''),
      organizationIds: new FormControl(''),


      //jobinhoud
      sectors: new FormControl(''),
      audiences: new FormControl(''),
      talents: new FormControl(''),
      themes: new FormControl(''),
      collab: new FormControl(''),

      digihelpers: new FormControl(''),//apart veld voor de weldoenersfilters


      //wanneer
      frequency: new FormControl(''),
      from: new FormControl(''),
      till: new FormControl(''),
      timespan: new FormControl(''),

      //weldoeners meerdere timespans
      timeFrames: new FormControl(''),

      //toegankelijkheid
      canPracticeDutch: new FormControl(false),
      noDutch: new FormControl(false),
      disability: new FormControl(false),
      extraDisability: new FormControl(false),
    });
    super(defaultFom.controls);
    this.defaultFom = defaultFom;
  }

  readonly name = this.get("name") as FormControl;
  readonly location = this.get("location") as FormControl;
  readonly locations = this.get("locations") as FormControl;
  readonly sectors = this.get("sectors") as FormControl;
  readonly audiences = this.get("audiences") as FormControl;
  readonly talents = this.get("talents") as FormControl;
  readonly orderBy = this.get("orderBy") as FormControl;
  readonly latitude = this.get("latitude") as FormControl;
  readonly longitude = this.get("longitude") as FormControl;
  readonly distance = this.get("distance") as FormControl;
  readonly fromHome = this.get("fromHome") as FormControl;
  readonly onLocation = this.get("onLocation") as FormControl;
  readonly withDistance = this.get("withDistance") as FormControl;
  readonly post = this.get("post") as FormControl;
  readonly frequency = this.get("frequency") as FormControl;
  readonly from = this.get("from") as FormControl;
  readonly till = this.get("till") as FormControl;
  readonly canPracticeDutch = this.get("canPracticeDutch") as FormControl;
  readonly disability = this.get("disability") as FormControl;
  readonly timespan = this.get("timespan") as FormControl;
  readonly collab = this.get("collab") as FormControl;
  readonly extraDisability = this.get("extraDisability") as FormControl;
  readonly themes = this.get("themes") as FormControl;
  readonly noDutch = this.get("noDutch") as FormControl;
  readonly organizations = this.get("organizations") as FormControl;
  readonly organizationIds = this.get("organizationIds") as FormControl;
  readonly digihelpers = this.get("digihelpers") as FormControl;
  readonly timeFrames = this.get("timeFrames") as FormControl;


  resetToDefault() {
    this.patchValue(this.defaultFom.value);
  }

  setDefaultLocation(latitude: number, longitude: number) {
    this.defaultFom.patchValue({
      latitude: latitude.toString(),
      longitude: longitude.toString()
    });
    this.latitude.patchValue(latitude);
    this.longitude.patchValue(longitude);
  }


  setDefaultForWidget(widget: WidgetData, locService: LocationService) {
    if (widget) {
      if (widget.filters.searchTerm)
        this.defaultFom.patchValue({ name: widget.filters.searchTerm });
      this.defaultFom.patchValue({ canPracticeDutch: widget.filters.practice_dutch });
      this.defaultFom.patchValue({ noDutch: widget.filters.no_dutch });
      this.defaultFom.patchValue({ extraDisability: widget.filters.accessibility });

      this.onLocation.patchValue(false);
      this.withDistance.patchValue(false);
      if (widget.filters.locationType == "locations") {
        if (widget.filters.locations)
          this.defaultFom.patchValue({ locations: widget.filters.locations });
      } else {
        if (widget.filters.locationType == "nolocation") {
          this.defaultFom.patchValue({ onLocation: false })
          this.defaultFom.patchValue({ withDistance: false })
        } else {
          this.defaultFom.patchValue({ post: widget.filters.locations[0] });
          if (widget.filters.aroundRadius)
            this.defaultFom.patchValue({ distance: widget.filters.aroundRadius / 1000 });
          this.defaultFom.patchValue({ onLocation: true })
          this.defaultFom.patchValue({ withDistance: true })
        }
      }
      this.defaultFom.patchValue({ sectors: widget.filters.sectors });
      this.defaultFom.patchValue({ audiences: widget.filters.targetAudiences });
      this.defaultFom.patchValue({ talents: widget.filters.talents });
      this.defaultFom.patchValue({ themes: widget.filters.themes });
      if (widget.filters.organizationModels) {
        var ids = [];
        for (var i = 0; i < widget.filters.organizationModels.length; i++) {
          ids.push(widget.filters.organizationModels[i].id)
          this.defaultFom.patchValue({ organizationIds: ids });
        }
      } else {
        if (widget.filters.organizations) {
          for (var i = 0; i < widget.filters.organizations.length; i++) {
            if (widget.filters.organizations[i].indexOf("%20") == -1) //om dubbele encoding te voorkomen bij server side rendering
              widget.filters.organizations[i] = encodeURIComponent(widget.filters.organizations[i]);
          }
          this.defaultFom.patchValue({ organizations: widget.filters.organizations });
        }
      }
    }
  }

}
