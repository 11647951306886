import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(DOCUMENT) private _document: Document) { 
  }

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)) {
      this._document.onreadystatechange = () => {
        if (this._document.readyState === 'complete') {
          this._document.body.style.visibility = 'visible';
        }
      } 
    }
  }

}
