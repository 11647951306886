import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { JobAudience } from 'src/models/jobAudience';
import { JobSector } from 'src/models/jobSector';

@Injectable({
  providedIn: 'root'
})
export class ApplicationSettingsService {

  constructor(protected http: HttpClient, @Inject('BASE_URL') protected baseUrl: string) {
  }

  isOnline() {
    return this.http.get<boolean>(this.baseUrl + "ApplicationSettings/online");

  }
}
