<app-navbar></app-navbar>

<section class="mt-100 mb-100">
    <div class="pxp-container text-center pt-4">
        <h2 class="pxp-section-h2">Algemene gebruiksvoorwaarden</h2>
    </div>
    <div class="pxp-container text-start" style="margin-bottom: 100px;">
        <br /><br />
        <h3>1. Algemeen</h3>
        <p>
            Bedankt dat je de website www.kanikhelpen.be ("Website") bezoekt. De Website is een initiatief van het <a href="https://www.vlaanderenvrijwilligt.be/" target="_blank">Vlaams Steunpunt Vrijwilligerswerk vzw</a> met ondersteuning van de Vlaamse
            overheid.
        </p>
        <p>
            Het doel van de website is vrijwilligers in contact brengen met lokale besturen die een oproep lanceren voor vrijwilligers in het kader van een noodsituatie of het opbouwen van een pool van vrijwilligers die kunnen ingezet worden voor dergelijke situaties.
            De Website is alleen bedoeld voor het individueel zoeken naar een vrijwilligersactiviteit voor natuurlijke personen in het vrijwilligersstatuut.
        </p>
        <p>
            De toegang tot en/of het gebruik van de Website en/of de daarop geplaatste informatie, zoals berichten en/of commentaar, en/of de via daarmee verleende diensten impliceert dat je je als bezoeker/gebruiker volledig bewust en geïnformeerd verklaart met
            alle voorwaarden en beperkingen zoals hierin vermeld en dat je deze integraal en zonder enig voorbehoud aanvaardt. Indien u onze gebruiksvoorwaarden niet accepteert, kunt u geen gebruik maken van de Website en vragen wij u om u te onthouden
            van verder gebruik ervan.
        </p>
        <p>
            Het Vlaams Steunpunt Vrijwilligerswerk behoudt zich het recht voor om deze voorwaarden steeds zonder kennisgeving te wijzigingen. De aangepaste voorwaarden worden bekendgemaakt op de Website en zullen automatisch van kracht worden na verschijning ervan.
        </p>
        <br />
        <h3>2. Onderwerp van de overeenkomst</h3>
        <ol>
            <li>
                Als u als gebruiker/vrijwilliger uw gegevens op de website invoert, verklaart u zich akkoord dat deze gegevens kunnen worden gedeeld of doorgegeven aan het gekozen lokaal bestuur of specifieke oproep van een lokaal bestuur.
            </li>
            <li>
                Gebruikers/vrijwilligers kunnen meldingen ontvangen van oproepen die overeenstemmen met de gekozen. Gebruikers/vrijwilligers kunnen ook gecontacteerd worden in het kader van een bevraging.
            </li>
            <li>
                De gebruiker/lokaal bestuur is akkoord dat haar gegevens, ingegeven in de toepassing Assist, inclusief contactgegevens worden gepubliceerd op de website, en toegankelijk zijn voor elke gebruiker van de website. Gebruikers/vrijwilligers kunnen anoniem
                de website bezoeken.
            </li>
            <li>
                Het lokaal bestuur verbindt zich ertoe de ontvangen gegevens van vrijwilligers enkel te gebruiken voor de afwikkeling van de kandidaatstelling voor een oproep/noodsituatie, en het Vlaams Steunpunt Vrijwilligers vzw bij te staan in geval van de uitoefening
                van de rechten op gegevensbescherming van de betrokken vrijwilliger.
            </li>
        </ol>

        <br />
        <h3>3. Verplichtingen en verantwoordelijkheden van de gebruiker van de Website en de daaraan gekoppelde databank</h3>
        <ol>
            <li>
                De Website is enkel bestemd voor bezoekers/gebruikers natuurlijke personen (vrijwilligers) vanaf de leeftijd van 16 jaar en lokale besturen) en kan enkel worden gebruikt/bezocht na aanvaarding van alle voorwaarden en beperkingen hierin vermeld.
            </li>
            <li>
                De bezoeker/gebruiker garandeert dat de gegevens die deze meedeelt juist en volledig zijn, rechtmatig en behoorlijk zijn verkregen en rechtsgeldig door Vlaams Steunpunt Vrijwilligers vzw kunnen verwerkt worden. Het meedelen van valse identiteiten, onvolledige
                of onjuiste gegevens, dan wel gegevens die toebehoren aan derden die de bezoeker/gebruiker daartoe geen toestemming hebben gegeven, kan ertoe leiden dat de bezoeker/gebruiker, tijdelijk of definitief, verwijderd worden uit de database
                zodat er geen verdere verwerking zal gebeuren.
            </li>
            <li>
                Op deze Website kan gratis en autonoom informatie over oproepen (in geval van noodsituaties) voor vrijwilligers worden gepost, aangepast, verwijderd, geraadpleegd en opgezocht.
            </li>
            <li>
                De bezoeker/gebruiker van de Website is zich ervan bewust en aanvaardt dat het Vlaams Steunpunt Vrijwilligerswerk steeds en zonder enige kennisgeving:
                <ol>
                    <li>
                        de Website en de inhoud ervan automatisch kan updaten en dat de voorwaarden en beperkingen hierin bepaald automatisch daarop van toepassing zullen zijn;
                    </li>
                    <li>
                        de door de bezoeker/gebruiker bezorgde inhoud of informatie, die is gepost, geplaatst of verstuurd via de Website kan wijzigen of verwijderen volgens de eigen discretionaire beslissing van het Vlaams Steunpunt Vrijwilligerswerk.
                    </li>
                    <li>
                        Bezoekers/gebruikers die langer dan 2 jaar inactief zijn, kunnen verwijderd worden zonder voorafgaandelijke toestemming of kennisgeving van de gebruiker.
                    </li>
                </ol>
            </li>
            <li>
                Het Vlaams Steunpunt Vrijwilligerswerk kan op ieder ogenblik en zonder enige termijn, een gebruiker/bezoeker de toegang en/of het gebruik van de Website beletten en verbieden.
            </li>
            <li>
                Aan de Website is een databank van oproepen gekoppeld die door alle bezoekers/gebruikers kunnen geraadpleegd worden die op zoek gaan naar een gepaste oproep of invulling daarvan. Deze oproepen worden aangemaakt door lokale besturen via de toepassing Assist.
                Het Vlaams Steunpunt Vrijwilligerswerk is niet aansprakelijk voor de inhoud van de oproepen, zoekertjes, reacties of de berichten die via de Website worden uitgewisseld. Reageren en berichten insturen, gebeurt steeds op eigen risico en
                de gebruiker/bezoeker is zich hiervan bewust.
            </li>
            <li>
                Indien een bezoeker/gebruiker van de Website meent dat bepaalde informatie onrechtmatig of aanstootgevend is, kan die contact opnemen met het Vlaams Steunpunt Vrijwilligerswerk via e-mail: info@vsvw.be. In geval van onwettig gedrag van een bezoeker/gebruiker,
                kunnen de persoonlijke gegevens van die bezoeker/gebruiker doorgegeven worden aan de bevoegde (gerechtelijke) instanties.
            </li>
        </ol>

        <br />
        <h3>4. Intellectuele eigendom</h3>
        <ol>
            <li>
                De Website, zowel de structuur als de inhoud, zoals, maar niet beperkt tot, teksten, tekens, databanken, berichten, gegevens, commentaar, afbeeldingen, benamingen, foto’s en software zijn beschermd door intellectuele eigendomsrechten. Elke rechtstreekse
                of onrechtstreekse inbreuk op deze rechten is strikt verboden. Van de Website mag niets worden gewijzigd, vermenigvuldigd, overgedragen, verdeeld, verspreid of gereproduceerd, als metatag op andere websites worden gebruikt of op enige
                andere wijze worden overgedragen aan derden of daarop gebruiksrechten worden verleend zonder de uitdrukkelijke en voorafgaande schriftelijke toestemming van de respectievelijke rechthebbende (Vlaams Steunpunt Vrijwilligerswerk). Zo is
                het onder meer verboden zonder voorafgaand schriftelijk akkoord van de rechthebbende informatie van de Website of de Website zelf op te slaan, te reproduceren, te wijzigen, openbaar te maken, gratis of tegen betaling te distribueren of
                te verzenden op een wijze die indruist tegen de intellectuele eigendomsrechten.
            </li>
            <li>
                Op geen enkele manier zal er via de Website enig (intellectueel) eigendomsrecht vanop de Website of de informatie daarop aan de bezoeker/gebruiker ervan overdragen.
            </li>
            <li>
                Elke bezoeker/gebruiker van de Website verleent door het posten, plaatsen of versturen van informatie via de Website op alle intellectuele eigendomsrechten op voormelde informatie een overdraagbaar, onherroepelijk, niet-exclusief gebruiksrecht aan het
                Vlaams Steunpunt Vrijwilligerswerk en alle bezoekers/gebruikers van de Website, wereldwijd, kosteloos en voor de volledige duur van de respectievelijke rechten teneinde op of via de Website te worden gebruikt voor het doel van de Website
                op om het even welke exploitatiewijze (zoals het reproduceren, ontwikkelen, opslaan, weergeven, gebruiken, distribueren, publiceren wijzigen, vervaardigen van afgeleide werken, aanpassen, verwijderen enz.). De bezoeker/gebruiker doet hierbij
                uitdrukkelijk afstand van het recht om het vaderschap op de voormelde informatie door hem op de Website geplaatste informatie uit te oefenen. De bezoeker/gebruiker geeft hierbij de uitdrukkelijke toestemming aan het Vlaams Steunpunt Vrijwilligerswerk
                om alle wijzigingen uit te voeren, met inbegrip van vernietigingen, die nuttig of noodzakelijk zijn voor het normale gebruik van de hierboven vermelde informatie op of via de Website. De bezoeker/gebruiker doet uitdrukkelijk afstand van
                zijn/haar recht om zich tegen dergelijke wijzigingen te verzetten, tenzij hij/zij aantoont dat zij afbreuk doen aan zijn/haar eer of reputatie.
            </li>
            <li>
                Iedere gebruiker/bezoeker erkent en garandeert dat hij/zij over de nodige rechten beschikt en vrijwaart het Vlaams Steunpunt Vrijwilligerswerk voor alle schade die zou ontstaan wegens een (beweerde) inbreuk op de rechten van derden in dit verband. Voor
                zover als nodig maakt de bezoeker/gebruiker zich sterk dat de respectievelijke rechthebbende bepalingen in dit artikel 4 naleeft.
            </li>
            <li>
                Volgens de voorwaarden en beperkingen hierin bepaald en voor zover het Vlaams Steunpunt Vrijwilligerswerk hierover kan beschikken, verleent het Vlaams Steunpunt Vrijwilligerswerk jou als gebruiker/bezoeker een niet-overdraagbaar, herroepelijk, niet-exclusief
                gebruiksrecht op alle intellectuele eigendomsrechten op de Website om deze wereldwijd, kosteloos en voor de volledige duur van de respectievelijke rechten te gebruiken voor het doel van de Website op om het even welke exploitatiewijze.
            </li>
        </ol>

        <br />
        <h3>5. Aansprakelijkheid</h3>
        <ol>
            <li>
                Hoewel het Vlaams Steunpunt Vrijwilligerswerk streeft naar een zorgvuldige controle van de informatie die op de Website wordt geplaatst, worden de Website en de erin vervatte informatie ter beschikking gesteld zonder enige garantie op bv. correctheid
                of volledigheid. Voor zover de wet het toelaat, kunnen het Vlaams Steunpunt Vrijwilligerswerk noch haar medewerkers aansprakelijk worden gesteld voor enige rechtstreekse of onrechtstreekse schade, in materiële of immateriële vorm, of enige
                vorm van nadeel voortvloeiend uit of samenhangend met de toegang tot, het gebruik of het bezoek van de Website of haar inhoud, ongeacht de specifieke oorzaak van de schade, zoals maar niet beperkt tot:
                <ol>
                    <li>
                        de inhoud op de Website, zoals hetgeen door de bezoekers/gebruikers erop is geplaatst. Het Vlaams Steunpunt Vrijwilligerswerk geeft bijgevolg geen enkele garantie over en kan niet aansprakelijk worden gesteld voor o.a. de nauwkeurigheid, kwaliteit, regel-,
                        of rechtmatigheid en veiligheid van geplaatste berichten of commentaar of van hetgeen op de Website wordt aangeboden of getoond of de geschiktheid voor een bepaald doel.
                    </li>
                    <li>
                        enige handeling of beslissing die bezoekers/gebruikers via de Website of naar aanleiding van het gebruik van de Website verrichten;
                    </li>
                    <li>
                        de ononderbroken of voldoende toegang tot of beveiliging van deze Website;
                    </li>
                    <li>
                        enige schade die zou ontstaan als gevolg van het gebruik van de Website, zoals maar niet beperkt tot gederfde winst of verlies van gegevens of schade aan computerapparatuur;
                    </li>
                    <li>
                        de overdracht van virussen, ondanks eventuele veiligheidsmaatregelen;
                    </li>
                    <li>
                        schade ontstaan door een geval van overmacht.
                    </li>
                </ol>
            </li>
            <li>
                Deze bepaling is, onder meer, van toepassing op schade zoals winstderving, inkomstenverlies, onderbreking van de activiteiten, verlies of beschadiging van gegevens, morele schade, verlies van imago, frustratieschade of verlies van opportuniteiten.
            </li>
            <li>
                Het Vlaams Steunpunt Vrijwilligerswerk kan niet garanderen dat de inhoud van de Website, zoals maar niet beperkt tot teksten, tekens, databanken, berichten, gegevens, commentaar, afbeeldingen, benamingen, foto’s en software enz., nauwkeurig, waarheidsgetrouw,
                legaal, volledig of geschikt zijn voor een bepaald doel. Op om het even welk moment kunnen er onnauwkeurigheden, onrechtmatigheden, onregelmatigheden, weglatingen of informatie die niet up-to-date zijn, waarvoor Vlaams Steunpunt Vrijwilligerswerk,
                noch zijn medewerkers, aansprakelijk kunnen worden gesteld. De bezoeker/gebruiker erkent uitdrukkelijk dat voor de inhoud van de Website, de aansprakelijkheid uitsluitend rust bij diegene die deze inhoud op de Website heeft geplaatst of
                deze heeft uitgewisseld.
            </li>
            <li>
                Websites van derden kunnen verwijzingen of links aanmaken of bevatten naar de Website. De Website kan op haar beurt ook verwijzingen of links bevatten naar externe websites of bronnen die geen eigendom of niet in het beheer zijn van Vlaams Steunpunt Vrijwilligerswerk.
                In geen geval staat het Vlaams Steunpunt Vrijwilligerswerk garant of kan zij aansprakelijk worden gesteld voor de beschikbaarheid, de veiligheid of de kwaliteit van deze externe websites en/of hun bronnen of inhoud.
            </li>
            <li>
                In ieder geval is de eventuele aansprakelijkheid van het Vlaams Steunpunt Vrijwilligerswerk voor enige schade ten opzichte van de gebruikers/bezoekers van de Website of enige derde beperkt tot 2.500 euro.
            </li>
            <li>
                Het Vlaams Steunpunt Vrijwilligerswerk behoudt zich het recht voor om op elk moment, zonder voorafgaande kennisgeving en zonder enige aansprakelijkheid eender welk gedeelte van de op de Website verstrekte informatie te vervolledigen, te schrappen, te
                verbeteren of op een andere manier te wijzigen of om iemand de toegang of het gebruik van de Website te beletten, weigeren of verhinderen.
            </li>
        </ol>

        <br />
        <h3>6. Vertrouwelijkheid en gegevensbescherming</h3>
        <p>
            De Website behandelt uw persoonsgegevens vertrouwelijk. Het verzamelen en verwerken van de persoonlijke gegevens van de gebruikers/vrijwilligers wordt uitgevoerd in overeenstemming met de toepasselijke wetgeving inzake gegevensbescherming. Meer informatie
            over het gebruik van persoonlijke gegevens op de Website is te vinden in privacyverklaring van de Website.
        </p>

        <br />
        <h3>7. Toegankelijkheid</h3>
        <p>
            Als Vlaams Steunpunt Vrijwilligerswerk vzw promoten wij de toegankelijkheid van het vrijwilligerswerk voor iedereen. Soms is de drempel om te vrijwilligen voor bepaalde doelgroepen toch nog te hoog. Daarom voorzien we filters voor anderstaligen en personen
            met een beperking, die hen kunnen helpen bij het vinden van passend vrijwilligerswerk. Op die manier vergemakkelijken we de zoektocht voor deze vrijwilligers, en proberen we lokale besturen bewust te maken van de noodzaak tot toegankelijk
            en divers vrijwilligerswerk. Lokale besturen die deze filters niet gebruiken, geven daarmee uiteraard niet impliciet aan dat ze niet open staan voor deze doelgroepen. Mocht je daar als vrijwilliger toch een negatieve ervaring mee oplopen,
            aarzel niet ons te contacteren via <a href="mailto:info@vsvw.be">info@vsvw.be</a>.
        </p>

        <br />
        <h3>8. Contact</h3>
        <p>
            Indien je vragen of opmerkingen over deze gebruiksvoorwaarden hebt, kan je ons altijd contacteren via e-mail: <a href="mailto:info@vsvw.be">info@vsvw.be</a>.
        </p>

        <br />
        <h3>9. Geschillen</h3>
        <ol>
            <li>
                Door jezelf toegang te verschaffen tot de Website, ga je als bezoeker/gebruiker ermee akkoord dat alle zaken tussen jou als bezoeker/gebruiker en het Vlaams Steunpunt Vrijwilligerswerk in verband met deze Website exclusief worden beheerst door het Belgische
                recht.
            </li>
            <li>
                Elk geschil in verband met deze Website behoort tot de exclusieve bevoegdheid van de rechtbanken Antwerpen, afdeling Antwerpen, met uitsluiting van iedere andere rechtbank.
            </li>
        </ol>
        <br /> Huidige versie: 19 november 2023
    </div>
</section>