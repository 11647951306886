import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobOffer } from 'src/models/jobOffer';
import { VolunteerModalComponent } from '../volunteer-modal/volunteer-modal.component';

@Component({
  selector: 'app-volunteer-card',
  templateUrl: './volunteer-card.component.html',
  styleUrls: ['./volunteer-card.component.scss']
})
export class VolunteerCardComponent implements OnInit {
  @Input() volunteer!: JobOffer;
  currentYear = new Date().getFullYear();
  constructor(@Inject('IMAGE_SERVER_URL') public imageUrl: string, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  selectVolunteer(job: JobOffer): void {
    let modal = this.modalService.open(VolunteerModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'pxp-user-modal' });
    modal.componentInstance.job = job;
  }


  getUrl(url: string) {
    if (url && url.indexOf('http') > -1) {
      return url;
    }
    else
      return this.imageUrl + url
  }

}
