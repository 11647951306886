import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobOffer } from 'src/models/jobOffer';
import { Organization } from 'src/models/organization';
import { JobService } from 'src/services/job.service';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  public organization!: Organization;
  public jobs!: JobOffer[];
  public totalJobs: number = 0;
  public totalVolunteers: number = 0;
  public volunteers!: JobOffer[];

  constructor(private title: Title, private route: ActivatedRoute, @Inject('IMAGE_SERVER_URL') public imageUrl: string, private jobService: JobService, private router: Router, private modalService: NgbModal) {
    this.route.data.subscribe(({ organization }) => {
      this.organization = organization;
      this.title.setTitle(`Vrijwilligerswerk - ${this.organization.name}`);
    })
  }

  ngOnInit(): void {
    this.jobService.getJobsByOrganization(this.organization.id).subscribe(jobs => {
      this.jobs = jobs.value;
      this.totalJobs = jobs['@odata.count'];
    });
    this.jobService.getVolunteersByOrganization(this.organization.id, 2).subscribe(volunteers => {
      this.volunteers = volunteers.value;
      this.totalVolunteers = volunteers['@odata.count'];
    });
  }

  getUrl(url: string) {
    if (url && url.indexOf('http') > -1) {
      return url;
    }
    else
      return this.imageUrl + url
  }

  scrollToJobs() {
    this.router.navigate([], { fragment: "organisation-vacatures" });
  }

  contactForm() {
    let modal = this.modalService.open(ContactModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, windowClass: 'pxp-user-modal' });
    modal.componentInstance.orgId = this.organization.id
    modal.componentInstance.organization = this.organization
  }


}
