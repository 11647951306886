<div class="pxp-jobs-card-1 pxp-has-border pxp-has-shadow">
  <div class="pxp-jobs-card-1-top">
    <a (click)="selectJob(job)" class="pxp-jobs-card-1-title fs-3" type="button">{{ job.name }}</a>
    <div class="pxp-jobs-card-1-details">
      <table style="width: 100%;">
        <tr *ngIf="!featured">
          <td style="width: 35px; text-align: center;">
            <h5 class="mb-0"><i class="fa-regular fa-handshake me-2"></i></h5>
          </td>
          <td>
            <h5 class="mb-0">Korte omschrijving</h5>
          </td>
        </tr>
        <tr>
          <td>
          </td>
          <td *ngIf="!featured" class="pe-md-5 mb-4">
            <div class="mb-4">
              <span [innerHtml]="job.description"></span>
            </div>
          </td>
          <td *ngIf="featured">
            <span [innerHtml]="job.description"></span>
          </td>
        </tr>
        <tr *ngIf="!featured && job.jobLocation">
          <td style="width: 35px; text-align: center;">
            <h5 class="mb-0"><i class="fa-solid fa-location-dot me-2"></i></h5>
          </td>
          <td>
            <h5 class="mb-0">Waar</h5>
          </td>
        </tr>
        <tr *ngIf="!featured && job.jobLocation">
          <td>
          </td>
          <td class="pe-md-5">
            <div class="mb-4">
              <app-job-location [job]="job"></app-job-location>
            </div>
          </td>
        </tr>
        <tr *ngIf="!featured">
          <td style="width: 35px; text-align: center;">
            <h5 class="mb-0"><i class="fa-regular fa-calendar me-2"></i></h5>
          </td>
          <td>
            <h5 class="mb-0">Wanneer</h5>
          </td>
        </tr>
        <tr *ngIf="!featured">
          <td>
          </td>
          <td class="pe-md-5">
            <app-job-moment [job]="job"></app-job-moment>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div class="pxp-jobs-card-1-bottom d-block mt-0">
    <div *ngIf="!featured">
      <table style="width: 100%;">
        <tbody>
          <tr>
            <td class="pxp-jobs-card-1-location">
              <div>
                <div class="pxp-jobs-card-1-details d-block text-center">
                  <a (click)="contactForm(job)" class="btn btn-sm rounded-pill pxp-section-cta px-3 py-1 fs-6 my-2">Kan ik helpen?</a>
                </div>
              </div>

              <div *ngIf="job.hasBeenFilled">
                <div class="pxp-jobs-card-1-details d-block text-center text-sm-start">
                  <a class="btn btn-sm btn-success rounded-pill px-3 py-1 fs-6 my-2 me-sm-3" (click)="contactForm(job)" role="button">Vacature ingevuld</a>
                </div>
              </div>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="featured">
      <div class="row d-lg-none">
        <div class="col-3">
        </div>
        <div class="col-6 text-center">
          <img src="../../../../assets/images/logo-korps.jpg" style="height: 75px;">
        </div>
        <div class="col-3 text-end">

        </div>
      </div>
      <div class="row">
        <div class="col-3">
        </div>
        <div class="col-6">
          <div class="d-block pxp-jobs-card-1-details text-center pt-3">
            <!-- HIER MOETEN WE DE URL NOG WIJZIGEN NAAR DE VARIABELE WAARDE -->
            <a href="{{job.attachmentUrl}}" target="_blank" class="btn btn-sm rounded-pill pxp-section-cta px-3 py-1 fs-6 my-2 me-sm-3" role="button">Meer info</a>
            <span class="d-sm-none"><br /></span>
            <a (click)="contactForm(job)" class="btn btn-sm rounded-pill pxp-section-cta px-3 py-1 fs-6 my-2">Kan ik helpen?</a>
          </div>
        </div>
        <div class="col-3 text-end d-none d-lg-block">
          <img class="" src="../../../../assets/images/logo-korps.jpg" style="height: 75px;" />
        </div>
      </div>
    </div>
  </div>
