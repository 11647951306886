<div class="dropdown">
    <button class="btn pxp-single-job-save-btn dropdown-toggle" id="favoriteBtn" data-bs-toggle="dropdown" aria-expanded="false" *ngIf="!isFavorite()"><span class="fa-regular fa-heart"></span></button>
    <button class="btn pxp-single-job-save-btn dropdown-toggle" id="favoriteBtn" data-bs-toggle="dropdown" aria-expanded="false" *ngIf="isFavorite()"><span class="fa fa-heart text-danger"></span></button>
    <ul class="dropdown-menu pxp-single-job-share-dropdown" aria-labelledby="favoriteBtn">
        <li *ngIf="!isFavorite()">
            <a class="dropdown-item" type="button" (click)="favoriteService.addFavorite(job.id)">Voeg toe in favorieten</a>
        </li>
        <li *ngIf="isFavorite()">
            <a class="dropdown-item" type="button" (click)="favoriteService.removeFavorite(job.id)">Verwijder uit favorieten</a>
        </li>
        <li>
            <a (click)="closeModals()" class="dropdown-item" routerLink="/favorieten">Bekijk favorieten</a>
        </li>
    </ul>
</div>