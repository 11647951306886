import { Component, Input, OnInit } from '@angular/core';
import { Organization } from 'src/models/organization';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent implements OnInit {
  @Input() organization!: Organization;
  constructor() { }

  ngOnInit(): void {
  }

}
