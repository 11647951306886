import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ODataResponse } from 'src/models/OData';
import { JobOffer } from 'src/models/jobOffer';
import { JobTheme } from 'src/models/jobTheme';
import { getTimeSpanById } from 'src/models/timeSpans';
import { WidgetData } from '../models/jobOfferWidgetData';
import { BaseService } from './base.service';
import { FilterFields } from '../models/filterFields';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';

@Injectable({
  providedIn: 'root'
})
export class JobService extends BaseService<JobOffer, JobOffer> {

  constructor(protected override http: HttpClient, @Inject('BASE_URL') protected override baseUrl: string) {
    super(http, baseUrl, 'JobOffer');
  }

  getFavorites(favs: number[]): Observable<ODataResponse<JobOffer>> {
    return super.odata(`?$filter=Id in (${favs.join(',')})&$expand=JobLocation&$expand=Moments&$expand=Organization`);
  }


  getWidgetData(uuid: string | null, type: string | null): Observable<WidgetData> {
    return this.http.get<WidgetData>(this.baseUrl + `widget/byUuid/${uuid}/${type}`);
  }

  getById(id: string | null): Observable<JobOffer> {
    return super.get(`/${id}`);
  }

  getAllJobs(): Observable<JobOffer[]> {
    return super.getAll();
  }

  getTotal(): Observable<string> {
    return super.odataCount();
  }

  getFeaturedJobs(max?: number): Observable<ODataResponse<JobOffer>> {
    if (max) {
      return super.odata(`?$filter=Featured eq true&$top=${max}&$expand=JobLocation&$expand=Moments&$expand=Organization`);
    }
    return super.odata(`?$filter=Featured eq true&$expand=JobLocation&$expand=Moments&$expand=Organization`);
  }

  getFeaturedJobsAmount(): Observable<ODataResponse<JobOffer>> {
    return super.odata(`?$filter=Featured eq true&$top=0`);
  }

  getFeaturedJobsByTheme(themeId: number): Observable<ODataResponse<JobOffer>> {
    //currentDate in yyyy-MM-ddTHH:mm:ss.sssZ
    let currentDate = new Date().toISOString();
    return super.odata(`?$filter=Themes/any(s: s/featuredFrom lt ${currentDate} and s/featuredTill gt ${currentDate} and s/ThemeId eq ${themeId})&$expand=JobLocation&$expand=Moments&$expand=Organization`);
  }

  getFeaturedJobsByThemes(themeIds: JobTheme[]): Observable<ODataResponse<JobOffer>> {
    //currentDate in yyyy-MM-ddTHH:mm:ss.sssZ
    let currentDate = new Date().toISOString();
    let qryString = `?$filter=Themes/any(s: s/featuredFrom lt ${currentDate} and s/featuredTill gt ${currentDate} and s/ThemeId in (`;
    themeIds.forEach(themeId => {
      qryString = qryString + `${themeId.id},`;
    });
    qryString = qryString.substring(0, qryString.length - 1) + `))&$expand=JobLocation&$expand=Moments&$expand=Organization`;
    return super.odata(qryString);
  }

  getJobsByOrganization(id: number): Observable<ODataResponse<JobOffer>> {
    return super.odata(`?$filter=OrganizationId eq ${id}&$expand=JobLocation&$expand=Moments`);
  }

  getVolunteersByOrganization(id: number, max: number): Observable<ODataResponse<JobOffer>> {
    return super.odata(`?$filter=OrganizationId eq ${id} and spotlightName ne null&$top=${max}`);
  }

  getVolunteers(max?: number): Observable<ODataResponse<JobOffer>> {
    if (max) return super.odata(`?$filter=spotlightName ne null and spotlightOnHomepage eq true&$top=${max}&$expand=Organization`);
    return super.odata(`?$filter=spotlightName ne null and spotlightOnHomepage eq true&$expand=Organization`);
  }

  // getNumberVolunteers(): Observable<string> {
  //   return super.odataCount(`?$filter=spotlightName ne null`);
  // }

  getSimilarJobs(job: JobOffer, max: number, filterSectors: boolean) {
    let qryString = "";

    qryString = qryString + `?$expand=JobLocation&$expand=Moments&$top=${max}`;

    if (job.jobLocation && job.jobLocation.postCode) { qryString = qryString + `&postcode=${job.jobLocation.postCode}&distance=150000`; }



    qryString = qryString + `&filter=Id ne ${job.id}`;

    if ((!job.jobLocation || job.jobLocation && !job.jobLocation.postCode) && job.isAtHome) { qryString = qryString + ` and IsAtHome eq true`; }

    if (filterSectors && job.sectors.length > 0) {
      let sectors = "(";
      job.sectors.forEach(sector => {
        sectors = sectors + `${sector.sectorId},`;
      });
      sectors = sectors.substring(0, sectors.length - 1) + ")";

      qryString = qryString + ` and Sectors/any(s: s/SectorId in ${sectors})`;
    }

    return super.odata(qryString);
  }

  search(skip: number, top: number, value?: FilterFields): Observable<ODataResponse<JobOffer>> {

    let qryString = "";

    if (value) {

      qryString = qryString + `?$filter=
        (contains(Name, '${value.name}')
        or contains(Description, '${value.name}') or contains(Organization/Name, '${value.name}'))`;

      if (value.postcodes && value.postcodes.length > 0) {
        qryString = qryString + ' and ('
        const ids = value.postcodes.map((postcode: any) => postcode.id).join(',');
        qryString = qryString + `Postcodes/any(s: s/PostcodeId in (${ids}))`
        qryString = qryString + ')';
      }


      //if (value.sector) qryString = qryString + ` and Sectors/any(s: s/SectorId Eq ${value.sector})`;

      //if (value.audience) qryString = qryString + ` and Audiences/any(s: s/JobAudienceId Eq ${value.audience})`;

      if (value.frequency) qryString = qryString + ` and FrequentionType eq ${value.frequency}`;

      if (value.collab) qryString = qryString + ` and CollaborationTypeId eq ${value.collab}`;

      //moments
      //if (value.from) { qryString = qryString + ` and (Moments/any(s: s/From ge cast(${value.from},Edm.DateTimeOffset)) or (Moments/any(s: s/Till ge cast(${value.from},Edm.DateTimeOffset)))`; }
      if (value.from) {
        let date = new Date(value.from).toISOString();
        qryString = qryString + ` and (Moments/any(s: s/From ge cast(${date},Edm.DateTimeOffset)) or Moments/any(s: s/Till ge cast(${date},Edm.DateTimeOffset)) or FrequentionType in (4,5))`;
      }

      if (value.till) {
        let date = new Date(value.till).toISOString();
        qryString = qryString + ` and (Moments/any(s: s/From lt cast(${date},Edm.DateTimeOffset)) or Moments/any(s: s/Till lt cast(${date},Edm.DateTimeOffset)) or FrequentionType in (4,5))`;
      }

      //timeFrames (multiple dates), show all jobs with at least one date in the range
      //because the filter checks also the date, check if the mome
      //nDate is the date + 1 day, because the filter checks also the date, check if the moment is in the range
      // (date < from < nDate) || (date < till < nDate) || (from < date < till)
      // if (value.timeFrames && value.timeFrames.length > 0) {
      //   qryString = qryString + ' and ('
      //   value.timeFrames.forEach((time: { id: string }) => {
      //     let date = new Date(time.id).toISOString();
      //     //add 1 day to the date, because the filter checks also the date, check if the moment is in the range
      //     let nDate = new Date(new Date(date).setDate(new Date(date).getDate() + 1)).toISOString();
      //     qryString = qryString + `(Moments/any(s: s/From ge cast(${date},Edm.DateTimeOffset) and s/From lt cast(${nDate},Edm.DateTimeOffset)) or Moments/any(s: s/Till ge cast(${date},Edm.DateTimeOffset) and s/Till lt cast(${nDate},Edm.DateTimeOffset)) or Moments/any(s: s/From le cast(${date},Edm.DateTimeOffset) and s/Till ge cast(${nDate},Edm.DateTimeOffset))) or `;
      //   });
      //   qryString = qryString.substring(0, qryString.length - 4) + ')';
      // }

      if (value.disability) qryString = qryString + ` and SuitabilityForDisabledPersons in ('1','2')`;

      if (value.noDutch) qryString = qryString + ` and DutchSkillType in ('1')`;

      if (value.canPracticeDutch) qryString = qryString + ` and CanPracticeDutch Eq ${value.canPracticeDutch}`;

      if (value.extraDisability) qryString = qryString + ` and HasExtraAdaptationsForDisabledPersons eq true`;



      if (value.themes && value.themes.length > 0) {
        qryString = qryString + ' and ('
        const themeIds = value.themes.map((theme: any) => theme.id).join(',');
        qryString = qryString + `Themes/any(s: s/ThemeId in (${themeIds}))`
        if (value.digihelpers) {
          qryString = qryString.substring(0, qryString.length - 3)
          qryString = qryString + ` and Themes/any(s: s/ThemeId eq 5)) `;
        } else {
          qryString = qryString + ')';
        }
      }




      if (value.audiences && value.audiences.length > 0) {
        qryString = qryString + ' and ('
        const audienceIds = value.audiences.map((audience: any) => audience.id).join(',');
        qryString = qryString + `Audiences/any(s: s/JobAudienceId in (${audienceIds}))`
        qryString = qryString + ')';
      }

      if (value.sectors && value.sectors.length > 0) {
        qryString = qryString + ' and ('
        const sectorIds = value.sectors.map((sector: any) => sector.id).join(',');
        qryString = qryString + `Sectors/any(s: s/SectorId in (${sectorIds}))`
        qryString = qryString + ')';
      }

      if (value.talents && value.talents.length > 0) {
        qryString = qryString + ' and ('
        const talentIds = value.talents.map((talent: any) => talent.id).join(',');
        qryString = qryString + `Talents/any(s: s/JobTalentId in (${talentIds}))`
        qryString = qryString + ')';
      }

      //if (value.onLocation && value.post && !value.withDistance) qryString = qryString + ` and JobLocation/PostCode eq '${value.post.postCode}'`;


      //geolocation

      if (!value.fromHome && value.onLocation) {
        qryString = qryString + ` and IsAtHome eq false`;
        if (value.longitude && value.latitude) qryString = qryString + `&latitude=${value.latitude}&longitude=${value.longitude}`;
      }

      if (value.fromHome && !value.onLocation) { qryString = qryString + ` and IsAtHome eq true`; }

      if (value.fromHome && value.onLocation) {
        qryString = qryString + ` or IsAtHome eq true`;
        if (value.longitude && value.latitude) qryString = qryString + `&latitude=${value.latitude}&longitude=${value.longitude}`;
      }
      if (value.locations) {
        qryString += `&postcodes=${(value.locations.map((location: any) => location.postCode) as string[]).join(',')}`;
      }

      //if (value.onLocation && value.post && value.withDistance) qryString = qryString + `&postcode=${value.post.postCode} `;

      if (value.onLocation && value.withDistance && value.distance) qryString = qryString + `&distance=${value.distance * 1000 * 1.56} `;

      if (value.organizationIds) {
        qryString += `&organizationIds=${(value.organizationIds as string[]).join(',')}`;
      } else {
        if (value.organizations) {
          qryString += `&organizations=${(value.organizations as string[]).join('[')}`;
        }
      }
      if (value.organizations) {
        qryString += `&organizations=${(value.organizations as string[]).join('[')}`;
      }

      //if (value.timeFrames && value.timeFrames.length > 0) {
      //  qryString = qryString + `&dates=${value.timeFrames.map((timeFrame: any) => timeFrame.id).join('[')}`;
      //}

      //pagination
      qryString = qryString + `&$skip=${skip}&$top=${top} `;

      //jobLocation
      qryString = qryString + `&$expand=JobLocation`;

      //jobMoments
      qryString = qryString + `&$expand=Moments`;
      //Organization
      qryString = qryString + `&$expand=Organization`;


      //order by createdAt
      if (value.orderBy) qryString = qryString + `&$orderby=CreatedAt ${value.orderBy} `;
      //if (!value.orderBy) qryString = qryString + `&$orderby=SpotlightName desc `;

    } else {

      //pagination
      qryString = qryString + `? $skip = ${skip}&$top=${top} `;

      //jobLocation
      qryString = qryString + `&$expand=JobLocation`;

      //jobMoments
      qryString = qryString + `&$expand=Moments`;
      //Organization
      qryString = qryString + `&$expand=Organization`;
    }

    return super.odata(qryString);
  }

  getAllByPostcode(postcodeId: number): Observable<JobOffer[]> {
    return this.getAll("/byPostcode/" + postcodeId);
  }
}
