import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Organization } from "src/models/organization";
import { OrganizationService } from "src/services/organization.service";

@Injectable({ providedIn: 'root' })
export class OrganizationResolver  {
  constructor(private service: OrganizationService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Organization>|Promise<Organization>|Organization {
    return this.service.getById(route.paramMap.get('id'));
  }
}
