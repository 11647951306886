import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { post } from 'jquery';
import { JobPostcode } from '../../../../../models/jobPostcode';
import { NotificationService } from '../../../../../services/notification.service';
import { NotificationDto } from '../../models/notification';

@Component({
  selector: 'app-notification-edit',
  templateUrl: './notification-edit.component.html',
  styleUrls: ['./notification-edit.component.scss']
})
export class NotificationEditComponent implements OnInit {
  @Input() notification: NotificationDto | null = null;

  @Output() onDeleted = new EventEmitter<any>(); // functie na het bewaren van het adres

  isLoading = false;

  constructor(private modalService: NgbModal, private notificationService: NotificationService) { }

  ngOnInit(): void {

  }

  delete() {
    if (this.notification) {
      this.isLoading = true;
      this.notificationService.deleteByKey(this.notification.accessKey).subscribe(x => {
        if (this.onDeleted && this.onDeleted.observers.length > 0)
          this.onDeleted.emit(x);
        this.isLoading = false;
      });
    }
  }

  deletePostcode(postcodeId: number) {
    if (this.notification)
      this.notificationService.deletePostcode(this.notification.accessKey, postcodeId).subscribe(x => {
        this.notification = x;
      });
  }


  addPostcode(postcode: JobPostcode) {
    if (this.notification && postcode)
      this.notificationService.addPostcode(this.notification.accessKey, postcode.id).subscribe(x => {
        this.notification = x;
      });
  }
}
