import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobOffer } from 'src/models/jobOffer';

@Component({
  selector: 'app-volunteer-modal',
  templateUrl: './volunteer-modal.component.html',
  styleUrls: ['./volunteer-modal.component.scss']
})
export class VolunteerModalComponent implements OnInit {
  @Input() job!: JobOffer;
  @Input() widget = false;
  constructor(@Inject('IMAGE_SERVER_URL') public imageUrl: string, private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {
  }

  close() {
    this.modalService.dismissAll();
  }

  jobs() {
    this.close();
    this.router.navigate([`/organisatie/${this.job.organization.nameForUrl}/${this.job.organizationId}`], { fragment: 'vacatures' });
  }

  getUrl(url: string) {
    if (url && url.indexOf('http') > -1) {
      return url;
    }
    else
      return this.imageUrl + url
  }

  organisation() {
    if (this.widget) {
      let url = this.router.createUrlTree([`/organisatie/${this.job.organization.nameForUrl}/${this.job.organizationId}`] );
      window.open(url.toString(), '_blank');
    }
    else {
      this.close();
      this.router.navigate([`/organisatie/${this.job.organization.nameForUrl}/${this.job.organizationId}`]);
    }
    
  }

}
