import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobOffer } from 'src/models/jobOffer';
import { FavoriteService } from 'src/services/favorite.service';

@Component({
  selector: 'app-favorite-button',
  templateUrl: './favorite-button.component.html',
  styleUrls: ['./favorite-button.component.scss']
})
export class FavoriteButtonComponent implements OnInit {
  @Input() job!: JobOffer;
  constructor(public favoriteService: FavoriteService, @Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  isFavorite() {
    return isPlatformBrowser(this.platformId) ? this.favoriteService.isFavorite(this.job.id) : false;
  }

  closeModals() {
    this.modalService.dismissAll();
  }

}
