<section>
    <div class="pxp-container">
        <div class="pxp-single-job-cover pxp-cover" style="background-color: var(--pxpMainColorLight);height: 110px;"></div>
        <!--
            <div *ngIf="organization.bannerUrl" class="pxp-single-job-cover pxp-cover" [ngStyle]="{'background-image': 'url(' + getUrl(organization.bannerUrl) + ')'}"></div>
            <div *ngIf="organization.logoUrl" class="pxp-single-job-cover-logo" [ngStyle]="{'background-image': 'url(' + getUrl(organization.logoUrl) + ')'}"></div>
        -->
        <div class="pxp-single-job-content mt-4 mt-lg-5">
            <div class="row">
                <div class="col-lg-7 col-xl-8 col-xxl-9">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-11">
                            <h1 *ngIf="organization.name">{{organization.name}}</h1>
                        </div>
                        <div class="col-auto d-none">
                            <div class="pxp-single-job-options mt-4 col-xl-0">
                                <button class="btn pxp-single-job-save-btn"><span class="fa .a-heart"></span></button>
                            </div>
                        </div>
                    </div>

                    <div class="pxp-single-job-content-details">

                        <div [innerHtml]="organization.description"></div>

                        <div class="text-center text-lg-start mt-5 mb-3">
                            <span *ngIf="totalJobs == 1">
                                <a (click)="scrollToJobs()" class="btn rounded-pill pxp-section-cta mx-2 my-2 ms-lg-0">Bekijk {{totalJobs}} vacature</a>
                            </span>
                            <span *ngIf="totalJobs > 1">
                                <a (click)="scrollToJobs()" class="btn rounded-pill pxp-section-cta mx-2 my-2 ms-lg-0">Bekijk {{totalJobs}} vacatures</a>
                            </span>
                            <!--
                                <button class="btn rounded-pill pxp-section-cta mx-2 my-2 ms-lg-1" (click)="contactForm()">Stuur een bericht</button>
                            -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-xl-4 col-xxl-3">
                    <div class="pxp-single-job-side-panel mt-5 mt-lg-0">
                        <div>
                            <div class="pxp-single-job-side-info-data">{{organization.name}}</div>
                            <div class="pxp-single-job-side-info-label pxp-text-light">{{organization.address.street}}<br>{{organization.address.postCode}} {{organization.address.location}}</div>
                        </div>
                        <div class="mt-4" *ngIf="organization.address && organization.address.latitude">
                          <div class="map-responsive" style="height: 200px;">
                            <app-location-map [location]="organization.address"></app-location-map>
                        </div>
                        </div>
                        <div class="mt-4 d-flex align-items-center">
                            <div class="d-inline-block">
                                <a [attr.href]="organization.website | hrefCheck" class="btn btn-sm rounded-pill pxp-section-cta me-4 px-3 py-1 fs-6" target="_blank">Website</a>
                            </div>
                            <div class="d-inline-block">
                                <app-social-media [organization]="organization"></app-social-media>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 mt-lg-4 pxp-single-job-side-panel">
                        <div>
                            <div class="pxp-single-job-side-info-data">Contactpersoon</div>
                            <div class="pxp-single-job-side-info-label pxp-text-light"><a [attr.href]="'tel:' + organization.telephone">{{organization.telephone}}</a><br /><a [attr.href]="'mailto:' + organization.email">{{organization.email}}</a></div>
                        </div>
                        <div class="mt-4 d-flex justify-content-center">
                            <div class="d-inline-block">
                                <button class="btn btn-sm rounded-pill pxp-section-cta mt-3 mt-sm-0 px-3 py-1 fs-6" (click)="contactForm()" *ngIf="organization.email" role="button">Stuur een bericht</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="organisation-vacatures" class="pt-100 pb-100 mt-4" style="background-color: var(--pxpSecondaryColorLight);">
    <div class="pxp-container">
        <h2 class="pxp-section-h2 text-center">
            <span *ngIf="totalJobs == 0">
                Momenteel geen vacatures
            </span>
            <span *ngIf="totalJobs == 1">
                1 vacature
            </span>
            <span *ngIf="totalJobs > 1">
                {{totalJobs}} vacatures
            </span>
        </h2>
        <h3 class="text-center">bij {{organization.name}}</h3>

        <app-job-list [jobs$]="jobs" [showFeatured]="false" [col]="'col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4'"></app-job-list>

    </div>
</section>

<section *ngIf="totalVolunteers > 0" class="mt-100">
    <div class="pxp-container">
        <h2 class="pxp-section-h2 text-center">Zij zijn vrijwilligers</h2>
        <h3 class="text-center">bij {{organization.name}}</h3>

        <app-volunteer-list [volunteers]="volunteers"></app-volunteer-list>
    </div>
</section>
