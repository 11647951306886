import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobPostcode } from '../../../../../models/jobPostcode';
import { NotificationDto } from '../../models/notification';

@Component({
  selector: 'app-notification-edit-modal',
  templateUrl: './notification-edit-modal.component.html',
  styleUrls: ['./notification-edit-modal.component.scss']
})
export class NotificationEditModalComponent implements OnInit {
  @Input() notification: NotificationDto | null = null;
  @Input() postcode: JobPostcode | null = null;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {

  }

  dismiss() {
    this.modalService.dismissAll();
  }
}
