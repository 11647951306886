import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { JobOffer } from 'src/models/jobOffer';
import { VolunteerModalComponent } from '../volunteer-modal/volunteer-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-volunteer-list',
  templateUrl: './volunteer-list.component.html',
  styleUrls: ['./volunteer-list.component.scss']
})
export class VolunteerListComponent implements OnInit {
  @Input() volunteers!: JobOffer[];

  constructor() { }

  ngOnInit(): void {
  }
}
