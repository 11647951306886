<div class="justify-content-md-center mb-3 px-2">
    <div *ngIf="!theme && !widget" class="row px-4">
        <!--<div class="px-0 pxp-banner">
      <div class="pxp-cover" style="position: absolute; width: 100%; height: 100%; background-image: url(../../../../assets/images/weldoeners_header_clean.jpg); border-radius: 30px; filter: brightness(0.75);"></div>
      <div class="px-4 py-3" style="position: absolute;">
        <a href="https://www.vrijwilligerswerk.be/weldoeners">
          <span class="d-block fs-3 text-white" style="font-weight: 600; line-height: 1;">Liever eerst 2 uur proeven van vrijwilligerswerk?</span>
          <span class="d-block mt-2 fs-4 text-white" style="font-weight: 600; line-height: 1;">Word Wéldoener tijdens de Week van de Vrijwilliger!</span>
        </a>
      </div>
    </div>-->
    </div>
    <div class="row">
        <div *ngIf="featuredJob" class="col-xxl-12 pxp-jobs-card-2-container mt-0">
            <app-job-card [widget]="widget" [job]="featuredJob" [featured]="true"></app-job-card>
        </div>
    </div>
    <div class="row {{widget ? 'overflow-auto' : ''}}">
        <div class="{{col}} pxp-jobs-card-2-container " *ngFor="let job of jobs">
            <app-job-card *ngIf="!job.featured" [widget]="widget" [job]="job"></app-job-card>
        </div>
    </div>
</div>