import { Component, Input, OnInit } from '@angular/core';
import { JobOffer } from 'src/models/jobOffer';

@Component({
  selector: 'app-job-location',
  templateUrl: './job-location.component.html',
  styleUrls: ['./job-location.component.scss']
})
export class JobLocationComponent implements OnInit {
  @Input() job!: JobOffer;
  constructor() { }

  ngOnInit(): void {
  }

}
