import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { JobPostcode } from 'src/models/jobPostcode';
import { ODataResponse } from 'src/models/OData';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class JobPostcodeService extends BaseService<JobPostcode, JobPostcode> {

  constructor(protected override http: HttpClient, @Inject('BASE_URL') protected override baseUrl: string) {
    super(http, baseUrl, 'JobPostcode');
  }

  getById(id: string | null) {
    return super.get(`/${id}`);
  }

  getByName(name: string | null) {
    return super.get(`/byName/${name}`);
  }

  search(searchTerm: string): Observable<JobPostcode[]> {
    return super.getAll(`/search?searchTerm=${searchTerm}`);

  }

}
