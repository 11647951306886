import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, map, Observable, OperatorFunction, switchMap } from 'rxjs';
import { JobPostcode } from '../../../../models/jobPostcode';
import { JobPostcodeService } from '../../../../services/postcode.service';

@Component({
  selector: 'app-postcode-select',
  templateUrl: './postcode-select.component.html',
  styleUrls: ['./postcode-select.component.scss']
})
export class PostcodeSelectComponent implements OnInit {
  @Input() showButton = true; //knop tonen of niet
  @Input() navigateOnSelect = false; //ga onmiddellijk naar de gemeente als je er een selecteert
  @Input() inputClass = "form-control pxp-fs-1 border-0 w-95 rounded-pill pt-2"; //class van het input veld
  @Input() buttonClass = "btn btn-sm rounded-pill pxp-section-cta mt-4 fs-5 px-3"; //class van de button
  @Input() buttonName = "Zoek vacatures"; //naam van de button
  @Input() control: FormControl = new FormControl(""); //control waar de waarde van de postcode in komt.

  @Output() onSelect = new EventEmitter<any>(); // functie na het bewaren van het adres

  constructor(private router: Router, private postcodeService: JobPostcodeService) {

  }

  ngOnInit(): void {
    this.control.valueChanges.subscribe(x => {
      console.log(this.control.value)
    })
  }

  goToPostcode() {
    debugger
    if (this.onSelect && this.onSelect.observers.length > 0) {
      this.onSelect.emit((this.control.value as JobPostcode));
      this.control.patchValue("")
    } else {
      if (this.control.value) {
        this.router.navigate([`/${(this.control.value as JobPostcode).nameForUrl}`]).then(x => {
          //window.location.reload();
        });
      } else {

      }
    }

  }

  formatLocation(loc: JobPostcode) {
    return (loc ? `${loc.name} (${loc.postcode})` : '');
  }

  selectCity(event: any) {
    this.control.patchValue(event.item);
    if (this.navigateOnSelect) {
      this.goToPostcode()
    }
  }

  searchCity: OperatorFunction<string, readonly JobPostcode[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) =>
        term.length < 2 ? [] : this.postcodeService.search(term)
      ),
      map(res => {
        if (res.length == 1) {
          this.control.patchValue(res[0]);
          this.goToPostcode()
          return []
        } else {
          return res.map(loc => loc)
        }
      })
    );

  formatter = (loc: JobPostcode) => this.formatLocation(loc);

}
