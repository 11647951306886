<section class="pxp-hero" style="background-color: var(--pxpMainColorLight);">
  <div class="pxp-hero-opacity">
    <img src="../../../assets/images/icon-rolstoel.png" class="d-none d-lg-block"
         style="height: 100px; position: absolute; bottom: 0; left:10%;">
    <img src="../../../assets/images/icon-borstelen.png" class="d-none d-lg-block"
         style="height: 100px; position: absolute; bottom: 0; right:10%;">
  </div>
  <div class="pxp-hero-caption">
    <div class="pxp-container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xl-9">
          <div class="text-center" style="font-size: 1.25rem;">
            Wil je als vrijwilliger helpen bij een noodsituatie, een ramp of een crisis in Vlaanderen,
            ontdek dan hier of je effectief aan de slag kan en waar je welke hulp kan bieden.
          </div>

          <div class="my-5">
            <h1 class="text-center">Kan ik helpen in...</h1>
            <div class="pxp-hero-form pxp-hero-form-round pxp-large text-center text-lg-start py-0 pe-0 mt-lg-3"
                 style="height: 58px !important;">
              <app-postcode-select [buttonClass]="'btn rounded-pill pxp-section-cta text-white me-2'"></app-postcode-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="pt-100 pb-100 position-relative">
  <img src="../../../assets/images/icon-puinuimen.png" class="d-none d-lg-block"
       style="height: 100px; position: absolute; bottom: 0; left:25%;">
  <div class="pxp-container">
    <div class="row pxp-pl-80 justify-content-between align-items-top">
      <div class="col-lg-6">
        <div class="pxp-info-caption mt-0">
          <h2 class="pxp-section-h2 mb-0">Waarom deze site?</h2>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="pt-2" style="font-size: 1.25rem;">
          <p class="pxp-text-light">
            De afgelopen jaren hebben we geleerd dat bij een noodsituatie of crisis, Vlaanderen massaal
            klaarstaat om te helpen. Van mensen begeleiden in het vaccinatiecentrum, over puinruimen na een
            overstroming, tot een gezin opvangen dat op de vlucht is voor oorlog.
          </p>
          <p class="pxp-text-light">
            Wat we ook geleerd hebben, is dat het niet altijd duidelijk was hoe je precies kon helpen, en óf
            je eigenlijk wel kon helpen. Daardoor was de vraag om te kunnen helpen soms groter dan de hulp
            die nodig was.
          </p>
          <p class="pxp-text-light mb-0">
            Deze site biedt de oplossing. Je kan hier per gemeente nakijken of je momenteel wel kan helpen,
            en zo ja, met welke taak.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="pt-100 pb-100" style="background-color: var(--pxpMainColorLight);">
  <div class="pxp-container">
    <div class="row pxp-pl-80 justify-content-between align-items-top">
      <div class="col-lg-6">
        <div class="pxp-info-caption mt-0" data-aos="fade-up" data-aos-duration="600">
          <h2 class="d-block d-xl-none pxp-section-h2 mb-0">Hoe minder, hoe beter</h2>
          <h2 class="d-none d-xl-block pxp-section-h2 mb-0">Hoe minder,<br />hoe beter</h2>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="pt-2" data-aos="fade-up" data-aos-duration="600" style="font-size: 1.25rem;">
          <p class="pxp-text-light">
            Het klinkt misschien vreemd, maar hoe minder we deze site nodig hebben, hoe beter. Je zal op
            deze site namelijk enkel vacatures vinden bij een noodsituatie of crisis. Noem deze site gerust
            'een crisissite'. En hoe minder je zo'n crisissite nodig hebt,
            hoe beter. Merk je dus dat je amper tot geen vacatures kan vinden, dan is dat alleen maar goed
            nieuws!
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-faq></app-faq>

<section class="pb-100 position-relative">
  <img src="../../../assets/images/icon-helpen.png" class="d-none d-lg-block"
       style="height: 100px; position: absolute; bottom: 0; right:0;">
  <div class="pxp-container">
    <div class="row pxp-pl-80 justify-content-between align-items-top">
      <div class="col-lg-12 text-center mt-5">
        <a href="#top" class="btn btn-sm rounded-pill pxp-section-cta">
          Terug naar boven<span class="fa fa-angle-up"></span>
        </a>
      </div>
    </div>
  </div>
</section>
