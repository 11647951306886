<div class="pxp-hero-form pxp-hero-form-round pxp-large py-0 pe-0 mt-3" style="height: 58px; border: 1px solid #ccc;">
    <form [formGroup]="form" class="row gx-3 align-items-center" style="height: 58px;">
        <div class="col-10 col-md-9 col-lg">
            <input type="email" class="form-control" id="pxp-signin-email" style="font-size: 1.25rem;" placeholder="Jouw e-mailadres..." [formControl]="form.email">
        </div>
        <div class="col-2 col-md-3 col-lg-auto">
            <button (click)="add()" style="height: 40px;" [disabled]="isLoading">
                <span *ngIf="isLoading">
                    <i class="fas fa-circle-notch fa-spin "></i>&nbsp;&nbsp;
                </span>
                <span class="d-none d-md-block">
                    Verzenden
                </span>
                <span class="d-block d-md-none">
                    <i class="fas fa-envelope"></i>
                </span>
            </button>
        </div>
    </form>
</div>