<div class="modal-content">
    <div class="p-0 pb-3" style="border-bottom: 1px solid #dee2e6;">
        <div class="row">
            <div class="col-11 modal-title text-center mt-4 fs-3">
                {{ job.name }}
            </div>
            <div class="col-1 mt-4">
                <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="row row-cols-auto">
            <div class="col-1 p-0 pe-1 text-center" style="margin-top: 0.6rem;">
                <h5 class="mb-0"><i class="fa-solid fa-building mx-auto"></i></h5>
            </div>
            <div class="col-11 ps-0">
                <div class="row row-cols-auto">
                    <div class="col-12 col-lg-2" style="margin-top: 0.6rem;">
                        <h5 class="mb-0">Bij wie?</h5>
                    </div>
                    <div class="col-12 col-lg-9 ms-lg-3" style="margin-top: 0.6rem;">
                        <h5 class="mb-0">{{ job.organizationName }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-cols-auto mt-4">
            <div class="col-1 p-0 pe-1 text-center">
                <h5><i class="fa fa-handshake mx-auto"></i></h5>
            </div>
            <div class="col-11 ps-0">
                <div class="row row-cols-auto">
                    <div class="col-12 col-lg-2">
                        <h5>Wat?</h5>
                    </div>
                    <div class="col-12 col-lg-9 ms-lg-3">
                        <span [innerHtml]="job.description" class="fs-5"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-cols-auto mt-3">
            <div class="col-1 p-0 pe-1 text-center">
                <h5><i class="fa-solid fa-map-marker-alt mx-auto"></i></h5>
            </div>
            <div class="col-11 ps-0">
                <div class="row row-cols-auto">
                    <div class="col-12 col-lg-2">
                        <h5>Waar?</h5>
                    </div>
                    <div class="col-12 col-lg-9 ms-lg-3">
                        <app-job-location [job]="job" class="fs-5"></app-job-location>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-cols-auto mt-4">
            <div class="col-1 p-0 pe-1 text-center">
                <h5><i class="fa fa-calendar-alt mx-auto"></i></h5>
            </div>
            <div class="col-11 ps-0">
                <div class="row row-cols-auto">
                    <div class="col-12 col-lg-2">
                        <h5>Wanneer?</h5>
                    </div>
                    <div class="col-12 col-lg-9 ms-lg-3">
                        <app-job-moment [job]="job" class="fs-5"></app-job-moment>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4 mb-3">
            <div class="col-lg-12 d-lg-flex justify-content-between">
                <div class="col-12 col-lg-6 text-center text-lg-end me-lg-2 mt-1 mt-lg-0">
                    <a class="btn btn-sm rounded-pill pxp-section-cta px-3 py-1 fs-6" aria-label="Close" (click)="close()" role="button">
                        Sluit venster
                    </a>
                </div>
                <div class="col-12 col-lg-6 text-center text-lg-start ms-lg-2 mt-3 mt-lg-0">
                    <a class="btn btn-sm rounded-pill pxp-section-cta px-3 py-1 fs-6" (click)="candidate()" role="button">
                        Kan ik helpen?
                    </a>
                </div>
            </div>
            <!--
            <div *ngIf="job.hasBeenFilled">
                <div class="col-12 text-center mt-1 mt-lg-0">
                    <button type="button" class="btn btn-success pe-none">
                        Vacature ingevuld
                    </button>
                </div>
            </div>
            -->
        </div>
    </div>
</div>