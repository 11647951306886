import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobOffer } from 'src/models/jobOffer';
import { Organization } from 'src/models/organization';
import { WidgetData } from '../../../../models/jobOfferWidgetData';
import { VolunteerModalComponent } from '../../volunteers/volunteer-modal/volunteer-modal.component';
import { RegistrationComponent } from '../job-detail/registration/registration.component';
import { JobModalComponent } from '../job-modal/job-modal.component';

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss']
})
export class JobCardComponent implements OnInit {
  @Input() job!: JobOffer;
  @Input() organization!: Organization;
  @Input() widget: WidgetData | undefined = undefined;
  @Input() featured: boolean = false;


  constructor(@Inject('IMAGE_SERVER_URL') public imageUrl: string, private router: Router, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  public selectJob(job: JobOffer): void {
    let modal = this.modalService.open(JobModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'pxp-user-modal' });
    modal.componentInstance.job = job;
    modal.componentInstance.widget = this.widget;
  }

  public selectVolunteer(job: JobOffer): void {
    let modal = this.modalService.open(VolunteerModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'pxp-user-modal' });
    modal.componentInstance.job = job;
    modal.componentInstance.widget = this.widget;
  }

  contactForm(job: JobOffer) {
    //this.registrationComponent.open(this.job);
    var modal = this.modalService.open(RegistrationComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'pxp-user-modal' });
    modal.componentInstance.job = this.job;

    //let modal = this.modalService.open(ContactModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, windowClass: 'pxp-user-modal' });
    //modal.componentInstance.jobId = job.id;
    //modal.componentInstance.job = job;
    //modal.componentInstance.organization = this.job.organization;
  }

  getUrl(url: string) {
    if (url && url.indexOf('http') > -1) {
      return url;
    } else {
      return this.imageUrl + url
    }
  }

  candidate() {
    if (this.widget) {
      let url = this.router.createUrlTree([`/vacatures/${this.job.nameForUrl}/${this.job.id}`]);
      window.window.open(url.toString(), '_blank');
    } else {
      this.router.navigate([`/vacatures/${this.job.nameForUrl}/${this.job.id}`], { state: { openModal: true } });
    }
  }

  jobDetails() {
    if (this.widget) {
      let url = this.router.createUrlTree([`/vacatures/${this.job.nameForUrl}/${this.job.id}`]);
      window.window.open(url.toString(), '_blank');
    } else {
      this.router.navigate([`/vacatures/${this.job.nameForUrl}/${this.job.id}`]);
    }
  }

}
