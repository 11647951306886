import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobOffer } from 'src/models/jobOffer';
import { RegistrationComponent } from '../job-detail/registration/registration.component';

@Component({
  selector: 'app-job-modal',
  templateUrl: './job-modal.component.html',
  styleUrls: ['./job-modal.component.scss']
})
export class JobModalComponent implements OnInit {
  @Input() job!: JobOffer;
  @Input() widget = false;

  constructor(private modalService: NgbModal, private router : Router) { }

  ngOnInit(): void {
  }

  candidate() {
    if (this.widget) {
      let url = this.router.createUrlTree([`/vacatures/${this.job.nameForUrl}/${this.job.id}`] );
      window.open(url.toString(), '_blank');
    }
    else {
      this.close();
      var modal = this.modalService.open(RegistrationComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'pxp-user-modal' });
      modal.componentInstance.job = this.job;
      // this.router.navigate([`/vacatures/${this.job.nameForUrl}/${this.job.id}`], { state: { openModal: true } });
    }
  }

  info() {
    if (this.widget) {
      let url = this.router.createUrlTree([`/vacatures/${this.job.nameForUrl}/${this.job.id}`] );
      window.open(url.toString(), '_blank');
    }
    else {
      this.close();
      this.router.navigate([`/vacatures/${this.job.nameForUrl}/${this.job.id}`]);
    }
  }

  close() {
    this.modalService.dismissAll();
  }
}
