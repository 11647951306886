import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { FormModel } from "../../../../../../models/form-model";
import { JobSkill } from "../../../../../../models/jobSkill";
import { JobTask } from "../../../../../../models/jobTask";


export class JobTaskForm extends FormModel<JobTask> {
  constructor(
    override readonly model: JobTask,
    readonly fb: FormBuilder = new FormBuilder()
  ) {
    super(model, fb.group({
      id: [model.id],
      name: [model.name, Validators.required],
      description: [model.description],
      listOrder: [model.listOrder, Validators.required],
      skills: fb.array(model.skills.map(skill => new JobSkillForm(skill, fb))),
      selected: [false]
    }).controls);

    this.selected.valueChanges.subscribe(x => {
      console.log(x)
      if (x) {
        this.setSkillsRequired();
      } else {
        this.setSkillsRequired(false);
      }
    })
  }

  readonly id = this.get("id") as FormControl;
  readonly name = this.get("name") as FormControl;
  readonly description = this.get("description") as FormControl;
  readonly listOrder = this.get("listOrder") as FormControl;
  readonly skills = this.get("skills") as FormArray;
  readonly selected = this.get("selected") as FormControl;


  setSkillsRequired(isRequired: boolean = true) {
    for (var i = 0; i < this.skills.length; i++) {
      if (isRequired)
        (this.skills.at(i) as JobSkillForm).selected.addValidators(Validators.requiredTrue);
      else
        (this.skills.at(i) as JobSkillForm).selected.removeValidators(Validators.requiredTrue);

      (this.skills.at(i) as JobSkillForm).selected.updateValueAndValidity()
    }
  }


  addSkill(): void {
    this.skills.push(new JobSkillForm({
      id: 0,
      name: null,
      description: null,
    }));
  }

  removeSkill(index: number): void {
    this.skills.removeAt(index);
  }

}



export class JobSkillForm extends FormModel<JobSkill>  {

  constructor(override readonly model: JobSkill, readonly fb: FormBuilder = new FormBuilder(), isRequiredTrue: boolean = false) {
    super(model, fb.group({
      id: [model.id],
      name: [model.name],
      description: [model.description],
      selected: [false]
    }).controls);

    if (isRequiredTrue)
      this.setRequiredTrue(this.selected, true);
  }
  readonly id = this.get("id") as FormControl;
  readonly name = this.get("name") as FormControl;
  readonly description = this.get("description") as FormControl;
  readonly selected = this.get("selected") as FormControl;
}
