import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JobApplication } from 'src/models/jobApplication';
import { Message } from 'src/models/message';
import { RequestAccess } from '../app/main/assist/models/requestAccess';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends BaseService<Message, Message> {

  constructor(protected override http: HttpClient, @Inject('BASE_URL') protected override baseUrl: string) {
    super(http, baseUrl, 'Message');
  }

  sendMailToOrganization(organizationId: number, message: Message) {
    return super.post('/org/' + organizationId, message);
  }

  sendMailToContactPerson(jobId: number, message: Message) {
    return super.post('/vacatures/' + jobId, message);
  }
  requestAccess(request: RequestAccess) {
    return super.postCustom<RequestAccess>('/requestAccess', request);
  }
}
