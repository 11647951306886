import { Component, OnInit } from '@angular/core';
import { JobOffer } from 'src/models/jobOffer';
import { FavoriteService } from 'src/services/favorite.service';
import { JobService } from 'src/services/job.service';

@Component({
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  jobs!: JobOffer[]
  constructor(private jobService: JobService, private favoService: FavoriteService) { }

  ngOnInit(): void {
    this.jobService.getFavorites(this.favoService.getFavorites()).subscribe(jobs => {
      this.jobs = jobs.value
    })
  }

}
