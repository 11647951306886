import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { JobOffer } from "src/models/jobOffer";
import { JobService } from "src/services/job.service";

@Injectable({ providedIn: 'root' })
export class JobResolver  {
  constructor(private service: JobService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<JobOffer>|Promise<JobOffer>|JobOffer {
    return this.service.getById(route.paramMap.get('id'));
  }
}
