<section class="mt-100 mb-100 pxp-no-hero">
    <div class="pxp-container text-center">
        <h2 class="pxp-section-h2">Maak een gratis account aan</h2>
        Heb je al een account? Ga naar <a data-bs-toggle="modal" href="#pxp-signin-modal" role="button">'Aanmelden'</a>
    </div>
    <div class="pxp-container text-start">
        <div class="row mt-3 pxp-animate-in pxp-animate-in-top">
            <div class="col-md-4 pxp-plans-card-1-container">
                <div class="pxp-plans-card-1">
                    <div class="pxp-plans-card-1-top">
                        <div class="pxp-plans-card-1-title">Als vrijwilliger...</div>
                        <div class="pxp-plans-card-1-list">
                            <ul class="list-unstyled">
                                <li class="fs-6"><i class="fa fa-check me-2" aria-hidden="true"></i>vind je uit
                                    duizenden vacatures jouw ideaal vrijwilligerswerk</li>
                                <li class="fs-6"><i class="fa fa-check me-2" aria-hidden="true"></i>beheer je jouw
                                    favoriete vacatures en organisaties in een overzichtelijk dashboard</li>
                                <li class="fs-6"><i class="fa fa-check me-2" aria-hidden="true"></i>krijg je berichten
                                    met interessant vrijwilligerswerk op jouw maat</li>
                            </ul>
                        </div>
                    </div>
                    <div class="pxp-plans-card-1-bottom">
                        <div class="pxp-plans-card-1-cta">
                            <a href="#vrijwilliger" class="btn rounded-pill pxp-card-btn">Registreer als
                                vrijwilliger</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 pxp-plans-card-1-container">
                <div class="pxp-plans-card-1">
                    <div class="pxp-plans-card-1-top">
                        <div class="pxp-plans-card-1-title">Als organisatie...</div>
                        <div class="pxp-plans-card-1-list">
                            <ul class="list-unstyled">
                                <li class="fs-6"><i class="fa fa-check me-2" aria-hidden="true"></i>voeg je een
                                    onbeperkt aantal vacatures toe</li>
                                <li class="fs-6"><i class="fa fa-check me-2" aria-hidden="true"></i>promoot je jouw
                                    organisatie als vrijwilligersorganisatie</li>
                                <li class="fs-6"><i class="fa fa-check me-2" aria-hidden="true"></i>beheer je vacatures
                                    en kandidaat-vrijwilligers in een gratis versie van het CRM-programma <a
                                        href="https://assistonline.eu/" style="display: contents;"
                                        target="_blank">Assist</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="pxp-plans-card-1-bottom">
                        <div class="pxp-plans-card-1-cta">
                            <a href="#organisatie" class="btn rounded-pill pxp-card-btn">Registreer als organisatie</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 pxp-plans-card-1-container">
                <div class="pxp-plans-card-1">
                    <div class="pxp-plans-card-1-top">
                        <div class="pxp-plans-card-1-title">Als lokaal bestuur...</div>
                        <div class="pxp-plans-card-1-list">
                            <ul class="list-unstyled">
                                <li class="fs-6"><i class="fa fa-check me-2" aria-hidden="true"></i>voeg je een
                                    onbeperkt aantal vacatures toe</li>
                                <li class="fs-6"><i class="fa fa-check me-2" aria-hidden="true"></i>geef je het
                                    vrijwilligerswerk binnen jouw lokaal bestuur een boost</li>
                                <li class="fs-6"><i class="fa fa-check me-2" aria-hidden="true"></i>beheer je vacatures
                                    en kandidaat-vrijwilligers in een gratis versie van het CRM-programma <a
                                        href="https://assistonline.eu/" style="display: contents;"
                                        target="_blank">Assist</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="pxp-plans-card-1-bottom">
                        <div class="pxp-plans-card-1-cta">
                            <a href="#bestuur" class="btn rounded-pill pxp-card-btn">Registreer als lokaal bestuur</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="vrijwilliger"></div>

    <div class="pxp-container d-flex justify-content-center mt-5">
        <div class="col-6 pxp-dashboard-content-details pxp-register-form">
            <h1 class="text-center">Registreer als vrijwilliger</h1>

            <form>
                <div class="row mt-4 mt-lg-5">
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="mb-3">
                                    <label for="pxp-candidate-title" class="form-label">Voornaam</label>
                                    <input type="text" id="pxp-candidate-title" class="form-control"
                                        placeholder="Je voornaam">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="mb-3">
                                    <label for="pxp-candidate-location" class="form-label">Naam</label>
                                    <input type="tel" id="pxp-candidate-location" class="form-control"
                                        placeholder="Je familienaam">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mb-3">
                                    <label for="pxp-candidate-email" class="form-label">E-mail</label>
                                    <input type="email" id="pxp-candidate-email" class="form-control"
                                        placeholder="Je e-mailadres">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mb-3">
                                    <label for="pxp-candidate-title" class="form-label">Wachtwoord</label>
                                    <input type="text" id="pxp-candidate-title" class="form-control"
                                        placeholder="Kies een wachtwoord">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mb-3">
                                    <label for="pxp-candidate-location" class="form-label">Herhaal wachtwoord</label>
                                    <input type="tel" id="pxp-candidate-location" class="form-control"
                                        placeholder="Herhaal je wachtwoord">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-sm-12">
                                <div class="mb-3 form-check">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                    <label class="form-check-label" for="exampleCheck1">Ik wil me abonneren op de
                                        nieuwsbrief voor vrijwilligers</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mb-3 form-check">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                    <label class="form-check-label" for="exampleCheck1">Ik aanvaard de <a
                                            href="gebruiksvoorwaarden.html">Gebruiksvoorwaarden</a> en de <a
                                            href="privacy.html">Privacyverklaring</a> van deze website</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4 mt-lg-5 text-center">
                    <button class="btn rounded-pill pxp-section-cta">Verzenden</button>
                </div>
            </form>
        </div>
    </div>

    <div id="organisatie" class="mt-50"></div>

    <div class="pxp-container d-flex justify-content-center mt-5">
        <div class="col-6 pxp-dashboard-content-details pxp-register-form">
            <h1 class="text-center">Registreer als organisatie</h1>
            Als organisatie kan je hier een registratieaanvraag lanceren. We kijken je gegevens eerst na alvorens je kan
            beginnen met het plaatsen van vacatures. Je krijgt een bericht per e-mail als het zover is!
            <form>
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <div class="row mb-3">
                            <div class="col-sm-12 fs-4 fw-bold">
                                Interne contactpersoon
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="mb-3">
                                    <label for="pxp-candidate-title" class="form-label">Voornaam</label>
                                    <input type="text" id="pxp-candidate-title" class="form-control"
                                        placeholder="Je voornaam">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="mb-3">
                                    <label for="pxp-candidate-location" class="form-label">Naam</label>
                                    <input type="tel" id="pxp-candidate-location" class="form-control"
                                        placeholder="Je familienaam">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mb-3">
                                    <label for="pxp-candidate-email" class="form-label">E-mail</label>
                                    <input type="email" id="pxp-candidate-email" class="form-control"
                                        placeholder="Je e-mailadres">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mb-3">
                                    <label for="pxp-candidate-title" class="form-label">Wachtwoord</label>
                                    <input type="text" id="pxp-candidate-title" class="form-control"
                                        placeholder="Kies een wachtwoord">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mb-3">
                                    <label for="pxp-candidate-location" class="form-label">Herhaal wachtwoord</label>
                                    <input type="tel" id="pxp-candidate-location" class="form-control"
                                        placeholder="Herhaal je wachtwoord">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-sm-12">
                                <div class="mb-3 form-check">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                    <label class="form-check-label" for="exampleCheck1">Ik wil me abonneren op de
                                        nieuwsbrief voor vrijwilligers</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <div class="row mb-3">
                            <div class="col-sm-12 fs-4 fw-bold">
                                Organisatie
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mb-3">
                                    <label for="pxp-candidate-title" class="form-label">Naam van de organisatie</label>
                                    <input type="text" id="pxp-candidate-title" class="form-control"
                                        placeholder="Je voornaam">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mb-3">
                                    <label for="pxp-company-job-category" class="form-label">Juridische vorm</label>
                                    <select id="pxp-company-job-category" class="form-select">
                                        <option>---</option>
                                        <option>Afdeling van een koepel</option>
                                        <option>Feitelijke vereniging</option>
                                        <option>IVZW</option>
                                        <option>Lokaal bestuur</option>
                                        <option>Openbare instelling</option>
                                        <option>Private stichting zonder winstoogmerk</option>
                                        <option>Stichting van openbaar nut</option>
                                        <option>Vennootschap met Sociaal Oogmerk</option>
                                        <option>VZW</option>
                                        <option>Ziekenfonds</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="mb-3">
                                    <label for="pxp-candidate-email" class="form-label">Adres</label>
                                    <input type="email" id="pxp-candidate-email" class="form-control"
                                        placeholder="Straat">
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="mb-3">
                                    <label for="pxp-candidate-email" class="form-label">Nr.</label>
                                    <input type="email" id="pxp-candidate-email" class="form-control" placeholder="Nr.">
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="mb-3">
                                    <label for="pxp-candidate-email" class="form-label">Bus</label>
                                    <input type="email" id="pxp-candidate-email" class="form-control" placeholder="Bus">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="mb-3">
                                    <label for="pxp-candidate-email" class="form-label">Postcode</label>
                                    <input type="email" id="pxp-candidate-email" class="form-control"
                                        placeholder="Postcode">
                                </div>
                            </div>
                            <div class="col-sm-9">
                                <div class="mb-3">
                                    <label for="pxp-candidate-email" class="form-label">Gemeente</label>
                                    <input type="email" id="pxp-candidate-email" class="form-control"
                                        placeholder="Gemeente">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mb-3">
                                    <label for="pxp-candidate-email" class="form-label">E-mail (publiek weergegeven op
                                        je organisatiepagina)</label>
                                    <input type="email" id="pxp-candidate-email" class="form-control"
                                        placeholder="E-mailadres van de organisatie">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mb-3">
                                    <label for="pxp-candidate-title" class="form-label">Telefoon</label>
                                    <input type="text" id="pxp-candidate-title" class="form-control"
                                        placeholder="Telefoon van de organisatie">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mb-3">
                                    <label for="pxp-candidate-location" class="form-label">Website</label>
                                    <input type="tel" id="pxp-candidate-location" class="form-control" value="https://">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mb-3 form-check">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                    <label class="form-check-label" for="exampleCheck1">Ik aanvaard de <a
                                            href="gebruiksvoorwaarden.html">Gebruiksvoorwaarden</a> en de <a
                                            href="privacy.html">Privacyverklaring</a> van deze website</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4 mt-lg-5 text-center">
                    <button class="btn rounded-pill pxp-section-cta">Verzenden</button>
                </div>
            </form>
        </div>
    </div>
</section>


<div id="bestuur" class="mt-50"></div>

<div class="pxp-container d-flex justify-content-center mt-5">
    <div class="col-6 pxp-dashboard-content-details pxp-register-form">
        <h1 class="text-center">Registreer als lokaal bestuur</h1>
        Als lokaal bestuur kan je hier een registratieaanvraag lanceren. We kijken je gegevens eerst na alvorens je kan
        beginnen met het plaatsen van vacatures. Je krijgt een bericht per e-mail als het zover is!
        <form>
            <div class="row mt-4">
                <div class="col-lg-12">
                    <div class="row mb-3">
                        <div class="col-sm-12 fs-4 fw-bold">
                            Interne contactpersoon
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="mb-3">
                                <label for="pxp-candidate-title" class="form-label">Voornaam</label>
                                <input type="text" id="pxp-candidate-title" class="form-control"
                                    placeholder="Je voornaam">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-3">
                                <label for="pxp-candidate-location" class="form-label">Naam</label>
                                <input type="tel" id="pxp-candidate-location" class="form-control"
                                    placeholder="Je familienaam">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label for="pxp-candidate-email" class="form-label">E-mail</label>
                                <input type="email" id="pxp-candidate-email" class="form-control"
                                    placeholder="Je e-mailadres">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label for="pxp-candidate-title" class="form-label">Wachtwoord</label>
                                <input type="text" id="pxp-candidate-title" class="form-control"
                                    placeholder="Kies een wachtwoord">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label for="pxp-candidate-location" class="form-label">Herhaal wachtwoord</label>
                                <input type="tel" id="pxp-candidate-location" class="form-control"
                                    placeholder="Herhaal je wachtwoord">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-12">
                            <div class="mb-3 form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                <label class="form-check-label" for="exampleCheck1">Ik wil me abonneren op de
                                    nieuwsbrief voor vrijwilligers</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-12">
                    <div class="row mb-3">
                        <div class="col-sm-12 fs-4 fw-bold">
                            Lokaal bestuur
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="mb-3">
                                <label for="pxp-candidate-email" class="form-label">Postcode</label>
                                <input type="email" id="pxp-candidate-email" class="form-control"
                                    placeholder="Postcode">
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <div class="mb-3">
                                <label for="pxp-candidate-email" class="form-label">Gemeente</label>
                                <input type="email" id="pxp-candidate-email" class="form-control"
                                    placeholder="Gemeente">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label for="pxp-candidate-email" class="form-label">E-mail (publiek weergegeven op je
                                    organisatiepagina)</label>
                                <input type="email" id="pxp-candidate-email" class="form-control"
                                    placeholder="E-mailadres van de organisatie">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label for="pxp-candidate-title" class="form-label">Telefoon</label>
                                <input type="text" id="pxp-candidate-title" class="form-control"
                                    placeholder="Telefoon van de organisatie">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mb-3">
                                <label for="pxp-candidate-location" class="form-label">Website</label>
                                <input type="tel" id="pxp-candidate-location" class="form-control" value="https://">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mb-3 form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                <label class="form-check-label" for="exampleCheck1">Ik aanvaard de <a
                                        href="gebruiksvoorwaarden.html">Gebruiksvoorwaarden</a> en de <a
                                        href="privacy.html">Privacyverklaring</a> van deze website</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4 mt-lg-5 text-center">
                <button class="btn rounded-pill pxp-section-cta">Verzenden</button>
            </div>
        </form>
    </div>
</div>