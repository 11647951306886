import { Component, Input, OnInit } from '@angular/core';
import { DayOfWeek } from "src/models/enums/dayOfWeek";
import { FrequencyEnum, FrequentionTypeEnum } from 'src/models/enums/jobOfferEnums';
import { JobOffer } from 'src/models/jobOffer';

@Component({
  selector: 'app-job-moment',
  templateUrl: './job-moment.component.html',
  styleUrls: ['./job-moment.component.scss']
})
export class JobMomentComponent implements OnInit {
  @Input() job!: JobOffer;
  public freq = FrequentionTypeEnum;
  public frequency = FrequencyEnum;
  isHydrated = false;

  ngAfterViewInit(): void {
    this.isHydrated = true;
  }

  constructor() { }

  ngOnInit(): void {
  }

  getFrequency(freq: FrequencyEnum) {
    if (freq == FrequencyEnum.Daily) return 'dag';
    if (freq == FrequencyEnum.Weekly) return 'week';
    if (freq == FrequencyEnum.Monthly) return 'maand';
    return '';
  }

  getDay(day: DayOfWeek) {
    if (day == DayOfWeek.Monday) return 'maandag';
    if (day == DayOfWeek.Tuesday) return 'dinsdag';
    if (day == DayOfWeek.Wednesday) return 'woensdag';
    if (day == DayOfWeek.Thursday) return 'donderdag';
    if (day == DayOfWeek.Friday) return 'vrijdag';
    if (day == DayOfWeek.Saturday) return 'zaterdag';
    if (day == DayOfWeek.Sunday) return 'zondag';
    return '';
  }

  isOneDay(f: string, t: string) {
    var from = new Date(f);
    var till = new Date(t);
    return (from.getDay() == till.getDay() && from.getMonth() == till.getMonth() && from.getFullYear() == till.getFullYear())
  }

}
