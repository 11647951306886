import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { JobOffer } from 'src/models/jobOffer';

@Component({
  selector: 'app-google-job',
  templateUrl: './google-job.component.html',
  styleUrls: ['./google-job.component.scss']
})
export class GoogleJobComponent implements OnInit {
  @Input() job!: JobOffer;
  jsonLD!: SafeHtml;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
//    if(this.job.sectors.length > 0) {
      //add sectors to description
//      if(this.job.sectors.length == 1) {
//        this.job.description += '<br><p>Sector: ' + this.job.sectors[0].sector.name + '</p>'
//      } else {
//        this.job.description += '<br><p>Sectoren: ' + this.job.sectors.map(s => s.sector.name).join(', ') + '</p>'
//      }
//    }

//    if(this.job.themes.length > 0) {
      //add themes to description
//      if(this.job.themes.length == 1) {
//        this.job.description += '<br><p>Thema: ' + this.job.themes[0].theme.name + '</p>'
//      } else {
//        this.job.description += '<br><p>Themas: ' + this.job.themes.map(t => t.theme.name).join(', ') + '</p>'
//      }
//    }

    const json = {
      '@context': 'http://schema.org',
      '@type': 'JobPosting',
      'title': this.job.name,
      'description': this.job.description,
      'datePosted': this.job.createdAt,
      'validThrough': this.job.publishedTill,
      'employmentType': 'VOLUNTEER',
      'identifier': this.job.id,
      'hiringOrganization': {
        '@type': 'Organization',
        'name': this.job.organization.name,
        'logo': this.job.organization.logoUrl
      },
      'jobLocation': {
        '@type': 'Place',
        'address': {
          '@type': 'PostalAddress',
          'addressLocality': '',
          //'addressRegion': this.job.jobLocation.state,
          'postalCode': '',
          'streetAddress': '',
          'addressCountry': 'BE'
        }
      },
    }

    if (this.job.jobLocation) {
      json['jobLocation'] = {
        '@type': 'Place',
        'address': {
          '@type': 'PostalAddress',
          'addressLocality': this.job.jobLocation.location,
          'postalCode': this.job.jobLocation.postCode,
          'streetAddress': this.job.jobLocation.street + ' ' + this.job.jobLocation.number,
          'addressCountry': 'BE'
        }
      }
    }

    this.jsonLD = this.getSafeHTML(json);
  }

  getSafeHTML(jsonLD: {[key: string]: any}): SafeHtml {
    const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : ''; // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
