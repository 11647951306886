import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JobApplication } from 'src/models/jobApplication';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class JobApplicationService extends BaseService<JobApplication, JobApplication> {

  constructor(protected override http: HttpClient, @Inject('BASE_URL') protected override baseUrl: string) {
    super(http, baseUrl, 'JobApplication');
  }

  sendApplication(jobApplication: JobApplication): Observable<JobApplication> {
    return super.postCustom('', jobApplication);
  }
}
