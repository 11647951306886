import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { JobOffer } from "src/models/jobOffer";
import { JobService } from "src/services/job.service";
import { WidgetData } from "../models/jobOfferWidgetData";

@Injectable({ providedIn: 'root' })
export class WidgetResolver  {
  constructor(private service: JobService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<WidgetData> | Promise<WidgetData> | WidgetData {
    return this.service.getWidgetData(route.paramMap.get('uuid'), route.paramMap.get('type'));
  }
}
