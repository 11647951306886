import { animate, state, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, debounceTime, distinctUntilChanged, interval } from 'rxjs';
import { JobOffer } from 'src/models/jobOffer';
import { JobTheme } from 'src/models/jobTheme';
import { FilterFormService, SearchForm } from 'src/services/filter-form.service';
import { JobService } from 'src/services/job.service';
import { JobThemeService } from 'src/services/theme.service';
import { ApplicationSettingsService } from '../../../services/application-settings.service';
import { JobListComponent } from '../jobs/job-list/job-list.component';
import { JobModalComponent } from '../jobs/job-modal/job-modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('in', style({ 'opacity': '1' })),
      state('out', style({ 'opacity': '0' })),
      transition('* => *', [
        animate(700)
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {
  selectedVolunteer: number = 0;
  enableAnimation = false;
  state = 'in';
  currentYear = new Date().getFullYear();
  counter = 0;

  currentImage: any;

  public totalJobs$!: Observable<string>;
  public totalNearby!: number;
  public jobs!: JobOffer[];
  public jobsForMap: JobOffer[] = [];
  public totalsultNearby!: number;
  public volunteers$!: JobOffer[];
  public themes$!: JobTheme[];
  locationEnabled: boolean = false;
  searchFormSubscriber!: Subscription;
  isBrowser = isPlatformBrowser(this.platformId)

  public nearBy!: SearchForm;
  timeoutId: ReturnType<typeof setTimeout> | null = null;
  isOnline: boolean = false;

  @ViewChild(JobListComponent) jobListComponent!: JobListComponent;

  constructor(private sanitize: DomSanitizer, private title: Title, private themeService: JobThemeService, private form: FilterFormService, @Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal) {

  }

  ngOnInit(): void {


    if (isPlatformBrowser(this.platformId)) {
      const root = document.documentElement;
      root.style.removeProperty('--pxpMainColor')
      root.style.removeProperty('--pxpMainColorLight')
      this.title.setTitle("Kan ik helpen?");
    }


  }



  ngOnDestroy() {
  }

  runAnimation() {
    this.enableAnimation = true;
    this.counter = 0;
    this.toggleState();
  }

  toggleState() {
    if (this.counter < 2) {
      this.state = this.state === 'out' ? 'in' : 'out';
      this.counter++;
    }
  }

  toggleImg() {
    this.selectedVolunteer == this.volunteers$.length - 1 ? (this.selectedVolunteer = 0) : ++this.selectedVolunteer;
  }

  onDone($event: any) {
    if (this.enableAnimation) {
      if (this.counter === 1) {
        this.toggleImg();
      }
      this.toggleState();
    }
  }

}
