import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import * as Aos from 'aos';
import { ApplicationSettingsService } from '../../services/application-settings.service';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private settingsService: ApplicationSettingsService) { }

  loader = false;
  isOnline = false;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) { Aos.init(); }

    this.settingsService.isOnline().subscribe(x => {
      this.isOnline = x
    })

  }

}
