<header class="pxp-header fixed-top pxp-no-bg">
    <div class="pxp-container">
        <div class="pxp-header-container d-flex justify-content-center justify-content-md-between">
            <div class="col-12 col-md-6 d-block d-md-inline-block pxp-logo text-center text-md-start ps-0 ps-md-2">
                <a routerLink="/" class="pxp-animate">Kan ik helpen?</a>
            </div>
            <div class="col-12 col-md-6 d-block d-md-inline-block pxp-nav text-center text-md-end">
                <app-postcode-select [control]="postcodeControl" [inputClass]="'input-sm'" [navigateOnSelect]="true" [showButton]="false"></app-postcode-select>
            </div>
        </div>
    </div>
</header>