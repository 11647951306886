import { Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ODataResponse } from 'src/models/OData';


export class BaseService<T, TList> {
  constructor(protected http: HttpClient, @Inject('BASE_URL') protected baseUrl: string, protected controller: string) {
  }

  odata(action?: string): Observable<ODataResponse<T>> {
    if (action) {
      return this.http.get<ODataResponse<T>>(this.baseUrl + "odata/" + this.controller + action + "&$count=true")
        .pipe(
          catchError(this.handleError<ODataResponse<T>>())
        );
    } else {
      return this.http.get<ODataResponse<T>>(this.baseUrl + "odata/" + this.controller + "&$count=true")
        .pipe(
          catchError(this.handleError<ODataResponse<T>>())
        );
    }
  }

  odataCount(action?: string): Observable<string> {
    return this.http.get(this.baseUrl + "odata/" + this.controller + "/$count", { responseType: 'text' })
      .pipe(
        catchError(this.handleError<string>())
      );
  }

  get(action: string): Observable<T> {
    return this.http.get<T>(this.baseUrl + this.controller + action)
      .pipe(
        catchError(this.handleError<T>())
      );
  }

  getCustomType<customT>(action: string): Observable<customT> {
    return this.http.get<customT>(this.baseUrl + this.controller + action)
      .pipe(
        catchError(this.handleError<customT>())
      );
  }


  getAll(action?: string): Observable<TList[]> {
    if (action) {
      return this.http.get<TList[]>(this.baseUrl + this.controller + action)
        .pipe(
          catchError(this.handleError<TList[]>([]))
        );
    } else {
      return this.http.get<TList[]>(this.baseUrl + this.controller)
        .pipe(
          catchError(this.handleError<TList[]>([]))
        );
    }
  }

  getAllCustomtype<customT>(action?: string): Observable<customT[]> {
    return this.http.get<customT[]>(this.baseUrl + this.controller + action)
      .pipe(
        catchError(this.handleError<customT[]>([]))
      );
  }

  postCustom<T>(action: string, item: T): Observable<T> {
    return this.http.post<T>(this.baseUrl + this.controller + action, item)
      .pipe(
        catchError(this.handleError<T>())
      );
  }

  post(action: string, item: T) {
    return this.http.post(this.baseUrl + this.controller + action, item)
      .pipe(
        catchError(this.handleError<T>())
      );
  }

  put(action: string, item: T) {
    return this.http.put(this.baseUrl + this.controller + action, item)
      .pipe(
        catchError(this.handleError<T>())
      );
  }

  delete(action: string) {
    return this.http.delete(this.baseUrl + this.controller + action)
      .pipe(
        catchError(this.handleError<T>())
      );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

   
}
