<div *ngIf="job.frequentionType == freq.Once && job.moments.length > 0">
    Op {{ job.moments[0].from | date: 'EEEE d MMMM' }} tussen {{ job.moments[0].from | date: 'HH:mm' }} en {{ job.moments[0].till | date: 'HH:mm' }}
</div>

<div *ngIf="job.frequentionType == freq.Subsequently && job.moments.length > 0">
    <span *ngIf="isOneDay(job.moments[0].from,job.moments[0].till)">Op {{ job.moments[0].from | date: 'dd/MM/YY'
        }}</span>
    <span *ngIf="!isOneDay(job.moments[0].from,job.moments[0].till)">Van {{ job.moments[0].from | date: 'dd/MM/YY' }}
        tot {{ job.moments[0].till | date: 'dd/MM/YY' }}</span> tussen {{ job.moments[0].from | date: 'HH:mm' }} en {{ job.moments[0].till | date: 'HH:mm' }}
</div>

<div *ngIf="job.frequentionType == freq.Multiple && job.moments.length > 0">
    <div *ngFor="let moment of job.moments">
        Van {{ moment.from | date: 'dd/MM/YY' }} tot {{ moment.till | date: 'dd/MM/YY' }} tussen {{ moment.from | date: 'HH:mm' }} en {{ moment.till | date: 'HH:mm' }}
    </div>
</div>

<div *ngIf="job.frequentionType == freq.Recurring && job.moments.length > 0">
    <p *ngIf="!job.frequency">Terugkerend op een vaste dag of moment<br />en dit van {{ job.moments[0].from | date: 'dd MMMM YYYY' }} tot {{ job.moments[0].till | date: 'dd MMMM YYYY' }}</p>
    <p *ngIf="job.frequency == frequency.Daily">Elke dag </p>
    <p *ngIf="job.frequency == frequency.Weekly">Elke {{ getDay(job.moments[0].dayOfTheWeek) }}</p>
</div>

<div *ngIf="job.frequentionType == freq.Indefinite">
    <p *ngIf="isHydrated && job.availability" [innerHtml]="job.availability"></p>
    <p *ngIf="!job.availability && job.id != 5006">Onderling af te spreken</p>
    <p *ngIf="!job.availability && job.id == 5006">Zondag 3 december om 11u.</p>
</div>