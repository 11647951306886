import { Component, Input, OnInit } from '@angular/core';
import { JobOffer } from 'src/models/jobOffer';
import { JobTheme } from 'src/models/jobTheme';
import { SearchForm } from 'src/services/filter-form.service';
import { JobService } from 'src/services/job.service';
import { WidgetData } from '../../../../models/jobOfferWidgetData';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss'],
})
export class JobListComponent implements OnInit {
  @Input() col: string = 'col-xxl-6';
  @Input() jobs$!: JobOffer[];
  @Input() theme!: JobTheme;
  @Input() widget: WidgetData | undefined = undefined;
  @Input() showFeatured = true;
  @Input() searchForm!: SearchForm;

  public selectedJob!: JobOffer;
  featuredJobs: JobOffer[] = [];
  featuredJob: JobOffer | null | undefined = null;
  alwaysFeatured: JobOffer[] = [];
  themeFeatured: JobOffer[] = [];

  constructor(private jobService: JobService) {
  }

  ngOnInit(): void {
    if (this.widget) this.col = 'col-md-6';
    this.featuredJob = this.jobs$.find(x => x.featured === true);
    //this.jobService.getFeaturedJobs().subscribe((jobs) => {
    //  //put featured jobs at the top of the list
    //  this.alwaysFeatured = jobs.value;
    //  this.featuredJobs = [...jobs.value, ...this.featuredJobs]
    //});

    if (this.searchForm) {
      if (this.searchForm.themes.value.length > 0) {
        this.loadInThemeFeatured(this.searchForm.themes.value);
      }

      this.searchForm.themes.valueChanges.subscribe((themes) => {
        if (themes.length > 0) this.loadInThemeFeatured(themes);
        if (themes.length == 0) this.featuredJobs = this.alwaysFeatured;
      })
    }

  }

  loadInThemeFeatured(value: JobTheme[]) {
    this.jobService.getFeaturedJobsByThemes(value).subscribe((jobs) => {
      //put featured jobs at the end of the list
      this.themeFeatured = jobs.value;
      this.featuredJobs = [...this.alwaysFeatured, ...jobs.value]
    });
  }

  get jobs() {
    return this.jobs$ ? this.jobs$.filter((job) => !this.featured.find((f) => f.id == job.id)) : [];
  }

  get featured() {
    return this.showFeatured ? this.removeDuplicates(this.featuredJobs) : this.themeFeatured;
  }

  removeDuplicates(jobs: JobOffer[]) {
    return jobs.filter((job, index, self) => self.findIndex((j) => j.id == job.id) == index);
  }

}
