<div class="loading" *ngIf="loader">
  <div class="loader"></div>
</div>

<!--
<app-navbar></app-navbar>
-->
<ng-container *ngIf="isOnline">

  <router-outlet></router-outlet>
  <app-footer></app-footer>
</ng-container>
