<div class="modal-content">
  <div class="p-0 pb-3" style="border-bottom: 1px solid #dee2e6;">
    <div class="row">
      <div class="col-11 modal-title text-center mt-4 fs-3">
        {{ job.spotlightName }} is hier vrijwilliger
      </div>
      <div class="col-1">
        <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-8">
        <span [innerHtml]="job.spotlightText">
        </span>
        <div class="d-flex justify-content-evenly mt-4">
          <a class="btn btn-sm rounded-pill pxp-section-cta mx-2 px-3 py-1 fs-6" (click)="organisation()" role="button">{{ job.organizationName }}</a>
          <a class="btn btn-sm rounded-pill pxp-section-cta mx-2 px-3 py-1 fs-6" (click)="jobs()">Vacatures</a>
        </div>
      </div>
      <div class="col-4">
        <div class="row p-0">
          <img *ngIf="job.spotlightPicture1Url" class="pxp-img img-fluid p-0" src="{{getUrl(job.spotlightPicture1Url)}}">
        </div>
        <div class="row mt-3 p-0">
          <div class="col-6 p-0">
            <img *ngIf="job.spotlightPicture2Url" class="w-100 pe-2" src="{{getUrl(job.spotlightPicture2Url)}}" style="border-radius: 20px;" href="#">
          </div>
          <div class="col-6 p-0">
            <img *ngIf="job.spotlightPicture3Url" class="w-100 ps-2" src="{{getUrl(job.spotlightPicture3Url)}}" style="border-radius: 20px;" href="#">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
