import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobApplication } from 'src/models/jobApplication';
import { JobOffer } from 'src/models/jobOffer';
import { JobApplicationService } from 'src/services/job-application.service';
import Swal from 'sweetalert2';
import { JobApplicationForm } from './form-models/job-application-form-model';
import { JobSkillForm, JobTaskForm } from './form-models/task-form-model';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  @Input() job!: JobOffer;
  @ViewChild('content') content!: ElementRef;
  form!: JobApplicationForm;
  taskForms: FormArray | null = null
  skillForms: FormArray | null = null
  sending = false;
  step = 1;
  numberSelected = 0;
  constructor(private modalService: NgbModal, private applicationService: JobApplicationService) { }

  ngOnInit(): void {
    this.form = new JobApplicationForm({ jobOfferId: this.job.id } as JobApplication);
    console.log(this.job.tasks)
    console.log(this.job.skills)
    if (this.job.tasks) {
      this.taskForms = new FormArray(this.job.tasks.map(task => new JobTaskForm(task)));
      this.taskForms.valueChanges.subscribe(x => {
        this.numberSelected = 0
        if (this.taskForms)
          for (var i = 0; i < this.taskForms.length; i++) {
            if (this.asTaskForm(this.taskForms.at(i)).selected)
              this.numberSelected++;
          }

      })
    }
    if (this.job.skills) {
      this.skillForms = new FormArray(this.job.skills.map(skill => new JobSkillForm(skill, new FormBuilder(), true)));
    }
  }

  open(job: JobOffer) {
    this.job = job;
    this.modalService.open(this.content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, windowClass: 'pxp-user-modal' }).result.then();
  }

  onSubmit() {
    this.sending = true;
    debugger
    if (this.job.tasks && this.taskForms) {
      for (var i = 0; i < this.taskForms.length; i++) {
        let taskform = this.asTaskForm(this.taskForms.at(i));
        if (taskform.selected.value) {
          for (var j = 0; j < taskform.skills.length; j++) {
            let skill = this.asSkillForm(taskform.skills.at(j));
            if (skill.selected.value)
              this.form.addTaskAndSkill(skill.id.value, taskform.id.value)
          }
        }
      }
    }
    if (this.job.skills && this.skillForms) {
      for (var j = 0; j < this.skillForms.length; j++) {
        let skill = this.asSkillForm(this.skillForms.at(j));
        if (skill.selected.value)
          this.form.addTaskAndSkill(skill.id.value)
      }
    }

    let jobApp = this.form.value as JobApplication;

    this.applicationService.sendApplication(jobApp).subscribe(data => {
      this.form.reset();
      this.sending = false;
      if (data && data.id > 0) {
        this.step++;

      } else {
        Swal.fire({
          title: 'Oeps. Er liep iets fout bij het verzenden.',
          text: 'Probeer het nog eens of neem rechtstreeks contact op met de organistie.',
          icon: 'error',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-lg',
            denyButton: 'btn btn-danger btn-lg',
            closeButton: 'btn btn-primary btn-lg',
            cancelButton: 'btn btn-danger btn-lg'
          },
        })
      }
    }, error => {
      this.sending = false;
      Swal.fire({
        title: 'Oeps. Er liep iets fout bij het verzenden.',
        text: 'Probeer het nog eens of neem rechtstreeks contact op met de organistie.',
        icon: 'error',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success btn-lg',
          denyButton: 'btn btn-danger btn-lg',
          closeButton: 'btn btn-primary btn-lg',
          cancelButton: 'btn btn-danger btn-lg'
        },
      })
    }
    );
  }

  isWeldoeners() {
    return this.job.themes.some(t => t.themeId === 8);
  }

  dismiss() {
    this.modalService.dismissAll();
  }

  asTaskForm(control: AbstractControl) {
    return control as JobTaskForm;
  }

  asSkillForm(control: AbstractControl) {
    return control as JobSkillForm;
  }

  hasValidatorTrue(skill: AbstractControl) {
    return skill.hasValidator(Validators.requiredTrue)
  }

  next() {
    this.step++;
  }
  back() {
    this.step--;
  }

}
