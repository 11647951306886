import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JobPostcode } from '../../../models/jobPostcode';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnDestroy, OnInit {
  postcode!: JobPostcode
  postcodeControl: FormControl = new FormControl("");
  logoUrl: string = "assets/images/logo-vlaanderen-vrijwilligt.svg"
  listener;

  constructor(@Inject('IMAGE_SERVER_URL') public imageUrl: string, private route: ActivatedRoute, private renderer2: Renderer2, @Inject(PLATFORM_ID) private platformId: Object) {
    this.listener = this.renderer2.listen('window', 'scroll', (e) => this.onScroll());
  }
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.route.data.subscribe(({ postcode }) => {
        const root = document.documentElement;
        if (postcode) {
          this.postcode = postcode;
          if (this.postcode.logoUrl)
            this.logoUrl = this.imageUrl + this.postcode.logoUrl;
          else
            this.logoUrl = "assets/images/logo-vlaanderen-vrijwilligt.svg";
          //this.postcodeControl.patchValue(this.postcode);
          root.style.setProperty('--pxpMainColor', this.postcode.primaryColor);
          root.style.setProperty('--pxpMainColorLight', this.postcode.backgroundColor);
        } else {
          root.style.removeProperty('--pxpMainColor')
          root.style.removeProperty('--pxpMainColorLight')
        }
      });
    }
  }

  onScroll(): void {
    if ($('.pxp-header').hasClass('pxp-bigger') || $('.pxp-header').hasClass('pxp-no-bg')) {
      if (window.pageYOffset > 20) {
        $('.pxp-header').addClass('pxp-is-sticky');
      } else {
        $('.pxp-header').removeClass('pxp-is-sticky');
      }
    } else if ($('.pxp-header').hasClass('pxp-no-bg')) {
      if (window.pageYOffset > 0) {
        $('.pxp-header').addClass('pxp-is-sticky');
      } else {
        $('.pxp-header').removeClass('pxp-is-sticky');
      }
    } else {
      if (window.pageYOffset > 93) {
        $('.pxp-header').addClass('pxp-is-sticky');
      } else {
        $('.pxp-header').removeClass('pxp-is-sticky');
      }
    }
  }

  ngOnDestroy(): void {
    this.listener();
  }

}
