<app-navbar></app-navbar>

<ng-container *ngIf="postcode">
    <section id="top" class="mt-5 mt-md-0 pt-100 pb-100">
        <div *ngIf="postcode" class="pxp-container mt-30">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-8">
                    <h1 *ngIf="postcode.name && postcode.name != 'Sinterklaas'" class="pxp-title text-center">Kan ik helpen in<br /><span style="color: var(--pxpMainColor);">{{postcode.name}}?</span></h1>
                    <h1 *ngIf="postcode.name && postcode.name == 'Sinterklaas'" class="pxp-title text-center">Kan ik <span style="color: var(--pxpMainColor);">{{postcode.name}}</span> helpen?</h1>
                </div>
            </div>
            <div class="row justify-content-center mt-5">
                <div *ngIf="postcode.name && postcode.name != 'Sinterklaas'" class="col-12 col-lg-8">
                    <!-- Actieve gebruiker - Geen vacatures - Geen Vrijwilligerskorps -->
                    <ng-container *ngIf="!postcode.redirectUrl">
                        <h2 *ngIf="totalResults == 0" class="text-center mb-1">Momenteel niet.</h2>
                        <h5 *ngIf="totalResults == 0 && !hasFeatured" class="text-center">Er werden op kanikhelpen.be geen crisisvacatures<br />voor {{postcode.name}} gevonden.</h5>

                        <!-- Actieve gebruiker - Geen vacatures - Wel Vrijwilligerskorps -->
                        <h5 *ngIf="totalResults == 0 && hasFeatured" class="text-center">
                            Maar je kan je wel al kandidaat stellen
                            <br />voor het Vrijwilligerskorps {{postcode.name}}.
                        </h5>

                        <!-- Actieve gebruiker - Wel vacatures - Geen Vrijwilligerskorps -->
                        <h2 *ngIf="totalResults > 0" class="text-center mb-1">Graag!</h2>
                        <h5 *ngIf="totalResults == 1" class="text-center">Er staat 1 vacature op jou te wachten.</h5>
                        <h5 *ngIf="totalResults > 1" class="text-center">
                            Er staan {{totalResults}} vacatures op jou te wachten.
                        </h5>
                    </ng-container>

                    <!-- Geen actieve gebruiker - Wenst doorverwezen te worden -->
                    <ng-container *ngIf="postcode.redirectUrl">
                        <h2 class="text-center mb-1">We verwijzen je graag door.</h2>
                        <h5 class="text-center">
                            <p>
                                Het lokaal bestuur {{postcode.name}} gebruikt in noodsituaties of crisis een ander platform dan kanikhelpen.be.
                            </p>
                            <p>
                                <a href="{{postcode.redirectUrl}}" target="_blank" class="btn btn-sm rounded-pill pxp-section-cta">
                                    Breng me naar dat platform
                                </a>
                            </p>
                        </h5>
                    </ng-container>
                </div>
                <div *ngIf="postcode.name && postcode.name == 'Sinterklaas'" class="col-12 col-lg-8">
                    <h2 class="text-center mb-1">Dat is erg vriendelijk!</h2>
                    <h5 class="text-center">Maar {{postcode.name}} heeft momenteel al<br />meer dan genoeg hulp-Sinterklazen.</h5>
                </div>
            </div>

            <!-- Actieve gebruiker - Geen vacatures - Geen Vrijwilligerskorps -->
            <div *ngIf="!postcode.redirectUrl && totalResults == 0 && !hasFeatured && postcode.name != 'Sinterklaas'" class="row justify-content-between align-items-top mt-2">
                <div class="col-lg-6 text-center text-lg-end mt-2 mt-lg-0 mb-3 mb-lg-0 pe-2">
                    <a href="{{postcode.nameForUrl}}#later" class="btn btn-sm rounded-pill pxp-section-cta">
                        Kan ik later helpen?
                    </a>
                </div>
                <div class="col-lg-6 text-center text-lg-start ps-2">
                    <a href="{{postcode.nameForUrl}}#elders" class="btn btn-sm rounded-pill pxp-section-cta">
                        Kan ik elders helpen?
                    </a>
                </div>
            </div>
        </div>
    </section>

    <section class="d-none">
        <div *ngIf="postcode" class="pxp-container">
            <div class="pxp-single-job-content mt-4 mt-lg-5">
                <div class="row">
                    <div class="col-lg-7 col-xl-8">
                        <div class="row justify-content-between align-items-center">
                            <div>
                                <h1 *ngIf="postcode.name" style="font-weight: 700; font-size: 48px; letter-spacing: -2px;">
                                    {{postcode.name}}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-xl-4">
                        <div *ngIf="postcode" class="mt-3 mt-lg-5 pxp-single-job-side-panel">
                            <div>
                                <div class="pxp-single-job-side-info-data" style="font-size: 25px;">
                                    <img *ngIf="postcode.logoUrl" src="{{getUrl(postcode.logoUrl)}}" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section *ngIf="totalResults == 0 && hasFeatured" class="pb-100">
        <div class="pxp-container">
            <app-job-list [jobs$]="jobs"></app-job-list>
        </div>
    </section>

    <section *ngIf="totalResults > 0" class="pb-100" style="background-color: var(--pxpMainColorLight); padding-top: 50px;">
        <div class="pxp-container">
            <app-job-list [jobs$]="jobs"></app-job-list>
        </div>
    </section>

    <section *ngIf="!postcode.redirectUrl && totalResults == 0 && postcode.name != 'Sinterklaas'" class="pt-100 pb-100" style="background-color: var(--pxpMainColorLight);">
        <div class="pxp-container">
            <div class="row justify-content-between align-items-top">
                <div class="col-lg-6">
                    <div class="pxp-info-caption mt-4 mt-sm-5 mt-lg-0">
                        <h2 class="pxp-section-h2">Waarom kan ik nu niet helpen?</h2>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="pt-2" style="font-size: 1.25rem;">
                        <p class="pxp-text-light">
                            Eerst en vooral, bedankt voor je interesse om tijdens een noodsituatie of crisis in {{postcode.name}} als vrijwilliger te komen helpen.
                        </p>
                        <span class="pxp-text-light">
              Als je hier geen vacature vindt, kan dat deze redenen hebben:
              <ul>
                <li class="my-2">
                  Er is momenteel geen noodsituatie of crisis in {{postcode.name}}.
                </li>
                <li class="mb-2">
                  Er is wel een noodsituatie of crisis in {{postcode.name}}, maar er is al voldoende
                  hulp voor alle taken.
                </li>
                <li>
                  Er is wel een noodsituatie of crisis in {{postcode.name}}, maar momenteel lijsten we
                  nog alle taken op.
                </li>
              </ul>
            </span>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section *ngIf="!postcode.redirectUrl && postcode.name != 'Sinterklaas'" id="later" class="pt-100 pb-100">
        <div class="pxp-container">
            <div class="row justify-content-between align-items-top">
                <div class="col-lg-6">
                    <div class="pxp-info-caption mt-4 mt-sm-5 mt-lg-0" data-aos="fade-up" data-aos-duration="600">
                        <h2 *ngIf="totalResults == 0" class="pxp-section-h2">Kan ik later helpen?</h2>
                        <h2 *ngIf="totalResults > 0" class="pxp-section-h2">Niets voor jou?</h2>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="pt-2" style="font-size: 1.25rem;" data-aos="fade-up" data-aos-duration="600">
                        <p *ngIf="totalResults == 0" class="pxp-text-light">
                            <strong>Graag!</strong><br /> Hou deze website in de gaten. Van zodra we (extra) hulp nodig hebben, plaatsen we hier een vacature.
                        </p>
                        <p *ngIf="totalResults > 0" class="pxp-text-light">
                            We zoeken erg gericht naar specifieke profielen. Als je geen vacature vindt die bij jouw profiel past, dan kan dat later wel het geval zijn.
                        </p>
                        <span class="pxp-text-light">
              <span *ngIf="totalResults == 0">
                Wil je als eerste op de hoogte zijn van zodra hier een vacature verschijnt, laat dan je
                e-mailadres achter.
              </span>
                        <span *ngIf="totalResults > 0">
                Wil je op de hoogte gebracht worden van nieuwe vacatures, laat dan je e-mailadres
                achter.
              </span>
                        <app-notification-add [postcode]="postcode"></app-notification-add>
                        </span>
                        <!--
            <p class="pxp-text-light mt-5">
                <strong>Vrijwilligerskorps {{postcode.name}}</strong><br /> Wil je deel uitmaken van het <strong>Vrijwilligerskorps {{postcode.name}}</strong>, een team van vrijwilligers dat bij een noodsituatie, ramp of crisis in {{postcode.name}}
                als eerste ingezet wordt, stel je dan <a data-bs-toggle="modal" href="#pxp-contact-modal" role="button"><strong>hier</strong></a> kandidaat.
            </p>
            <a href="https://www.{{postcode.name}}.be/vrijwilligerskorps-{{postcode.name}}" target="_blank" class="btn btn-sm rounded-pill pxp-section-cta me-2" style="height: 36px; padding-top: 4px;">Meer info</a>
            <a data-bs-toggle="modal" href="#pxp-contact-modal" class="btn btn-sm rounded-pill pxp-section-cta" style="height: 36px; padding-top: 4px;">Stel je kandidaat</a>
            -->
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="elders" class="pt-100 pb-100" style="background-color: var(--pxpMainColorLight);">
        <div class="pxp-container">
            <div class="row justify-content-between align-items-top">
                <div class="col-lg-6">
                    <div class="pxp-info-caption mt-4 mt-sm-5 mt-lg-0" data-aos="fade-up" data-aos-duration="600">
                        <h2 class="pxp-section-h2">Kan ik ergens anders helpen?</h2>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="pt-2" style="font-size: 1.25rem;" data-aos="fade-up" data-aos-duration="600">
                        <p class="pxp-text-light">
                            Deze website beperkt zich enkel tot oproepen vanuit gemeenten en steden in Vlaanderen. Hier vind je dus geen vacatures van andere organisaties of lokale verenigingen.
                        </p>
                        <span class="pxp-text-light">
              Kijk op deze 2 platformen of je ergens anders kan helpen:
              <ul>
                <li class="pxp-text-light mb-2">
                  <a href="https://www.vrijwilligerswerk.be"
                     target="_blank">www.vrijwilligerswerk.be</a>
                </li>
                <li class="pxp-text-light">
                  <a href="https://burgervrijwilligers.rodekruis.be/"
                     target="_blank">burgervrijwilligers.rodekruis.be</a>
                </li>
              </ul>
            </span>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--
    <style>
    .accordion-button:not(.collapsed) {
        background-color: #FFF2EE;
        color: #CF6451;
    }
  </style>
  -->

    <app-faq></app-faq>

    <section class="pb-100 position-relative">
        <img src="../../../assets/images/icon-helpen.png" class="d-none d-lg-block" style="height: 100px; position: absolute; bottom: 0; right:0;">
        <div class="pxp-container">
            <div class="row pxp-pl-80 justify-content-between align-items-top">
                <div class="col-lg-12 text-center mt-5">
                    <a href="{{postcode.nameForUrl}}#top" class="btn btn-sm rounded-pill pxp-section-cta">
            Terug naar
            boven<span class="fa fa-angle-up"></span>
          </a>
                </div>
            </div>
        </div>
    </section>
</ng-container>