import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { JobPostcode } from "../models/jobPostcode";
import { JobPostcodeService } from "../services/postcode.service";

@Injectable({ providedIn: 'root' })
export class PostcodeResolver {
  constructor(private service: JobPostcodeService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<JobPostcode> | Promise<JobPostcode> | JobPostcode {
    return this.service.getByName(route.paramMap.get('nameForUrl'));
  }
}
