<app-navbar></app-navbar>

<section class="pt-100">
    <div class="pxp-container pb-100" *ngIf="!success  && !failed">
        <form [formGroup]="form" (submit)="onSubmit()">

            <div class="row pxp-pl-80 justify-content-between align-items-top">
                <div class="col-lg-8 mx-auto mt-4">
                    <div class="pxp-info-caption text-center mt-4 mt-md-0" data-aos-duration="600">
                        <h2 class="pxp-section-h2 mb-5">Hoe kan ik als lokaal bestuur kanikhelpen.be gebruiken?</h2>
                    </div>
                    <div class="pxp-info-caption fs-5 mt-4">
                        De website en de functionaliteiten van kanikhelpen.be kunnen enkel door lokale besturen (gemeenten en steden) gebruikt worden. Als lokaal bestuur heb je de keuze of je de functionaliteiten al dan niet gebruikt en op welke manier.
                        <p class="ms-3 mt-3">
                            <span class="fw-bold">1. Als 'doorverwijzer' naar een ander platform</span><br /> Je gebruikt kanikhelpen.be als 'doorverwijzer' naar je eigen website of een ander platform dat je nu al gebruikt in noodsituaties of crisis.
                            Wij voorzien een link naar jouw platform.
                        </p>
                        <p class="ms-3 mt-2">
                            <span class="fw-bold">2. Om crisisvacatures te publiceren en te beheren</span><br />Je gebruikt kanikhelpen.be om snel oproepen te lanceren tijdens een noodsituatie of crisis.
                        </p>
                        <p class="ms-3 mt-2">
                            <span class="fw-bold">3. Om een Vrijwilligerskorps uit te bouwen</span><br /> Je gebruikt kanikhelpen.be om een oproep te lanceren voor vrijwilligers die zich kandidaat stellen voor het lokale Vrijwilligerskorps.
                        </p>
                        <p class="ms-3 mt-2">
                            <span class="fw-bold">4. Een combinatie van bovenstaande functionaliteiten</span><br />Je kiest als lokaal bestuur zelf welke van deze functionaliteiten je al dan niet wilt gebruiken. Een combinatie is uiteraard ook mogelijk.
                        </p>
                    </div>
                    <div class="pxp-info-caption fs-5 mt-5">
                        <h4 class="fw-bold">Hoe wenst jouw lokaal bestuur kanikhelpen.be te gebruiken?</h4>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="useType" id="useType1" formControlName="useType" value="Enkel als 'doorverwijzer' naar ons eigen of een ander platform">
                            <label class="form-check-label" for="useType1">
                Enkel als 'doorverwijzer' naar ons eigen of een ander platform
              </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="useType" id="useType2" formControlName="useType" value="Enkel om crisisvacatures te publiceren en te beheren">
                            <label class="form-check-label" for="useType2">
                Enkel om crisisvacatures te publiceren en te beheren
              </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="useType" id="useType3" formControlName="useType" value="Enkel om een Vrijwilligerskorps uit te bouwen">
                            <label class="form-check-label" for="useType3">
                Enkel om een Vrijwilligerskorps uit te bouwen
              </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="useType" id="useType4" formControlName="useType" value="Een combinatie van de verschillende functionaliteiten">
                            <label class="form-check-label" for="useType4">
                Een combinatie van de verschillende functionaliteiten
              </label>
                        </div>
                    </div>

                    <div class="pxp-info-caption fs-5 mt-5">
                        <h4 class="fw-bold">Is deze aanvraag dringend?</h4>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="urgent" id="urgent1" formControlName="urgent" [value]="valueTrue">
                            <label class="form-check-label" for="urgent1">
                Ja, wij kampen momenteel met een noodsituatie of crisis
              </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="urgent" id="urgent2" formControlName="urgent" [value]="valueFalse">
                            <label class="form-check-label" for="urgent2">
                Nee, er is momenteel geen noodsituatie of crisis
              </label>
                        </div>
                    </div>

                    <div class="pxp-info-caption fs-5 mt-5">
                        <h4 class="fw-bold mb-3">Voor welk lokaal bestuur?</h4>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row mb-3">
                                    <div class="col-sm-4">
                                        <label class="form-label">Postcode</label>
                                    </div>
                                    <div class="col-sm-3">
                                        <input type="number" class="form-control" formControlName="postcode" id="postcode">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <label class="form-label">Hoofdgemeente</label>
                                    </div>
                                    <div class="col-sm-8">
                                        <input class="form-control" formControlName="postcodeName" id="postcodeName">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-lg-12">
                                <div class="row mb-3">
                                    <div class="col-sm-12 fs-4 fw-bold">
                                        Interne contactpersoon
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-4">
                                        <label class="form-label">Voornaam</label>

                                    </div>
                                    <div class="col-sm-8">
                                        <input class="form-control" formControlName="firstName" id="firstName">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-4">
                                        <label class="form-label">Familienaam</label>
                                    </div>
                                    <div class="col-sm-8">
                                        <input class="form-control" formControlName="lastName" id="lastName">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-4">
                                        <label class="form-label">Functie</label>
                                    </div>
                                    <div class="col-sm-8">
                                        <input class="form-control" formControlName="function" id="function">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-4">
                                        <label class="form-label">E-mailadres</label>
                                    </div>
                                    <div class="col-sm-8">
                                        <input class="form-control" formControlName="emailadres" id="emailadres">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-4">
                                        <label class="form-label">Telefoon / GSM</label>
                                    </div>
                                    <div class="col-sm-8">
                                        <input class="form-control" formControlName="telephone" id="telephone">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="mb-3 form-check">
                                            <input type="checkbox" class="form-check-input" formControlName="terms" id="terms">
                                            <label class="form-check-label" for="terms">
                        Ik aanvaard de <a routerLink="../algemene-gebruiksvoorwaarden" target="_blank">Gebruiksvoorwaarden</a>
                        en de <a routerLink="../privacy-verklaring" target="_blank">Privacyverklaring</a>
                        van deze website
                      </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4 mt-lg-5 text-center">
                            <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (success)="handleSuccess($event)" [useGlobalDomain]="false" formControlName="recaptcha" />

                            <button type="submit" class="btn rounded-pill pxp-section-cta">Verzenden</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-none col-lg-12 mt-5">
                <div class="pxp-info-caption text-center mt-4 mt-md-0" data-aos="fade-up" data-aos-duration="600">
                    <h2 class="pxp-section-h2 mb-2">Toch nog een andere vraag?</h2>
                    <h3 class="mb-4">Contacteer ons: <a href="mailto:kanikhelpen@vsvw.be">kanikhelpen@vsvw.be</a></h3>
                </div>
            </div>
        </form>
    </div>
    <div class="pxp-container pb-100" *ngIf="success">
        <div class="row pxp-pl-80 justify-content-between align-items-top">
            <div class="col-lg-8 mx-auto mt-4">
                <div class="pxp-info-caption text-center mt-4 mt-md-0" data-aos-duration="600">
                    <h2 class="pxp-section-h2 mb-5">Bedankt voor je aanvraag.</h2>
                    <div class="pxp-info-caption fs-5 mt-4">
                        <p>
                            Je aanvraag is succesvol verzonden. We bewerken deze zo snel mogelijk en nemen indien nodig contact met je op moesten we hierover nog bijkomende vragen hebben.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pxp-container pb-100" *ngIf="failed">
        <div class="row pxp-pl-80 justify-content-between align-items-top">
            <div class="col-lg-10 mx-auto mt-4">
                <div class="pxp-info-caption text-center mt-4 mt-md-0" data-aos-duration="600">
                    <h2 class="pxp-section-h2 mb-5">Je aanvraag werd <span style="color: #CF0107">niet</span> verzonden.</h2>
                    <div class="pxp-info-caption fs-5 mt-4">
                        <p>
                            Opgelet! Er werd een fout ontdekt tijdens het verzenden van je aanvraag.<br />
                            <a routerLink="/start" target="_blank" style="text-decoration: underline; font-weight: bold;">Klik hier</a> om het opnieuw te proberen.
                        </p>
                        <p>
                            Blijft het probleem zich voordoen, <a href="mailto:kanikhelpen@vsvw.be?subject=Aanvraag toegang kanikhelpen.be&body=GEGEVENS LOKAAL BESTUUR%0D%0A- Postcode: %0D%0A- Hoofdgemeente: %0D%0A%0D%0AINTERNE CONTACTPERSOON%0D%0A- Voornaam: %0D%0A- Familienaam: %0D%0A- Functie: %0D%0A- E-mailadres: %0D%0A- Telefoon: %0D%0A%0D%0AHOE WILLEN JULLIE KANIKHELPEN.BE GEBRUIKEN?%0D%0A<schrappen wat niet past>%0D%0AO Enkel als 'doorverwijzer' naar ons eigen of een ander platform%0D%0AO Enkel om crisisvacatures te publiceren en te beheren%0D%0AO Enkel om een Vrijwilligerskorps uit te bouwen%0D%0AO Een combinatie van de verschillende functionaliteiten%0D%0A%0D%0AIS DEZE AANVRAAG DRINGEND?%0D%0A<schrappen wat niet past>%0D%0AO JA, wij kampen momenteel met een noodsituatie of crisis%0D%0AO Nee, er is momenteel geen noodsituatie of crisis"
                                style="text-decoration: underline; font-weight: bold;">klik dan hier</a> om je aanvraag per mail te verzenden.
                        </p>
                        <p>
                            Onze excuses voor dit tijdelijke ongemak.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
