import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { JobOffer } from 'src/models/jobOffer';
import { ODataResponse } from 'src/models/OData';
import { BaseService } from './base.service';
import { Location } from 'src/models/location';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends BaseService<Location, Location> {

  constructor(protected override http: HttpClient, @Inject('BASE_URL') protected override baseUrl: string) {
    super(http, baseUrl, 'Location');
  }

  getByPost(post: string): Observable<Location[]> {
    return super.getAll(`?$filter=contains(Name, '${post}') or contains(PostCode, '${post}')`);
  }

  getAllCities(): Observable<Location[]> {
    return super.getAll();
  }
}
