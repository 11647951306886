import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { JobSector } from 'src/models/jobSector';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class JobSectorService extends BaseService<JobSector, JobSector> {

  constructor(protected override http: HttpClient, @Inject('BASE_URL') protected override baseUrl: string) {
    super(http, baseUrl, 'JobSector');
  }

  getById(id: number) {
    return super.get(`/${id}`);
  }

  getAllSectors() {
    return super.getAll();
  }
}
