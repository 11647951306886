import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import locale from '@angular/common/locales/nl-BE';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainModule } from './main/main.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

registerLocaleData(locale);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    MainModule,
    // OrganisationModule,
    // VolunteerModule,
    HttpClientModule,
    NgbModule,
    TransferHttpCacheModule,
    ToastrModule.forRoot()
  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    { provide: 'APP_KEY', useFactory: getAppKey, deps: [] },
    { provide: 'IMAGE_SERVER_URL', useFactory: getImageServerUrl, deps: [] },
    { provide: LOCALE_ID, useValue: 'nl-BE' },
    provideClientHydration(),
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: BrowserStateInterceptor,
    //   multi: true,
    // }
    //{ provide: LocationStrategy, useClass: HashLocationStrategy },
    //{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getBaseUrl() {
  return environment.apiUrl;
}

export function getAppKey() {
  return environment.appKey;
}

export function getImageServerUrl() {
  return environment.imageServerUrl;
}

