<section class="pt-100">
    <div class="pxp-container">
        <div class="row pxp-pl-80 justify-content-between align-items-top">
            <div class="col-lg-12">
                <div class="pxp-info-caption text-center mt-4 mt-md-0" data-aos="fade-up" data-aos-duration="600">
                    <h2 class="pxp-section-h2 mb-4">Enkele veelgestelde vragen</h2>
                </div>
            </div>
            <div class="col-lg-8 mx-auto">
                <div data-aos="fade-up" data-aos-duration="600">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                  Wie beheert deze website?
                              </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <strong>Het Vlaams Steunpunt Vrijwilligerswerk.</strong><br /> Al vele jaren ondersteunen wij het vrijwilligerswerk in Vlaanderen en dat doen we in opdracht van de Vlaamse overheid. Het is ook diezelfde Vlaamse overheid
                                    die ons gevraagd heeft om deze website te bouwen en te beheren.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                  Wie kan hier vacatures plaatsen?
                              </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <strong>Enkel lokale besturen (= gemeenten en steden).</strong><br /> Alle lokale besturen hebben toegang tot dit platform waarmee ze vacatures kunnen activeren en beheren. Momenteel beperken we ons enkel tot hen.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                  Is het normaal dat ik geen enkele vacature vind?
                              </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <strong>Oh jawel, en gelukkig maar!</strong><br /> Je zal op deze site namelijk enkel vacatures vinden bij een noodsituatie of crisis. Kan je dus amper tot geen vacatures vinden, dan is dat alleen maar goed nieuws!
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                  Mag ik enkel in mijn gemeente zoeken of helpen?
                              </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <strong>Neen, kijk gerust rond.</strong><br /> Het hangt van de vacature af of je inwoner moet zijn van de gemeente of niet. Soms is dat gewenst, als het bijvoorbeeld om een paar vrijwilligers gaat. Andere keren zal
                                    een lokaal bestuur heel veel vrijwilligers nodig hebben, en staat de vacature open voor iedereen.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>