import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, Observable, OperatorFunction, Subscription, switchMap } from 'rxjs';
import { CollaborationTypeObject, FrequentionTypeEnumObject } from 'src/models/enums/jobOfferEnums';
import { JobAudience } from 'src/models/jobAudience';
import { JobOffer } from 'src/models/jobOffer';
import { JobSector } from 'src/models/jobSector';
import { JobTalent } from 'src/models/jobTalent';
import { TimeSpans } from 'src/models/timeSpans';
import { JobAudienceService } from 'src/services/audience.service';
import { FilterFormService, SearchForm } from 'src/services/filter-form.service';
import { JobService } from 'src/services/job.service';
import { LocationService } from 'src/services/location.service';
import { JobSectorService } from 'src/services/sector.service';
import { JobTalentService } from 'src/services/talent.service';
import { Location } from 'src/models/location';
import { JobTheme } from 'src/models/jobTheme';
import { JobThemeService } from 'src/services/theme.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobModalComponent } from '../job-modal/job-modal.component';
import { EnabledFilters, Filters, WidgetData } from '../../../../models/jobOfferWidgetData';
import { debug } from 'console';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() value!: any;
  @Input() enableTheme: boolean = true;
  @Input() searchForm!: SearchForm;
  @Input() theme!: JobTheme;
  @Input() locationEnabled: boolean = false;
  @Input() widget: WidgetData | undefined = undefined;
  @Input() enabledFilters: EnabledFilters = new EnabledFilters();
  @Input() filters!: Filters;
  @Input() enableStorage: boolean = false;
  @Input() showFeatured: boolean = true;

  @ViewChild('filters') filterBlock!: ElementRef;
  @ViewChild('filter') filter!: ElementRef;

  @Output() resetSecondForm = new EventEmitter<null>();
  @Output() updateSecondForm = new EventEmitter<FormGroup>();

  public jobs$!: JobOffer[];
  public sectors$!: Observable<JobSector[]>;
  public sectors: JobSector[] = [];
  public audiences$!: Observable<JobAudience[]>;
  public audiences: JobAudience[] = [];
  public talents$!: Observable<JobTalent[]>;
  public talents: JobTalent[] = [];
  public themes$!: Observable<JobTheme[]>;
  public themes: JobTheme[] = [];
  public location!: Location;
  public showMap: boolean = false;
  public filterShown: boolean = false;
  public showSmallFilter = false;
  public initialLoad = true;
  public loadingMore = false;
  public loading = false;

  public digihelperTheme: JobTheme | null = null;


  //public frequentions = FrequentionTypeEnum;
  //public ob = Object;

  dropdownSettings: any = {};

  public frequentions = FrequentionTypeEnumObject;
  public collabs = CollaborationTypeObject;
  public timeSpans = TimeSpans;
  public timeFrames!: any;

  toggleMap(): void {
    this.showMap = !this.showMap;
  }

  // public searchForm!: FormGroup;

  public currentPage = 1;
  public totalPages = 1;
  public totalResults = 0;
  skip: number = 0;
  top: number = 100;
  initialTop: number = 20;


  // weldoeners dates
  getDates(startDate: Date, endDate: Date) {
    const dates = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  dates = this.getDates(new Date(2023, 1, 25), new Date(2023, 2, 5));

  datesWithIdAndName = this.dates.map((date) => {
    return {
      "id": `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      "name": date.toLocaleDateString("nl-NL", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
    };
  });



  Arr = Array;

  searchFormSubscriber!: Subscription;

  constructor(private router: Router, private themeService: JobThemeService, private jobService: JobService, private sectorService: JobSectorService, private form: FilterFormService, private locService: LocationService, @Inject(PLATFORM_ID) private platformId: Object, private audienceService: JobAudienceService, private talentService: JobTalentService, @Inject(DOCUMENT) private document: Document, private modalService: NgbModal) {
  }

  formListener() {
    this.searchFormSubscriber = this.searchForm.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => {
      this.search();
      // this.updateSecondForm.emit(this.searchForm);
      this.skip = 0;
    });

  }

  ngOnInit(): void {
    if (!this.searchForm) {
      if (isPlatformBrowser(this.platformId) && !this.widget)
        this.getLocation();
      if (!this.searchForm)
        this.searchForm = this.form.getFilterForm();
      if (isPlatformBrowser(this.platformId)) {
        if(localStorage.getItem("searchForm") && this.enableStorage){
          this.searchForm.patchValue(JSON.parse(localStorage.getItem("searchForm")!))
        }
      }
      this.timeFrames = this.searchForm.get('timeFrames') as FormArray;
      if (this.widget) {
        this.searchForm.setDefaultForWidget(this.widget, this.locService);
        this.searchForm.resetToDefault();
      }
      //if (this.filters)
      //  this.searchForm.patchValue(this.filters);
      this.locationSet()
    } else {
      if (isPlatformBrowser(this.platformId)) {
        if(localStorage.getItem("searchForm") && this.enableStorage){
          this.searchForm.patchValue(JSON.parse(localStorage.getItem("searchForm")!))
        }
      }
      this.locationSet()
    }
  }

  ngOnDestroy() {
    this.searchFormSubscriber.unsubscribe();
  }

  locationSet() {
    if (this.locationEnabled)
      this.searchForm.patchValue({
        withDistance: true
      })

    this.formListener();
    if (isPlatformBrowser(this.platformId))
      this.search();
    else
      this.search(this.initialTop);

    if (this.theme) {
      if (this.theme.id == 8) {
        this.showSmallFilter = true;
        this.themeService.getByName('digihelpers').subscribe(res => {
          this.themes = [res];
        });
      }
    } else {
      this.themeService.getAllThemes().subscribe(res => {
        this.themes = res;
      });
      //this.searchForm.patchValue({
      //  themes: [this.theme]
      //});
    }
    this.sectors$ = this.sectorService.getAllSectors();
    this.audiences$ = this.audienceService.getAllAudiences();
    this.talents$ = this.talentService.getAllTalents();




    this.sectors$.subscribe(res => {
      this.sectors = res;
    });
    this.audiences$.subscribe(res => {
      this.audiences = res;
    });
    this.talents$.subscribe(res => {
      this.talents = res;
    });

    if (this.value) {
      this.searchForm.setValue(this.value);
    }



    this.searchForm.get('onLocation')?.valueChanges.subscribe((value) => {
      if (!value) {
        this.searchForm.patchValue({
          withDistance: false
        });
      } else {
        this.searchForm.patchValue({
          withDistance: true
        });
      }
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  searchMore() {
    this.loadingMore = true;
    if (!this.initialLoad)
      this.skip += 100;
    else {
      this.initialLoad = false;
      this.skip = this.initialTop;
    }
    this.jobService.search(this.skip, this.top, this.searchForm.value).subscribe(res => {
      this.jobs$.push(...res.value);
      this.totalResults = res['@odata.count']
      this.totalPages = Math.ceil(this.totalResults / this.top);
      this.loadingMore = false;
    });
  }

  search(top: number = 100) {
    this.loading = true;
    if (top > this.initialTop)
      this.initialLoad = false;
    this.jobService.search(this.skip, top ? top : this.top, this.searchForm.value).subscribe(res => {
      this.jobs$ = res.value;
      this.totalResults = res['@odata.count']
      this.totalPages = Math.ceil(this.totalResults / this.top);
      this.loading = false;
    });
  }

  getLocation(): void {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        debugger;
        this.locationEnabled = true;
        if (this.searchForm) {

          this.searchForm.setDefaultLocation(position.coords.latitude, position.coords.longitude)
          this.locationSet();
        } else {
          this.searchForm = this.form.getFilterForm(position.coords.latitude, position.coords.longitude);
          this.locationSet();
        }
      }, () => {
        this.searchForm = this.form.getFilterForm();
        this.locationSet();
      });
    } else {
      //console.log("No support for geolocation")
    }
  }

  public selectJob(job: JobOffer): void {
    let modal = this.modalService.open(JobModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'pxp-user-modal' });
    modal.componentInstance.job = job;
  }

  getPlaceholder() {
    return this.locationEnabled ? 'Jouw locatie' : 'Overal';
  }

  toggleFilter() {
    this.filterShown = !this.filterShown;

    //if filter shown; add class 'filter-mobile-opened' to filter block else remove it
    if (this.filterShown) {
      this.filter.nativeElement.classList.add('filter-mobile-opened');
    } else {
      this.filter.nativeElement.classList.remove('filter-mobile-opened');
    }
  }

  openFilterModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'pxp-user-modal' });
  }

  getHeight() {
    if (isPlatformBrowser(this.platformId) && this.filterBlock) {
      if (window.innerWidth < 992) {
        return '90vh';
      } else {
        return this.filterBlock.nativeElement.offsetHeight + 'px';
      }
    }
    return '100%';
  }

  reloadComponent(self: boolean, urlToNavigateTo?: string) {
    //skipLocationChange:true means dont update the url to / when navigating
    const url = self ? this.router.url : urlToNavigateTo;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`]).then(() => {
      })
    })
  }

  resetFilters() {
    this.searchForm.resetToDefault();
    //this.formListener();
    ////this.reloadComponent(true);
    //this.resetSecondForm.emit();
  }
  formatLocation(loc: Location) {
    return (loc ? `${loc.name} (${loc.postCode})` : '');
  }



  selectCity(event: any) {
    this.searchForm.post.patchValue(event.item);
  }

  searchCity: OperatorFunction<string, readonly Location[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? [] : this.locService.getByPost(term)),
      map(res => {
        if (res.length == 1) {
          this.searchForm.post.patchValue(res[0]);
          return []
        } else {
          return res.map(loc => loc)
        }
      })
    );
  //  if(res.length == 1)
  //this.searchForm.post.patchValue(res[0]);
  formatter = (loc: Location) => this.formatLocation(loc);

}
