import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BasicDto } from "./basicDto";

export class FormModel<T extends Object> extends FormGroup {

  constructor(readonly model: T, controls: any) {
    super(controls);
  }

  disableValidation(ignoreFields: string[]) {
    for (var key in this.controls) {
      if (ignoreFields.indexOf(key) == -1) {
        this.get(key)?.clearValidators();
        this.get(key)?.updateValueAndValidity();
      }
    }
  }

  removeNullProperties(obj: any): void {
    for (const prop in obj) {
      if (obj[prop] === null) {
        delete obj[prop];
      }
    }
  }

  disableValidationForField(control: FormControl) {
    control.clearValidators();
    control.setErrors(null);
    control.updateValueAndValidity();
  }



  findInvalidFields(formGroup: FormGroup | FormArray = this): string[] {
    const invalidFields: string[] = [];

    Object.keys(formGroup.controls).forEach(fieldName => {
      const control = formGroup.get(fieldName);

      if (control instanceof FormControl) {
        if (control.invalid) {
          invalidFields.push(fieldName);
        }
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        const subInvalidFields = this.findInvalidFields(control);

        if (subInvalidFields.length > 0) {
          invalidFields.push(...subInvalidFields.map(subFieldName => `${fieldName}.${subFieldName}`));
        }
      }
    });
    return invalidFields;
  }


  setRequired(control: FormControl, isrequired: Boolean = true) {
    if (isrequired) {
      control.addValidators(Validators.required);
    } else {
      control.removeValidators(Validators.required);
    }
    //control.updateValueAndValidity()
    control.updateValueAndValidity({ emitEvent: false })
  }

  setRequiredTrue(control: FormControl, isrequired: Boolean = true) {
    if (isrequired) {
      control.addValidators(Validators.requiredTrue);
    } else {
      control.removeValidators(Validators.requiredTrue);
    }
    //control.updateValueAndValidity()
    control.updateValueAndValidity({ emitEvent: false })
  }

  mapToModel(): T {
    Object.assign(this.model, { ...this.getRawValue() } as T);
    return this.model;
  }

}

declare module '@angular/forms' {
  interface FormControl {
    resetValidators(): void;
  }
}


export class BasicDtoForm extends FormModel<BasicDto<any>> {

  constructor(override readonly model: BasicDto<any>, readonly fb: FormBuilder = new FormBuilder()) {
    super(model, fb.group({
      Id: [model.Id],
      Name: [model.Name],
    }).controls);
  }

  readonly Id = this.get("Id") as FormControl;
  readonly Name = this.get("Name") as FormControl;
}
FormControl.prototype.resetValidators = function () {
  this.clearValidators();
  this.setErrors(null);
  this.updateValueAndValidity({ emitEvent: false });
};

export type IForm<T> = {
  [K in keyof T]?: any;
}
