import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../../services/mail-message.service';
import { RequestAccessForm } from './form-models/requestAccessForm';
import { RequestAccess } from './models/requestAccess';

@Component({
  templateUrl: './assist.component.html',
  styleUrls: ['./assist.component.scss']
})
export class AssistComponent implements OnInit {
  form: RequestAccessForm = new RequestAccessForm({} as RequestAccess);
  isLoading: boolean = false;
  success: boolean = false;
  failed: boolean = false;

  siteKey = "6LdU4iYjAAAAAGX-0hdVqxXdWB4W_8tOXgO0xWRe"
  handleSuccess($event: string) {
    alert($event)
    //throw new Error('Method not implemented.');
  }
  valueTrue = true;
  valueFalse = false;

  constructor(private messageService: MessageService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    debugger;
    if (this.form.valid) {
      this.isLoading = true;
      var model = this.form.mapToModel();
      this.messageService.requestAccess(model).subscribe(x => {
        this.isLoading = false;
        if (x)
          this.success = true;
        else
          this.failed = true
      })
    }
  }

}
