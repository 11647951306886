import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'hrefCheck'})
export class HrefCheckPipe implements PipeTransform {
  transform(value: string) : String {
    if (value.includes('http')) {
      return value;
    } else {
      return 'https://' + value;
    }
  }
}