import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  OperatorFunction,
  switchMap,
  map,
  Subscription,
} from 'rxjs';
import { JobOffer } from 'src/models/jobOffer';
import { JobSector } from 'src/models/jobSector';
import { JobService } from 'src/services/job.service';
import { LocationService } from 'src/services/location.service';
import { JobSectorService } from 'src/services/sector.service';
import { Location } from 'src/models/location';
import { JobModalComponent } from '../job-modal/job-modal.component';
import { JobListComponent } from '../job-list/job-list.component';
import { isPlatformBrowser } from '@angular/common';
import { FilterFormService, SearchForm } from 'src/services/filter-form.service';
import { JobAudience } from 'src/models/jobAudience';
import { JobAudienceService } from 'src/services/audience.service';
import {
  CollaborationTypeObject,
  EnumObject,
  FrequentionTypeEnum,
  FrequentionTypeEnumObject,
} from 'src/models/enums/jobOfferEnums';
import { TimeSpans } from 'src/models/timeSpans';
import { JobTalentService } from 'src/services/talent.service';
import { JobTalent } from 'src/models/jobTalent';
import { SearchComponent } from '../search/search.component';
import { Router } from '@angular/router';
import { debug } from 'memory-cache';

@Component({
  selector: 'app-search-jobs',
  templateUrl: './search-jobs.component.html',
  styleUrls: ['./search-jobs.component.scss'],
})
export class SearchJobsComponent implements OnInit {
  public sectors$!: JobSector[];
  public searchForm!: SearchForm;

  @ViewChild(SearchComponent) searchComponent!: SearchComponent;
  public totalJobs$!: Observable<string>;
  searchFormSubscriber!: Subscription;
  locationEnabled: boolean = false;
  timeoutId: ReturnType<typeof setTimeout> | null = null;


  constructor(
    private jobService: JobService,
    private sectorService: JobSectorService,
    private form: FilterFormService,
    private locService: LocationService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private audienceService: JobAudienceService,
    private talentService: JobTalentService,
    private router: Router
  ) {
    //this.sectorService.getAllSectors().subscribe;
  }

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  ngOnInit(): void {
    this.totalJobs$ = this.jobService.getTotal();
    if (!this.searchForm) {

      if (isPlatformBrowser(this.platformId))
        this.getLocation();


      //timeout zetten voor het geval de gebruiker niet op de popup van de locatie klikt
      //na 1000ms geen reactie worden de vacatures op de standaard locatie geladen.
      this.timeoutId = setTimeout(() => {
        if (!this.searchForm)
          this.searchForm = this.form.getFilterForm();
        this.locationSet()
      }, 1000)
    } else {
      this.locationSet();
    }


  }

  ngOnDestroy() {
    this.searchFormSubscriber.unsubscribe();
  }

  locationSet() {
    if (this.locationEnabled) {
      this.searchForm.withDistance.patchValue(true);
    } else {
      this.searchForm.withDistance.patchValue(false);
    }
    if (this.timeoutId)
      clearTimeout(this.timeoutId); // timeout clearen, als er toestemming is gegeven worden de vacatures vlakbij geladen, anders van de standaard locatie.
    //  this.searchForm = this.form.getFilterForm();
    this.searchFormSubscriber = this.searchForm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((e) => {
        localStorage.setItem("searchForm", JSON.stringify(e));
        //this.searchComponent.searchForm.patchValue({
        //  name: e.name,
        //  post: e.post,
        //  sectors: e.sectors,
        //}, { emitEvent: false });
      });

    this.searchForm.post.valueChanges.subscribe(x => {
      if (x) {
        this.searchForm.withDistance.patchValue(true);
      } else {
        this.searchForm.withDistance.patchValue(false);
      }
    })
  }

  getLocation(): void {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        debugger;
        this.locationEnabled = true;
        if (this.searchForm) {
          this.searchForm.setDefaultLocation(position.coords.latitude, position.coords.longitude)
          this.locationSet();
        } else {
          this.searchForm = this.form.getFilterForm(position.coords.latitude, position.coords.longitude);
          this.locationSet();
        }
      }, () => {
        this.searchForm = this.form.getFilterForm();
        this.locationSet();
      });
    } else {
      //console.log("No support for geolocation")
    }
  }


  ngAfterViewInit() {
  }

  getPlaceholder() {
    return this.locationEnabled ? 'Jouw locatie' : 'Overal';
  }

  resetForm() {
    this.searchForm.reset({
      name: '',
      post: '',
      sectors: '',
    });
  }

  updateForm(form: FormGroup) {
    // this.searchForm.patchValue(form.value, {
    //   emitEvent: false,
    //   onlySelf: true,
    // });
  }



  formatLocation(loc: Location) {
    return (loc ? `${loc.name} (${loc.postCode})` : '');
  }
  selectCity(event: any) {
    this.searchForm.post.patchValue(event.item);

  }

  searchCity: OperatorFunction<string, readonly Location[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) =>
        term.length < 2 ? [] : this.locService.getByPost(term)
      ),
      map(res => {
        if (res.length == 1) {
          this.searchForm.post.patchValue(res[0]);
          return []
        } else {
          return res.map(loc => loc)
        }
      })
    );

  formatter = (loc: Location) => this.formatLocation(loc);
}
