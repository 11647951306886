<app-navbar></app-navbar>

<section class="mt-100 mb-100">
    <div class="pxp-container text-center pt-4">
        <h2 class="pxp-section-h2">Privacyverklaring</h2>
    </div>
    <div class="pxp-container text-start" style="margin-bottom: 100px;">
        <br /><br />
        <p>
            Vlaams Steunpunt Vrijwilligerswerk vzw hecht veel belang aan de bescherming van persoonsgegevens.
        </p>
        <p>
            In deze privacyverklaring geven we daarom heldere informatie over hoe wij omgaan met persoonsgegevens. Wij houden ons maximaal aan de geldende privacyregelgeving, waaronder de Algemene Verordening Gegevensbescherming (Verordening EU 2016/679) en de Wet
            van 30 juli 2018 tot bescherming van natuurlijke personen met betrekking tot de verwerking van persoonsgegevens.
        </p>
        <p class="mb-1">
            Concreet betekent dit dat we:
        </p>
        <ul>
            <li>
                de vraag naar persoonsgegevens tot een minimum beperken;
            </li>
            <li>
                persoonsgegevens uitsluitend verwerken in overeenstemming met het doel waarvoor de gebruiker ze verstrekt, wat in ons geval het samenbrengen is van vrijwilligers en organisaties;
            </li>
            <li>
                hiervoor samenwerken met een externe leverancier voor de ontwikkeling, beheer en onderhoud van de website, de onderliggende databank en de verbinding met de toepassing Assist;
            </li>
            <li>
                technische en organisatorische maatregelen hebben genomen om de beveiliging van persoonsgegevens te waarborgen;
            </li>
            <li>
                onder geen beding persoonsgegevens doorgeven voor commerciële doeleinden;
            </li>
            <li>
                geen persoonsgegevens opslaan op een server buiten de Europese Economische Ruimte.
            </li>
        </ul>

        <br /><br />

        <h3>Verwerkingsverantwoordelijke</h3>
        <p>
            Als vzw Vlaams Steunpunt Vrijwilligerswerk zijn wij verantwoordelijk voor de verwerking van uw persoonsgegevens. Indien u na het doornemen van onze privacyverklaring vragen heeft hierover of contact met ons wenst op te nemen, kan dit via onderstaande
            contactgegevens:
        </p>
        <p class="ms-5">
            Vlaams Steunpunt Vrijwilligerswerk vzw<br /> Brueghelstraat 31-33<br /> 2018 Antwerpen<br /> Ondernemingsnummer: 417.430.095<br />
            <a href="mailto:info@vsvw.be">info@vsvw.be</a>
        </p>

        <br /><br />

        <h3>Aan wie is deze privacyverklaring gericht?</h3>
        <p>
            Deze privacyverklaring heeft betrekking op de diensten die door ons worden verstrekt via de website <a href="https://www.kanikhelpen.be" target="_blank">www.kanikhelpen.be</a> en het gekoppelde gratis <a href="https://www.assistonline.eu"
                target="_blank">Assist</a> abonnement.
        </p>
        <p class="mb-1">
            Deze privacyverklaring is van toepassing op de verwerking van gegevens van de volgende categorieën van personen :
        </p>
        <ul>
            <li>
                Bezoekers en gebruikers van de website <a href="https://www.kanikhelpen.be" target="_blank">www.kanikhelpen.be</a>
            </li>
            <li>
                Kandidaat-vrijwilligers
            </li>
        </ul>

        <br /><br />

        <h3>Hoe verzamelen wij persoonsgegevens?</h3>
        <p>
            Er zijn een aantal situaties waarin wij uw persoonsgegevens verzamelen en vervolgens gebruiken:
        </p>
        <p>
            <u>Gegevens die u ons zelf rechtstreeks verstrekt</u>
        </p>
        <p class="ms-5 mb-1">
            We verzamelen persoonsgegevens die u zelf op verschillende wijzen aan ons meedeelt, o.a.
        </p>
        <ul class="ms-5">
            <li>
                Bezoek aan onze website;
            </li>
            <li>
                Invullen van uw e-mailadres op een pagina van een lokaal bestuur;
            </li>
            <li>
                Invullen van het contactformulier voor een oproep op de website;
            </li>
            <li>
                Wanneer u ons contacteert via e-mail, sociale media, telefoon of per brief.
            </li>
        </ul>
        <p>
            <u>Gegevens die we onrechtstreeks ontvangen van de organisaties</u>
        </p>
        <p class="ms-5">
            We verzamelen persoonsgegevens die het lokaal bestuur bijhoudt in de toepassing Assist, en die de organisatie of lokaal bestuur ons meedeelt via de interface tussen Assist en onze Website.
        </p>
        <p>
            <u>Gegevens die we automatisch verzamelen</u>
        </p>
        <p class="ms-5">
            We verzamelen automatisch gegevens door middel van cookies bij uw gebruik, toegang of interactie met onze websites. Onze website gebruikt zowel functionele (technisch noodzakelijke) cookies als niet-functionele cookies. Bij uw eerste bezoek aan onze website
            wordt u gevraagd om onze verschillende soorten cookies te aanvaarden.
        </p>
        <p class="ms-5">
            Voor meer informatie over ons gebruik van cookies verwijzen we naar de cookieverklaring.
        </p>

        <br />

        <h3>Welke persoonsgegevens verzamelen wij over u en waarom?</h3>
        <p class="mb-1">
            Wanneer <strong>u onze website bezoekt</strong> verzamelen wij:
        </p>
        <ul>
            <li>
                technische informatie over de verbindingsmiddelen (het IP-adres, het besturingssysteem, de gebruikte internetproviders en andere soortgelijke informatie)
            </li>
            <li>
                gegevens betreffende de gedragingen en gewoontes van de bezoekers bij het gebruik van de website (o.a. surfgedrag), door middel van niet-noodzakelijke cookies (een klein tekstbestand dat wordt opgeslagen op uw toestel).
            </li>
            <li>
                de gegevens die u meedeelt op webformulieren.
            </li>
        </ul>
        <p class="mb-1">
            Deze informatie gebruiken we om:
        </p>
        <ul>
            <li>
                De website correct te laten functioneren op uw computer en te beschermen tegen onrechtmatig gebruik (gerechtvaardigd belang van het verzekeren van de goede werking van onze organisatie).
            </li>
            <li>
                Uw website-ervaring te verbeteren (wij vragen uw voorafgaandelijke toestemming voor het plaatsen van niet-noodzakelijke cookies)
            </li>
        </ul>
        <p>
            Aangezien wij voor het verwerken van bovenstaande gegevens vertrouwen op het gebruik van cookies, verwijzen wij u voor meer uitleg hierover naar onze cookieverklaring.
        </p>

        <br />

        <p class="mb-1">
            Van <strong>lokale besturen die een vacature voor een vrijwilliger melden</strong> verzamelen we:
        </p>
        <ul>
            <li>
                identificatiegegevens lokaal bestuur: naam van het lokaal bestuur
            </li>
            <li>
                locatie van het aangeboden vrijwilligerswerk: benaming locatie, adres, postcode, gemeente.
            </li>
            <li>
                Omschrijving van de gevraagde activiteit (oproep)
            </li>
        </ul>
        <p class="mb-1">
            Wij verzamelen deze persoonsgegevens om:
        </p>
        <ul>
            <li>
                Door te geven aan de kandidaat vrijwilliger (overeenkomst – aanvaarding algemene voorwaarden gebruik website)
            </li>
            <li>
                U te contacteren ingeval van nood aan bijkomende informatie (gerechtvaardigd belang)
            </li>
        </ul>

        <br />

        <p class="mb-1">
            Wanneer u zich <strong>aanmeldt als kandidaat-vrijwilliger</strong> dan verzamelen wij:
        </p>
        <ul>
            <li>
                rechtstreeks van u, de identiteitsgegevens die u opgeeft op de website (globale pagina van het lokale bestuur) of op het contactformulier voor de oproep, zoals naam, voornaam, woonplaats (postcode), telefoon, e-mailadres, en uw antwoord op de mogelijke
                voorwaarden van de oproep.
            </li>
        </ul>
        <p class="mb-1">
            Deze informatie gebruiken wij om:
        </p>
        <ul>
            <li>
                U te laten contacteren door het aanvragende lokaal bestuur (inrichters van de vrijwilligersactiviteit) (overeenkomst - algemene voorwaarden gebruik website)
            </li>
            <li>
                U in de toekomst te informeren over gelijkaardige oproepen van het lokaal bestuur van de gekozen locatie (overeenkomst – algemene gebruiksvoorwaarden website)
            </li>
            <li>
                Een bevraging te organiseren of (wetenschappelijk) onderzoek uit te voeren (gerechtvaardigd belang)
            </li>
        </ul>

        <br />

        <p class="mb-1">
            Wanneer u <strong>met ons communiceert</strong>, via telefoon, e-mail, sociale media, gesprek of eender welk ander kanaal u verkiest, verzamelen wij:
        </p>
        <ul>
            <li>
                uiteraard de inhoud van uw bericht maar ook de technische details ervan, zoals datum en tijd en aan wie het gericht is.
            </li>
            <li>
                de identiteitsgegevens die u ons bezorgt, zoals uw naam, (e-mail)adres, telefoonnummer, functie,...
            </li>
            <li>
                alle andere informatie die u ons meedeelt.
            </li>
        </ul>
        <p class="mb-1">
            Deze informatie gebruiken wij om:
        </p>
        <ul>
            <li>
                met u te kunnen communiceren. Wij beroepen ons op ons gerechtvaardigd belang om op uw vragen of opmerkingen te kunnen antwoorden.
            </li>
        </ul>

        <br />

        <p class="mb-1">
            Ten slotte worden alle persoonsgegevens die wij verzameld hebben ook nog voor <strong>volgende doeleinden</strong> verwerkt:
        </p>
        <ul>
            <li>
                Wij gebruiken uw gegevens ook om het gebruik en de algemene werking van onze diensten te analyseren en verbeteren en voor statistische doeleinden (gerechtvaardigd belang).
            </li>
            <li>
                Beheer van geschillen en gerechtelijke procedures, de invordering of overdracht van schuldvordering en de bescherming van onze rechten in het algemeen (gerechtvaardigd belang);
            </li>
            <li>
                Bestrijden van eventuele fraude en misbruik (gerechtvaardigd belang).
            </li>
            <li>
                Om een wettelijke verplichting na te komen of om in te gaan op een verzoek van bevoegde autoriteiten zoals politiediensten, gerechtelijke instanties, de gegevensbeschermings-autoriteit, enz.
            </li>
        </ul>
        <p>
            Wanneer de verwerking van persoonsgegevens plaatsvindt op basis van toestemming heeft u steeds het recht om de gegeven toestemming in te trekken. U kan ons hiervoor contacteren via de contactgegevens bovenaan deze verklaring of uitschrijven via Vlaams
            Steunpunt Vrijwilligerswerk die voorzien is in bepaalde van onze e-mails.
        </p>
        <p>
            Waar wij ons op de juridische verwerkingsgrond van onze gerechtvaardigde belangen baseren, heeft u steeds het recht om bezwaar te maken tegen de verwerking van uw gegevens (zoals direct marketing).
        </p>

        <br />

        <h3>
            Met wie delen wij uw persoonsgegevens?
        </h3>
        <p>
            <u>Met derden - verwerkers</u>
        </p>
        <p>
            De gegevens die u aan ons geeft kunnen wij verstrekken aan externe dienstverleners waarop wij beroep doen voor de uitvoering van de hierboven beschreven doeleinden.
        </p>
        <p class="mb-1">
            Zo maken wij gebruik van externe dienstverleners voor:
        </p>
        <ul>
            <li>
                Het verzorgen van de internet omgeving en het beheer van onze website;
            </li>
            <li>
                Het verwerken van gegevens van de lokale besturen en de vacatures die ze aanbieden (toepassing Assist)
            </li>
            <li>
                Formulieren op de website
            </li>
            <li>
                Onderzoek naar motivatie en inzet, en bereik van oproepen.
            </li>
        </ul>
        <p>
            Deze externe dienstverleners krijgen enkel toegang tot bepaalde gegevens en informatie in het kader van hun opdracht. Zij treden met andere woorden enkel op in onze opdracht en voor onze rekening, en mogen uw gegevens dus nooit voor hun eigen doeleinden
            gebruiken. Hiervoor hebben wij een zogenaamde "verwerkersovereenkomst" afgesloten met deze partijen.
        </p>
        <p>
            Wij doen geen beroep op verwerkers of sub-verwerkers die buiten de Europese Economische Ruimte zijn gevestigd.
        </p>

        <br />

        <p>
            <u>Aan derden - ontvangers</u>
        </p>
        <p class="mb-1">
            Wij delen persoonsgegevens met de volgende externe partijen:
        </p>
        <ul>
            <li>
                Lokale besturen : Wanneer wij uw persoonsgegevens verzamelen (via het interesseformulier) dan geven wij uw persoonsgegevens (o.a. naam, e-mailadres) door aan het lokaal bestuur die een vacature voor een vrijwilliger heeft om u te kunnen contacteren voor
                een onderhoud.
            </li>
            <li>
                Overheid en autoriteiten: Wij geven uw gegevens door aan de overheid en autoriteiten als dat wettelijk verplicht en toegestaan is.
            </li>
        </ul>
        <p>
            Met deze partijen maken wij uiteraard de nodige afspraken om de beveiliging en vertrouwelijkheid van uw persoonsgegevens te waarborgen en te zorgen dat deze enkel gebruikt worden voor het doeleinde waarvoor ze werden verzameld, en enkel zolang als nodig
            is binnen de samenwerking.
        </p>
        <p>
            Vlaams Steunpunt Vrijwilligerswerk verkoopt geen persoonlijke gegevens aan externe organisaties, noch zullen wij uw persoonlijke gegevens delen met andere organisaties voor hun eigen marketingcommunicatie.
        </p>

        <br />

        <h3>Hoe lang bewaren wij uw persoonsgegevens?</h3>
        <p>
            Vlaams Steunpunt Vrijwilligerswerk vzw bewaart uw persoonsgegevens niet langer dan noodzakelijk voor het realiseren van het doel waarvoor deze zijn verwerkt.
        </p>
        <p>
            Vlaams Steunpunt Vrijwilligerswerk vzw verbindt zich ertoe de gegevens niet langer bij te houden dan maximaal 2 jaar na laatste contact/gebruik (of de beëindiging van de relatie of samenwerking), behoudens voor wat betreft de persoonsgegevens die wij
            op basis van specifieke wetgeving langer moeten bewaren of in geval van een langere contractuele aansprakelijkheid of in geval van een lopend geschil waarvoor de persoonsgegevens nog noodzakelijk zijn.
        </p>
        <p>
            Sommige informatie kan voor onbepaalde tijd worden bewaard voor historische, statistische of onderzoeksdoeleinden.
        </p>
        <p>
            Zoals hieronder vermeld heeft u het recht om ons te vragen uw persoonlijke gegevens uit onze databases te verwijderen. Wanneer de verwerking op uw toestemming gebaseerd is, dan houden wij uw persoonsgegevens bij totdat u uw toestemming intrekt.
        </p>

        <br />

        <h3>Hoe beschermen wij uw persoonsgegevens?</h3>
        <p class="mb-1">
            Wij nemen passende technische en organisatorische maatregelen om uw persoonsgegevens te beschermen tegen onrechtmatige verwerking of gebruik, waaronder:
        </p>
        <ul>
            <li>
                Alle personen die namens Vlaams Steunpunt Vrijwilligerswerk vzw van uw gegevens kennis kunnen nemen, zijn gehouden aan een confidentialiteitsplicht.
            </li>
            <li>
                Onze medewerkers zijn zich bewust en geïnformeerd over het belang van de bescherming van persoonsgegevens.
            </li>
            <li>
                We hanteren een gebruikersnaam en wachtwoord op al onze systemen;
            </li>
            <li>
                Wij maken back-ups van de persoonsgegevens om deze te kunnen herstellen bij fysieke of technische incidenten;
            </li>
        </ul>

        <br />

        <h3>Wat zijn uw rechten en hoe kan u die oefenen?</h3>
        <p>
            Aangezien wij uw persoonsgegevens verzamelen en verwerken, beschikt u over een aantal rechten. Via het adres bovenaan deze privacyverklaring kan u ons contacteren om uw rechten uit te oefenen, vergezeld van een motivatie voor uw vraag. Wanneer we redelijke
            twijfels hebben omtrent uw identiteit, kunnen we u om bijkomend bewijs van uw identiteit vragen.
        </p>
        <p>
            U heeft recht op <strong>inzage en een kopie</strong> van de persoonsgegevens die op u betrekking hebben. Op die manier kan u nagaan over welke gegevens wij van u beschikken.
        </p>
        <p>
            U heeft recht op <strong>verbetering en aanvulling</strong> wanneer uw gegevens niet juist, niet meer juist of onvolledig zijn.
        </p>
        <p>
            U heeft recht op <strong>gegevenswissing (verwijdering)</strong> van de persoonsgegevens die wij van u ontvangen hebben en die niet langer strikt noodzakelijk zijn voor de doeleinden waarvoor ze werden verwerkt. Wij zijn echter niet altijd
            verplicht uw gegevens te wissen wanneer u dat vraagt. We hoeven dat enkel te doen in de gevallen die door de wet zijn bepaald.
        </p>
        <p>
            U heeft recht op <strong>beperking van gegevensverwerking</strong>. Indien u bezwaar heeft tegen de verwerking van bepaalde gegevens kan u vragen om deze verwerking te stoppen. In dat geval blijven we uw gegevens bewaren, maar zullen we het
            gebruik ervan inperken. Op die manier wordt de verwerking tijdelijk stopgezet tot er bijvoorbeeld zekerheid is over de juistheid ervan. We hoeven dat enkel te doen in de gevallen die door de wet zijn bepaald.
        </p>
        <p>
            U heeft het recht om <strong>bezwaar</strong> te maken tegen de verwerking van uw gegevens die is gebaseerd op onze gerechtvaardigde belangen. Dit dient te gebeuren op basis van specifieke met uw situatie verband houdende redenen. In dit geval
            dienen wij de verwerking stop te zetten, tenzij wij dwingende gerechtvaardigde gronden aanvoeren om de verwerking voort te zetten. U kan, ongeacht de rechtsgrond waarop wij ons baseren, echter steeds, zonder opgave van reden, bezwaar maken
            tegen het gebruik van uw gegevens voor direct marketing doeleinden (vb. informatieve massa mailings, toesturen van nieuwsbrieven), waarna wij verplicht zijn de verwerking voor deze doeleinden stop te zetten. U krijgt bij elke informatieve
            massa mail de mogelijkheid om u uit te schrijven.
        </p>
        <p>
            U heeft het recht op <strong>gegevensoverdraagbaarheid</strong> om van ons een kopie van persoonsgegevens te krijgen, in een gestructureerde, gangbare en machine-leesbaar formaat. Dit heeft alleen betrekking op de persoonsgegevens die u aan
            ons heeft verstrekt.
        </p>
        <p>
            Voor de gevallen waar wij uw toestemming hebben gevraagd om uw persoonsgegevens te verzamelen en te verwerken, heeft u steeds het <strong>recht om uw toestemming in te trekken</strong>.
        </p>

        <br />

        <h3>Waar kan u terecht met klachten?</h3>
        <p>
            Wij stellen alles in het werk om op een zorgvuldige, legitieme manier en in overeenstemming met de toepasselijke regelgeving met uw persoonsgegevens om te gaan.
        </p>
        <p>
            Mocht u een klacht hebben over de verwerking van uw persoonsgegevens of over de uitoefening van uw rechten dan vragen wij u hierover direct contact met ons op te nemen via het adres bovenaan deze privacyverklaring.
        </p>
        <p>
            Indien u van oordeel bent dat uw rechten geschonden zijn en u binnen onze organisatie geen gehoor vindt voor uw bezorgdheden staat het u altijd vrij een klacht in te dienen bij de toezichthoudende autoriteit op het gebied van privacy bescherming :
        </p>
        <p class="ms-5">
            Gegevensbeschermingsautoriteit (GBA)<br /> Drukpersstraat 35<br /> 1000 Brussel<br />
            <a href="https://www.gegevensbeschermingsautoriteit.be/" target="_blank">www.gegevensbeschermingsautoriteit.be</a><br />
            <a href="mailto:contact@apd-gba.be">contact@apd-gba.be</a>
        </p>

        <br />

        <h3>Wanneer werd deze privacyverklaring laatst gewijzigd?</h3>
        <p>
            Wij behouden het recht voor om deze verklaring van tijd tot tijd aan te passen. Indien de aanpassingen substantieel zijn, zullen wij u hierop attent maken via onze website of via een bericht per e-mail.
        </p>
        <p>
            Huidige versie: 16 oktober 2023.
        </p>
    </div>
</section>