import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobOffer } from 'src/models/jobOffer';
import { FavoriteService } from 'src/services/favorite.service';
import { JobService } from 'src/services/job.service';
import { ContactModalComponent } from '../../contact-modal/contact-modal.component';
import { VolunteerModalComponent } from '../../volunteers/volunteer-modal/volunteer-modal.component';
import { RegistrationComponent } from './registration/registration.component';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss'],
})
export class JobDetailComponent implements OnInit {
  url!: string;
  sectors!: string;
  themes!: string;
  public job!: JobOffer;
  public volunteers!: JobOffer[];
  public similarJobs!: JobOffer[];
  public openModal = false;
  @ViewChild(RegistrationComponent) registrationComponent!: RegistrationComponent;


  constructor(private title: Title, private meta: Meta, private router: Router, private route: ActivatedRoute, @Inject('IMAGE_SERVER_URL') public imageUrl: string, private jobService: JobService, public favoriteService: FavoriteService, @Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal) {
    this.route.data.subscribe(({ job }) => {
      if (!job)
        this.router.navigate([``]);
      else {
        this.job = job;
        this.title.setTitle(`Vrijwilligerswerk - ${this.job.name}`);

        //facebook metatags
        this.meta.updateTag({ property: 'og:title', content: this.job.name });
        //this.meta.updateTag({ property: 'og:description', content: this.job.description });
        //this.meta.updateTag({ property: 'og:image', content: this.job.organization. });
        //this.meta.updateTag({ property: 'og:url', content: this.router.url });
        //this.meta.updateTag({ property: 'og:type', content: 'website' });
        this.meta.updateTag({ property: 'og:site_name', content: 'Vrijwilligerswerk' });

        this.openModal = this.router.getCurrentNavigation()?.extras.state?.['openModal'];
      }
    })
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) this.url = window.location.href;
    this.jobService.getVolunteers(4).subscribe(res => {
      this.volunteers = res.value;
    });

    this.jobService.getSimilarJobs(this.job, 3, true).subscribe(res => {
      this.similarJobs = res.value;
      if (this.similarJobs.length == 0) {
        this.jobService.getSimilarJobs(this.job, 3, false).subscribe(res => {
          this.similarJobs = res.value;
        });
      }
    });

    if(this.job.sectors.length > 0) {
      if(this.job.sectors.length == 1) {
        this.sectors = this.job.sectors[0].sector.name
      } else {
        this.sectors = this.job.sectors.map(s => s.sector.name).join(', ')
      }
    }

    if(this.job.themes.length > 0) {
      if(this.job.themes.length == 1) {
        this.themes = this.job.themes[0].theme.name
      } else {
        this.themes = this.job.themes.map(t => t.theme.name).join(', ')
      }
    }
  }

  isFavorite() {
    return isPlatformBrowser(this.platformId) ? this.favoriteService.isFavorite(this.job.id) : false;
  }

  ngAfterViewInit() {
    if (this.openModal) this.openRegistrationModal()
  }

  openRegistrationModal() {
    this.registrationComponent.open(this.job);
  }

  getUrl(url: string) {
    if (url && url.indexOf('http') > -1) {
      return url;
    }
    else
      return this.imageUrl + url
  }

  contactForm() {
    let modal = this.modalService.open(ContactModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, windowClass: 'pxp-user-modal' });
    modal.componentInstance.jobId = this.job.id;
    modal.componentInstance.organization = this.job.organization;
  }

  public selectVolunteer(job: JobOffer): void {
    let modal = this.modalService.open(VolunteerModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'pxp-user-modal' });
    modal.componentInstance.job = job;
  }
}
