import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { FormModel } from "../../../../models/form-model";
import { RequestAccess } from "../models/requestAccess";

export class RequestAccessForm extends FormModel<RequestAccess> {
  constructor(override readonly model: RequestAccess = {} as RequestAccess, readonly fb: FormBuilder = new FormBuilder()) {
    super(model, fb.group({
      useType: [model.useType, Validators.required],
      urgent: [null, Validators.required],
      postcode: [model.postcode, Validators.required],
      postcodeName: [model.postcodeName, Validators.required],
      firstName: [model.firstName, Validators.required],
      lastName: [model.lastName, Validators.required],
      function: [model.function, Validators.required],
      emailadres: [model.emailadres, [Validators.required, Validators.email]],
      telephone: [model.telephone, Validators.required],
      terms: [model.terms, Validators.requiredTrue],
      recaptcha: [model.recaptcha, Validators.required],
    }).controls);
  }

  readonly useType = this.get("useType") as FormControl;
  readonly urgent = this.get("urgent") as FormControl;
  readonly postcode = this.get("postcode") as FormControl;
  readonly postcodeName = this.get("postcodeName") as FormControl;
  readonly firstName = this.get("firstName") as FormControl;
  readonly lastName = this.get("lastName") as FormControl;
  readonly function = this.get("function") as FormControl;
  readonly emailadres = this.get("emailadres") as FormControl;
  readonly telephone = this.get("telephone") as FormControl;
  readonly terms = this.get("terms") as FormControl;
  readonly recaptcha = this.get("recaptcha") as FormControl;

  override mapToModel() {
    var model = super.mapToModel();
    model.postcode = model.postcode.toString();
    return model
  }
}
