export interface WidgetData {
  filters: Filters;
  display: Display;
  style: Style;
  showFilter: boolean;
}

export interface Filters {
  searchTerm: string | null;
  locationType: string;
  locations: number[];
  aroundRadius: number;
  organizations: string[];
  organizationModels: WidgetOrganization[];
  targetAudiences: number[];
  talents: number[];
  themes: number[];
  sectors: number[];
  tasks: number[];
  frequency: number;
  period: {
    from: string | null;
    to: string | null;
  };
  languages: number[];
  accessibility: boolean;
  no_dutch: boolean;
  practice_dutch: boolean;
}

export interface Display {
  title: string;
  worksOnDomains: string[];
  domNodeId: string;
  maxResults: string;
  filters: EnabledFilters;
}

export class EnabledFilters {

  constructor() {
    this.searchTerm = true;
    this.talents = true;
    this.location = true;
    this.targetAudiences = true;
    this.sectors = true;
    this.tasks = true;
    this.organizations = true;
    this.frequency = true;
    this.period = true;
    this.languages = true;
    this.accessibility = true;
    this.no_dutch = true;
    this.practice_dutch = true;
    this.timespan = true;
    this.collab = true;
    this.themes = true;
  }

  searchTerm: boolean;
  talents: boolean | true;
  location: boolean;
  targetAudiences: boolean;
  sectors: boolean;
  tasks: boolean;
  organizations: boolean;
  frequency: boolean;
  period: boolean;
  languages: boolean;
  accessibility: boolean;
  no_dutch: boolean;
  practice_dutch: boolean;
  timespan: boolean;
  collab: boolean;
  themes: boolean;
}

export interface Color {
  color: string;
  alpha: number;
}

export interface Style {
  withCss: boolean;
  maxHeight: string | null;
  colors: {
    background: Color;
    overlay: Color;
    link: Color;
    tagBackground: Color;
    tagText: Color;
    text: Color;
    title: Color;
  };
}

export interface WidgetOrganization {
  id: number;
  name: string;
}


