<form class="row gx-3 align-items-center" style="height: 58px;">
  <div class="col-12 col-lg px-0">
    <ng-template #rt let-r="result" let-t="term">
      <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
    </ng-template>

    <input type="text" [class]="inputClass" [formControl]="control"
           [value]="formatLocation(control.value)"
           [ngbTypeahead]="searchCity"
           [inputFormatter]="formatter"
           [resultTemplate]="rt"
           [resultFormatter]="formatter" [editable]='false' (selectItem)="selectCity($event)" (keyup.enter)="goToPostcode()"
           placeholder="Postcode of gemeente..." />
  </div>
  <div class="col-12 col-lg-auto mt-3 mt-lg-0">
    <!--
        <button *ngIf="false && showButton" (click)="goToPostcode()" [disabled]="!this.control.value" [class]="buttonClass">{{buttonName}}</button>
    -->
    <a *ngIf="showButton" (click)="goToPostcode()" [class]="buttonClass">{{buttonName}}</a>
  </div>
</form>
