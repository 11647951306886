import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Address } from 'src/models/address';
import { MapService } from 'src/services/map.service';
import { observeFields } from '../../../open-map/util';

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss']
})
export class LocationMapComponent implements OnInit {
  @Input('location') location!: Address
  @ViewChild('leafletMap') leafletMap!: ElementRef;
  fields = observeFields(this);
  map!: L.Map;
  
  constructor(private mapService: MapService) { }

  ngOnInit(): void {
    if(this.mapService.L) {
      this.fields.leafletMap.subscribe(() => this.setupMap());
    }
  }

  private setupMap() {
    if (!this.map && this.leafletMap) {
      this.map = this.mapService.L.map(this.leafletMap.nativeElement).setView([50.85045, 4.34878], 12);
      this.mapService.L.tileLayer(
        'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      ).addTo(this.map);
      (document.querySelector('.leaflet-control-attribution') as HTMLElement).style.display = 'none';
      this.addMarker();
    }
  }

  private addMarker() {
    this.fields.location.subscribe((val) => {
      if(this.map && val.latitude && val.longitude) this.map.panTo(this.mapService.L.latLng(val.latitude, val.longitude));

      if(this.map && val.latitude && val.longitude) this.map.addLayer(this.mapService.L.marker([val.latitude, val.longitude], {
        icon: this.mapService.L.icon({
          iconSize: [ 48, 48],
          iconUrl: 'assets/images/leaflet/location-pin.png',
        })
      }));
    })
  }

}
