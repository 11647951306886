import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { post } from 'jquery';
import { debug } from 'util';
import { JobPostcode } from '../../../../../models/jobPostcode';
import { NotificationService } from '../../../../../services/notification.service';
import { NotificationForm, NotificationJobPostcodeForm } from '../../form-models/notification-form-model';
import { NotificationDto, NotificationJobPostcodeDto } from '../../models/notification';
import { NotificationEditModalComponent } from '../../pages/notification-edit-modal/notification-edit-modal.component';

@Component({
  selector: 'app-notification-add',
  templateUrl: './notification-add.component.html',
  styleUrls: ['./notification-add.component.scss']
})
export class NotificationAddComponent implements OnInit {
  @Input() postcode: JobPostcode | null = null;

  form!: NotificationForm;
  isLoading: boolean = false;

  constructor(private modalService: NgbModal, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.form = new NotificationForm({} as NotificationDto);
  }

  add() {
    console.log("add notification")
    if (this.postcode) {
      for (var i = 0; i < this.form.notificationJobPostcodes.length; i++) {
        this.form.notificationJobPostcodes.removeAt(i);
      }
      this.form.notificationJobPostcodes.push(new NotificationJobPostcodeForm({ jobPostcodeId: this.postcode.id } as NotificationJobPostcodeDto));
    }
    if (this.form.valid && !this.isLoading) {
      this.isLoading = true;
      var model = this.form.mapToModel();
      this.notificationService.add(model).subscribe(x => {
        this.isLoading = false;
        if (x) {
          let modal = this.modalService.open(NotificationEditModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'pxp-user-modal' });
          modal.componentInstance.notification = model;
          modal.componentInstance.postcode = this.postcode;
        }
      });
    }
  }


}
