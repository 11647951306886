import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { JobTheme } from 'src/models/jobTheme';
import { ODataResponse } from 'src/models/OData';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class JobThemeService extends BaseService<JobTheme, JobTheme> {

  constructor(protected override http: HttpClient, @Inject('BASE_URL') protected override baseUrl: string) {
    super(http, baseUrl, 'JobTheme');
  }

  getById(id: string | null) {
    return super.get(`/${id}`);
  }

  getByName(name: string | null) {
    return super.get(`/byName/${name}`);
  }

  getAllThemes() {
    return super.getAll();
  }

  getThemes(max: number): Observable<ODataResponse<JobTheme>> {
    return super.odata(`?$top=${max}&$expand=Organization&$orderBy=ListOrder`);
  }
}
