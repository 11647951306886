import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../../services/notification.service';
import { NotificationDto } from '../../models/notification';

@Component({
  selector: 'app-notification-edit-page',
  templateUrl: './notification-edit-page.component.html',
  styleUrls: ['./notification-edit-page.component.scss']
})
export class NotificationEditPageComponent implements OnInit {
  isConfirming = false;
  isConfirmed = false;
  notification: NotificationDto | null = null

  constructor(private route: ActivatedRoute, private router: Router, private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.route?.params.subscribe(params => {
      this.notificationService.getById(params['key']).subscribe(x => {
        this.notification = x;
        if (this.notification && !this.notification.emailConfirmed)
          this.confirm();
      });
    });
  }

  confirm() {
    if (this.notification) {
      this.isConfirming = true
      this.notificationService.confirm(this.notification.accessKey).subscribe(x => {
        if (this.notification && x) {
          this.isConfirming = false
          this.isConfirmed = true;
          setTimeout(() => {
            if (this.notification)
              this.notification.emailConfirmed = true
          }, 5000)
        }
      });
    }
  }

  delete() {
    if (this.notification) {
      this.notificationService.deleteByKey(this.notification.accessKey).subscribe(x => {
        this.goHome()
      });
    }
  }

  goHome() {
    this.router.navigate(["/"]);
  }
}
