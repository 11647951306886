<div class="modal-content">
    <div class="p-0 pb-3" style="border-bottom: 1px solid #dee2e6;">
        <div class="row">
            <div class="col-11 modal-title text-center mt-4 fs-3" *ngIf="organization">
                Stel je kandidaat voor<br />{{ job.name}}
            </div>
            <div class="col-1">
                <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <h3 class="text-success" *ngIf="success">Je bericht is succesvol verzonden.</h3>
        <form [formGroup]="form" (submit)="onSubmit()" *ngIf="!success">
            <div class="mb-3">
                <label for="contact-company-name" class="form-label fw-bolder">Naam</label>
                <input formControlName="name" type="text" class="form-control" id="contact-company-name">
            </div>
            <div class="mb-3">
                <label for="contact-company-email" class="form-label fw-bolder">E-mailadres</label>
                <input formControlName="email" type="email" class="form-control" id="contact-company-email">
            </div>
            <div class="mb-3">
                <label for="contact-company-message" class="form-label fw-bolder">Motivatie</label>
                <textarea formControlName="message" class="form-control" rows="8" id="contact-company-message" placeholder="Typ hier je bericht..."></textarea>
                <small *ngIf="!form.controls['message'].valid" class="text-danger">Je bericht moet minstens 10 karakters bevatten.</small>
            </div>
            <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (success)="handleSuccess($event)" [useGlobalDomain]="false" formControlName="recaptcha">
            </ngx-recaptcha2>
            <div class="text-center pt-3">
                <a (click)="close()" class="me-2">Annuleren</a>
                <button type="submit" class="btn rounded-pill pxp-section-cta" [disabled]="!form.valid || disabled">Verzenden</button>
            </div>
        </form>
    </div>
</div>