import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { FormModel } from "../../../../../../models/form-model";
import { JobApplication, JobApplicationTasksAndSkills } from "../../../../../../models/jobApplication";
export class JobApplicationForm extends FormModel<JobApplication> {
  constructor(
    override readonly model: JobApplication,
    readonly fb: FormBuilder = new FormBuilder()) {
    super(model, fb.group({
      id: [0],
      firstName: [model.firstName, Validators.required],
      lastName: [model.lastName, Validators.required],
      email: [model.email, [Validators.required, Validators.email]],
      gsm: [model.gsm, Validators.required],
      motivation: [model.motivation],
      jobOfferId: [model.jobOfferId, Validators.required],
      radio: [false],
      terms: [false, Validators.requiredTrue],
      tasksAndSkills: model.tasksAndSkills ? fb.array(model.tasksAndSkills.map(item => new JobApplicationTasksAndSkillsForm(item, fb))) : fb.array([]),
    }).controls);
  }

  readonly id = this.get("id") as FormControl;
  readonly firstName = this.get("firstName") as FormControl;
  readonly lastName = this.get("lastName") as FormControl;
  readonly email = this.get("email") as FormControl;
  readonly gsm = this.get("gsm") as FormControl;
  readonly motivation = this.get("motivation") as FormControl;
  readonly jobOfferId = this.get("jobOfferId") as FormControl;
  readonly radio = this.get("radio") as FormControl;
  readonly tasksAndSkills = this.get("tasksAndSkills") as FormArray;

  addTaskAndSkill(skillId: number, taskId: number | null = null): void {
    this.tasksAndSkills.push(new JobApplicationTasksAndSkillsForm({ skillId: skillId, taskId: taskId } as JobApplicationTasksAndSkills));
  }



}


export class JobApplicationTasksAndSkillsForm extends FormModel<JobApplicationTasksAndSkills> {
  constructor(
    override readonly model: JobApplicationTasksAndSkills,
    readonly fb: FormBuilder = new FormBuilder()
  ) {
    super(model, fb.group({
      taskId: [model.taskId],
      skillId: [model.skillId],
      jobApplicationId: [model.jobApplicationId ? model.jobApplicationId : 0],
    }).controls);
  }

  readonly taskId = this.get("taskId") as FormControl;
  readonly skillId = this.get("skillId") as FormControl;
  readonly jobApplicationId = this.get("jobApplicationId") as FormControl;
}
