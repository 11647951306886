<div class="row d-none d-md-flex justify-content-center pxp-animate-in pxp-animate-in-top pxp-in mt-md-5">
    <div class="col-md-6 col-xl-4 col-xxl-3 px-3 mt-4 mt-xxl-0" *ngFor="let volunteer of volunteers">
        <app-volunteer-card [volunteer]="volunteer"></app-volunteer-card>
    </div>
</div>

<div id="carouselExampleSlidesOnly" class="carousel slide d-md-none mt-5" data-bs-ride="carousel">
    <div class="carousel-inner">
        <div class="carousel-item active" *ngFor="let volunteer of volunteers">
            <app-volunteer-card [volunteer]="volunteer"></app-volunteer-card>
        </div>
    </div>
</div>