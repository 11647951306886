import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { JobOffer } from 'src/models/jobOffer';
import { ODataResponse } from 'src/models/OData';
import { Organization } from 'src/models/organization';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends BaseService<Organization, Organization> {

  constructor(protected override http: HttpClient, @Inject('BASE_URL') protected override baseUrl: string) {
    super(http, baseUrl, 'Organization');
  }

  getById(id: string | null): Observable<Organization> {
    return super.get(`/${id}`);
  }
}
