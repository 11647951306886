export enum DutchSkillTypeEnum {
  None = 0,
  Limited = 1,
  Sufficient = 2,
  Excellent = 3
}

export enum SuitabilityForDisabledPersonsEnum {
  NotSuitable = 0,
  Suitable = 1,
  VerySuitable = 2
}

export enum FrequencyEnum {
  Daily = 1,
  Weekly = 2,
  Monthly = 3
}

export enum FrequentionTypeEnum {
  Once = 1,
  Subsequently = 2,
  Multiple = 3,
  Recurring = 4,
  Indefinite = 5
}

//json based of enum FrequentionTypeEnum with label and value
export const FrequentionTypeEnumObject: EnumObject[] = [
  {
    label: 'Eenmalig',
    value: 1
  },
  {
    label: 'Een periode',
    value: 2
  },
  {
    label: 'Meerdere periodes',
    value: 3
  },
  {
    label: 'Terugkerend',
    value: 4
  },
  {
    label: 'Volgens afspraak',
    value: 5
  }
];

export const CollaborationTypeObject: EnumObject[] = [
  {
    label: 'Zonder collega\'s',
    value: 1
  },
  {
    label: 'Met collega\'s, individueel werk',
    value: 2
  },
  {
    label: 'In groep met collega\'s',
    value: 3
  }
];


export interface EnumObject {
  value: number;
  label: string;
}