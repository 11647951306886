import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobOffer } from 'src/models/jobOffer';
import { SearchForm } from 'src/services/filter-form.service';
import { MapService } from 'src/services/map.service';
import { JobModalComponent } from '../job-modal/job-modal.component';
import { observeFields } from './util';

@Component({
  selector: 'app-open-map',
  templateUrl: './open-map.component.html',
  styleUrls: ['./open-map.component.scss']
})
export class OpenMapComponent implements OnInit {
  @Input() jobs!: JobOffer[];
  @Input() height: number = 500;
  @Input() filter!: SearchForm;
  @Input() centerLng: number = 4.34878;
  @Input() lat = 50.85045;
  @Input() lng = 4.34878;
  @ViewChild('leafletMap') leafletMap!: ElementRef;
  fields = observeFields(this);
  map!: L.Map;

  constructor(private modalService: NgbModal, private mapService: MapService) {
  }

  ngOnInit(): void {
    if (this.mapService.L) {
      this.fields.leafletMap.subscribe(() => this.setupMap());
    }
  }

  public selectJob(job: JobOffer): void {
    let modal = this.modalService.open(JobModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'pxp-user-modal' });
    modal.componentInstance.job = job;
  }

  private setupMap() {
    if (!this.map && this.leafletMap) {
      this.map = this.mapService.L.map(this.leafletMap.nativeElement).setView([this.lat, this.lng], 12);
      this.loadLocation()
      if (this.filter.post.value)
        this.centerMap(this.filter.post.value.latitude, this.filter.post.value.longitude);
      else
        this.centerMap(this.filter.latitude.value, this.filter.longitude.value);

      this.mapService.L.tileLayer(
        'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
      ).addTo(this.map);
      (document.querySelector('.leaflet-control-attribution') as HTMLElement).style.display = 'none';
      if (this.jobs) this.loadJobs(this.jobs);
      this.fields.jobs.subscribe((val) => {
        this.loadJobs(val);
      })
    }
  }

  private loadJobs(val: JobOffer[]) {
    if(val) {
      for (let job of val) {
        if(this.map && job.jobLocation.latitude && job.jobLocation.longitude) this.map.addLayer(this.mapService.L.marker([job.jobLocation.latitude, job.jobLocation.longitude], {
          icon: this.mapService.L.icon({
            iconSize: [ 48, 48],
            iconUrl: 'assets/images/leaflet/location-pin.png',
          })
        }).on('click', () => {
          this.selectJob(job);
        }));
      }
    }
  }

  private loadLocation() {
    this.filter.get("post")?.valueChanges.subscribe((val) => {
      console.log(val)
      if (val)
        this.centerMap(val.latitude, val.longitude);
    });

    this.filter.latitude.valueChanges.subscribe((val) => {
      this.centerMap(this.filter.latitude.value, this.filter.longitude.value);
    });
  }

  centerMap(lat: number, lng: number) {
    if (this.map) this.map.panTo(this.mapService.L.latLng(lat, lng));

  }

}
