<app-navbar></app-navbar>

<section id="top" class="pt-100 pb-100">
    <div class="pxp-container mt-30">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-8">
                <h1 class="pxp-title text-center">Waar wil je helpen?</h1>
            </div>
        </div>
        <div *ngIf="notification && !notification?.emailConfirmed && isConfirmed" class="row justify-content-center mt-5">
            <div class="col-12 col-lg-8">
                <h2 class="text-center mb-1">Je e-mailadres is bevestigd</h2>
                <h5 class="text-center">Je zal vanaf nu meldingen ontvangen van onderstaande gemeenten.</h5>
            </div>
        </div>
    </div>
</section>

<section class="pb-100">
    <div class="pxp-container">
        <app-notification-edit *ngIf="notification" [notification]="notification" (onDeleted)="goHome()"></app-notification-edit>
    </div>
</section>
