import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormModel } from '../../../../models/form-model';
import { NotificationDto, NotificationJobPostcodeDto } from '../models/notification';
export class NotificationForm extends FormModel<NotificationDto> {
  constructor(override readonly model: NotificationDto = {} as NotificationDto, readonly fb: FormBuilder = new FormBuilder()) {
    super(model, fb.group({
      id: [model.id ? model.id : 0],
      email: [model.email, [Validators.required, Validators.email]],
      accessKey: [model.accessKey],
      notificationJobPostcodes: fb.array((model.notificationJobPostcodes ? model.notificationJobPostcodes.map(x => new NotificationJobPostcodeForm(x, fb)) : [])),
    }).controls);
    console.log("new NotificationForm")
  }

  readonly id = this.get("id") as FormControl;
  readonly email = this.get("email") as FormControl;
  readonly accessKey = this.get("accessKey") as FormControl;
  readonly notificationJobPostcodes = this.get("notificationJobPostcodes") as FormArray;
}

export class NotificationJobPostcodeForm extends FormModel<NotificationJobPostcodeDto> {
  constructor(override readonly model: NotificationJobPostcodeDto = {} as NotificationJobPostcodeDto, readonly fb: FormBuilder = new FormBuilder()) {
    super(model, fb.group({
      notificationId: [model.notificationId ? model.notificationId : 0],
      jobPostcodeId: [model.jobPostcodeId, Validators.required],
    }).controls);
  }

  readonly notificationId = this.get("notificationId") as FormControl;
  readonly jobPostcodeId = this.get("jobPostcodeId") as FormControl;
  readonly jobPostcode = this.get("jobPostcode") as FormControl; // Adjust this if JobPostcodeListDto has a different structure
}
