import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JobResolver } from 'src/resolvers/job.resolver';
import { OrganizationResolver } from 'src/resolvers/organization.resolver';
import { PostcodeResolver } from '../../resolvers/postcode.resolver';
import { WidgetResolver } from '../../resolvers/widget.resolver';
import { WidgetComponent } from '../widget/widget.component';
import { AssistComponent } from './assist/assist.component';
import { FaqvlbComponent } from './faqvlb/faqvlb.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { HomeComponent } from './home/home.component';
import { JobDetailComponent } from './jobs/job-detail/job-detail.component';
import { SearchJobsComponent } from './jobs/search-jobs/search-jobs.component';
import { MainComponent } from './main.component';
import { NotificationEditPageComponent } from './notification/pages/notification-edit-page/notification-edit-page.component';
import { OrganizationComponent } from './organization/organization.component';
import { PostcodeDetailComponent } from './postcode/postcode-detail/postcode-detail.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { RegisterComponent } from './register/register.component';
import { TosComponent } from './tos/tos.component';

const routes: Routes = [
  { path: 'widget', component: WidgetComponent },
  { path: 'widget/:uuid/:type', component: WidgetComponent, resolve: { widget: WidgetResolver } },
  {
    path: '', component: MainComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'algemene-gebruiksvoorwaarden', component: TosComponent },
      { path: 'draaiboek', component: FaqvlbComponent },
      { path: 'favorieten', component: FavoritesComponent },
      { path: 'organisatie/:nameForUrl/:id', component: OrganizationComponent, resolve: { organization: OrganizationResolver } },
      { path: 'organisatie/:id', component: OrganizationComponent, resolve: { organization: OrganizationResolver } },
      { path: 'privacy-verklaring', component: PrivacyComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'start', component: AssistComponent },
      { path: 'vacatures', component: SearchJobsComponent },
      { path: 'vacatures/:nameForUrl/:id', component: JobDetailComponent, resolve: { job: JobResolver } },
      { path: 'vacatures/:id', component: JobDetailComponent, resolve: { job: JobResolver } },
      { path: 'voor-lokale-besturen', component: FaqvlbComponent },
      { path: 'vrijwilligerswerk-zoeken', redirectTo: 'vacatures' },
      { path: ':nameForUrl', component: PostcodeDetailComponent, resolve: { postcode: PostcodeResolver } },
      { path: 'laat-me-iets-weten/:key', component: NotificationEditPageComponent },
      //{ path: ':nameForUrl', component: ThemeComponent, resolve: { theme: ThemeResolver } },
      { path: '**', redirectTo: '', pathMatch: 'full' }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
