import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as Aos from 'aos';
import { JobOffer } from '../../../../models/jobOffer';
import { JobPostcode } from '../../../../models/jobPostcode';
import { SearchForm } from '../../../../services/filter-form.service';
import { JobService } from '../../../../services/job.service';


@Component({
  selector: 'app-postcode-detail',
  templateUrl: './postcode-detail.component.html',
  styleUrls: ['./postcode-detail.component.scss']
})
export class PostcodeDetailComponent implements OnInit {
  postcode!: JobPostcode
  searchForm!: SearchForm;
  jobs: JobOffer[] = [];
  totalResults: number = 0;
  hasFeatured: boolean = false;

  constructor(private title: Title, private route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: Object, @Inject('IMAGE_SERVER_URL') public imageUrl: string, private jobService: JobService,) {

  }

  ngOnInit(): void {
    console.log("PostcodeDetailComponent.ngOnInit")
    if (isPlatformBrowser(this.platformId)) { Aos.init(); }
    this.route.data.subscribe(({ postcode }) => {
      this.postcode = postcode;
      console.log("PostcodeDetailComponent.route.data.subscribe")
      this.totalResults = 0

      this.jobService.getAllByPostcode(this.postcode.id).subscribe(res => {
        this.jobs = res;
        this.hasFeatured = false;
        setTimeout(() => {
          this.totalResults = res.length;
          this.hasFeatured = this.jobs.find(x => x.featured === true) != undefined;
          if (this.hasFeatured) {
            this.totalResults--;
          }
        }, 200);

      });
    });
  }

  getUrl(url: string) {
    if (url && url.indexOf('http') > -1) {
      return url;
    } else {
      return this.imageUrl + url
    }
  }
}


