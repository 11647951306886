import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxCaptchaModule } from 'ngx-captcha-ssr';
import { ClipboardModule } from 'ngx-clipboard';
import { HrefCheckPipe } from 'src/helpers/href-check';
import { MapService } from 'src/services/map.service';
import { WidgetComponent } from '../widget/widget.component';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { FaqComponent } from './faq/faq.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { JobCardComponent } from './jobs/job-card/job-card.component';
import { FavoriteButtonComponent } from './jobs/job-detail/favorite-button/favorite-button.component';
import { GoogleJobComponent } from './jobs/job-detail/google-job/google-job.component';
import { JobDetailComponent } from './jobs/job-detail/job-detail.component';
import { JobLocationComponent } from './jobs/job-detail/job-location/job-location.component';
import { LocationMapComponent } from './jobs/job-detail/job-location/location-map/location-map.component';
import { JobMomentComponent } from './jobs/job-detail/job-moment/job-moment.component';
import { RegistrationComponent } from './jobs/job-detail/registration/registration.component';
import { JobListComponent } from './jobs/job-list/job-list.component';
import { JobModalComponent } from './jobs/job-modal/job-modal.component';
import { OpenMapComponent } from './jobs/open-map/open-map.component';
import { SearchJobsComponent } from './jobs/search-jobs/search-jobs.component';
import { SearchComponent } from './jobs/search/search.component';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { ModalsComponent } from './modals/modals.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NotificationAddComponent } from './notification/components/notification-add/notification-add.component';
import { NotificationEditComponent } from './notification/components/notification-edit/notification-edit.component';
import { NotificationEditModalComponent } from './notification/pages/notification-edit-modal/notification-edit-modal.component';
import { OrganizationComponent } from './organization/organization.component';
import { SocialMediaComponent } from './organization/social-media/social-media.component';
import { PostcodeDetailComponent } from './postcode/postcode-detail/postcode-detail.component';
import { PostcodeSelectComponent } from './postcode/postcode-select/postcode-select.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { RegisterComponent } from './register/register.component';
import { ThemeCardComponent } from './theme/theme-card/theme-card.component';
import { ThemeComponent } from './theme/theme-detail/theme.component';
import { ThemeListComponent } from './theme/theme-list/theme-list.component';
import { TosComponent } from './tos/tos.component';
import { VolunteerCardComponent } from './volunteers/volunteer-card/volunteer-card.component';
import { VolunteerListComponent } from './volunteers/volunteer-list/volunteer-list.component';
import { VolunteerModalComponent } from './volunteers/volunteer-modal/volunteer-modal.component';
import { FaqvlbComponent } from './faqvlb/faqvlb.component';
import { AssistComponent } from './assist/assist.component';
import { NotificationEditPageComponent } from './notification/pages/notification-edit-page/notification-edit-page.component';



@NgModule({
  declarations: [
    MainComponent,
    HomeComponent,
    RegisterComponent,
    NavbarComponent,
    ModalsComponent,
    JobCardComponent,
    PrivacyComponent,
    TosComponent,
    FooterComponent,
    JobDetailComponent,
    JobListComponent,
    ContactModalComponent,
    SearchJobsComponent,
    JobModalComponent,
    VolunteerModalComponent,
    JobMomentComponent,
    JobLocationComponent,
    GoogleJobComponent,
    OrganizationComponent,
    SocialMediaComponent,
    VolunteerListComponent,
    VolunteerCardComponent,
    RegistrationComponent,
    ThemeComponent,
    ThemeCardComponent,
    ThemeListComponent,
    SearchComponent,
    HrefCheckPipe,
    WidgetComponent,
    OpenMapComponent,
    LocationMapComponent,
    FavoritesComponent,
    FavoriteButtonComponent,
    PostcodeDetailComponent,
    PostcodeSelectComponent,
    FaqComponent,
    NotificationEditComponent,
    NotificationAddComponent,
    NotificationEditModalComponent,
    FaqvlbComponent,
    AssistComponent,
    NotificationEditPageComponent
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    ClipboardModule,
    NgxCaptchaModule
  ],
  bootstrap: [MainComponent],
  providers: [MapService]
})
export class MainModule { }
