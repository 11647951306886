<div class="row">
  <div class="col-12 pb-4" *ngIf="showMap">
    <div class="map-responsive" style="height: 400px; margin-top: 16px;">
      <app-open-map [jobs]="jobs$" [height]="400" [filter]="searchForm"></app-open-map>
    </div>
  </div>
  <div *ngIf="!widget || widget.showFilter" class="col-lg-4 col-xl-4 col-xxl-4">
    <a (click)="openFilterModal(modal)" role="button"
      class="btn btn-sm rounded-pill pxp-section-cta px-3 py-1 fs-6 fly-filter d-block d-lg-none">Filter</a>

    <div class="pxp-jobs-list-side-filter stuck filter-mobile {{widget ? 't-0' : ''}}" #filter>
      <div class="pxp-list-side-filter-header d-flex d-lg-none">
        <div class="pxp-list-side-filter-header-label d-none d-lg-block">Filter Vacatures</div>
        <a (click)="toggleFilter()" role="button"><span class="fa fa-sliders"></span></a>
      </div>
      <div #filters class="mt-4 mt-lg-0 pxp-list-side-filter-panel" ngClass="{{filterShown ? 'd-block' : ''}}">

        <div class="stuck filter-mobile {{widget ? 't-0' : ''}}">
          <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
        </div>

      </div>
    </div>
  </div>
  <div class="col-lg-8 col-xl-8 col-xxl-8" *ngIf="!jobs$">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div class="{{widget && !widget.showFilter ? 'col-lg-12' : 'col-lg-8'}} mt-3 mt-lg-0" *ngIf="jobs$">
    <div class="pxp-jobs-list-top mt-4 mt-lg-0 px-4">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <h2>
            <span *ngIf="totalResults == 0">Geen vacatures gevonden in je buurt</span>
            <span *ngIf="totalResults == 1">{{ totalResults }} vacature gevonden</span>
            <span *ngIf="totalResults > 1">{{ totalResults }} vacatures gevonden</span>
          </h2>
        </div>
        <div *ngIf="totalResults > 0" class="col-auto">
          <div class="d-inline-block">
            <form [formGroup]="searchForm">
              <select class="form-select" formControlName="orderBy">
                <option value="">Dichtste bij</option>
                <option value="desc">Nieuwste</option>
                <option value="asc">Oudste</option>
              </select>
            </form>
          </div>
          <div class="d-none d-xl-inline-block ms-3">
            <a *ngIf="!showMap" (click)="toggleMap()"
              class="btn btn-sm rounded-pill pxp-section-cta d-inline-block px-3 py-1 fs-6">Toon kaart</a>
            <a *ngIf="showMap" (click)="toggleMap()"
              class="btn btn-sm rounded-pill pxp-section-cta d-inline-block px-3 py-1 fs-6">Verberg
              kaart</a>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="totalResults == 0" class="mt-3 ms-4">
      Probeer in de filter de maximum afstand te vergroten of een andere locatie te selecteren.
    </div>

    <div [ngClass]="loading ? 'loading' : ''">
      <app-job-list [widget]="widget" [jobs$]="jobs$" [theme]="theme" [showFeatured]="showFeatured" [searchForm]="searchForm"></app-job-list>
      <div class="row mt-4 mt-lg-5 justify-content-between align-items-center text-center">
        <!-- <div class="col-auto d-none">
          <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">
            <ul class="pagination pxp-pagination">
              <li class="page-item" [class.active]="(i + 1) === currentPage"
                *ngFor="let page of Arr(totalPages).fill(1); let i = index;">
                <a *ngIf="currentPage + 5 >= i && currentPage - 5 <= i" class="page-link"
                  (click)="pageChanged(i + 1)">{{ i + 1 }}</a>
              </li>
            </ul>
          </nav>
        </div> -->
        <div *ngIf="(totalResults > top && totalPages>(skip/top)) || initialLoad">
          <button [disabled]="loadingMore" class="btn rounded-pill pxp-section-cta mt-3 mt-sm-0"
            (click)="searchMore()">Laad meer
            <span *ngIf="!loadingMore" class="fa fa-plus"></span>
            <span *ngIf="loadingMore" class="fa fa-circle-notch fa-spin"></span>
          </button>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title mt-4" id="modal-basic-title">Filterer in vacatures</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body overflow-scroll">
    <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
  </div>
</ng-template>


<ng-template #filterTemplate let-filterTemplate>
  <div #filters class="mt-4 mt-lg-0 pxp-list-side-filter-panel pxp-jobs-list-side-filter">
    <form [formGroup]="searchForm">
      <div class="row">
        <div class="col" *ngIf="enabledFilters.searchTerm">
          <h3 class="mt-1">Zoekterm</h3>
        </div>
        <div class="col text-end" *ngIf="!showSmallFilter">
          <a (click)="resetFilters()" role="button"
            class="btn btn-sm rounded-pill pxp-section-cta d-inline-block px-2 py-1 fs-xl-6 resetFilter"
            style="font-size: 0.75rem;">Wis alle filters</a>
        </div>
      </div>

      <div class="mt-2 mt-lg-3" *ngIf="enabledFilters.searchTerm">
        <div class="input-group">
          <span class="input-group-text"><span class="fa fa-search"></span></span>
          <input [formControl]="searchForm.name" [value]="searchForm.name.value" type="text" class="form-control"
            placeholder="Wat? Zoekterm of interesse">
        </div>
      </div>
      <div *ngIf="enabledFilters.location">
        <h3 class="mt-3 mt-lg-4" *ngIf="enabledFilters.location">Waar?</h3>
        <div class="list-group mt-2 mt-lg-3" *ngIf="enabledFilters.location">
          <label class="list-group-item d-flex justify-content-between align-items-center" *ngIf="!showSmallFilter">
            <span class="d-flex">
              <input class="form-check-input me-2" type="checkbox" value="" formControlName="fromHome">
              Van thuis uit
            </span>
          </label>

          <div class="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
            <span class="d-flex">
              <input class="form-check-input me-2" type="checkbox" formControlName="onLocation">
              Op locatie vanuit
              <input type="text" class="form-control ms-1 py-0 px-2" style="width: 120px; border-radius: 30px;"
                [formControl]="searchForm.post" [value]="formatLocation(searchForm.post.value)"
                [ngbTypeahead]="searchCity" [inputFormatter]="formatter" [resultFormatter]="formatter"
                [editable]='false' [placeholder]="getPlaceholder()" (selectItem)="selectCity($event)">
            </span>
          </div>

          <label class="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3 pxp-checked"
            style="margin-left: 25px;">
            <span class="d-flex">
              <input class="form-check-input me-2" type="checkbox" formControlName="withDistance">
              Max. afstand <input type="text" class="form-control ms-1 py-0 px-2" min="2" max="120"
                formControlName="distance" style="width: 40px; border-radius: 30px;"
                value="{{searchForm.value.distance}}">&nbsp;km.
            </span>
          </label>
          <input type="range" class="form-range mt-3 pe-5" min="2" max="120" formControlName="distance"
            style="margin-left: 50px;">
        </div>
      </div>

      <div *ngIf="showSmallFilter">

        <h3 class="mt-3 mt-lg-4">Wanneer</h3>

        <div class="mt-2 mt-lg-3" *ngIf="false">
          <div class="row">
            <div class="col-md-2 d-flex align-items-center">
              Op
            </div>
            <div class="col-md-10">
              <div class="input-group">
                <span class="input-group-text"></span>
                <input formControlName="from" type="date" class="form-control ps-0" style="padding-right: 1.15rem;"
                  placeholder="dd-mm-jjjj">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="theme && theme.id == 8">
          <!-- multiselect with all dates from value dates -->
          <div class="mt-2 mt-lg-3">
            <div class="input-group">
              <span class="input-group-text"><span class="fa fa-calendar"></span></span>
              <ng-multiselect-dropdown [placeholder]="'Datums'" [settings]="dropdownSettings"
                [data]="datesWithIdAndName" formControlName="timeFrames">
              </ng-multiselect-dropdown>
            </div>
          </div>

        </div>



        <h3 class="mt-3 mt-lg-4">Jobinhoud</h3>
        <div class="list-group mt-2 mt-lg-3">
          <label class="list-group-item d-flex justify-content-between align-items-center">
            <span class="d-flex">
              <input class="form-check-input me-2" type="checkbox" value="" formControlName="digihelpers">
              Digihelpers
            </span>
          </label>
        </div>
      </div>

      <div *ngIf="!showSmallFilter">
        <h3 class="mt-3 mt-lg-4"
          *ngIf="enabledFilters.sectors || enabledFilters.targetAudiences || enabledFilters.themes || enabledFilters.collab">
          Jobinhoud</h3>


        <div class="mt-2 mt-lg-3" *ngIf="enabledFilters.sectors">
          <div class="input-group">
            <span class="input-group-text"><span class="fa fa-sitemap"></span></span>
            <ng-multiselect-dropdown [placeholder]="'Sectoren'" [settings]="dropdownSettings" [data]="sectors"
              [formControl]="searchForm.sectors">
            </ng-multiselect-dropdown>
          </div>
        </div>

        <div class="mt-2 mt-lg-3" *ngIf="enabledFilters.targetAudiences">
          <div class="input-group">
            <span class="input-group-text"><span class="fa fa-users"></span></span>
            <ng-multiselect-dropdown [placeholder]="'Doelgroep'" [settings]="dropdownSettings" [data]="audiences"
              formControlName="audiences">
            </ng-multiselect-dropdown>
          </div>
        </div>

        <div class="mt-2 mt-lg-3" *ngIf="enabledFilters.talents">
          <div class="input-group">
            <span class="input-group-text"><span class="fa fa-star"></span></span>
            <ng-multiselect-dropdown [placeholder]="'Talenten'" [settings]="dropdownSettings" [data]="talents"
              formControlName="talents">
            </ng-multiselect-dropdown>
          </div>
        </div>

        <div class="mt-2 mt-lg-3" *ngIf="enabledFilters.themes && !theme">
          <div class="input-group">
            <span class="input-group-text"><span class="fa fa-folder"></span></span>
            <ng-multiselect-dropdown [placeholder]="'Thema\'s'" [settings]="dropdownSettings" [data]="themes"
              formControlName="themes">
            </ng-multiselect-dropdown>
          </div>
        </div>

        <div class="mt-2 mt-lg-3" *ngIf="enabledFilters.collab">
          <div class="input-group">
            <span class="input-group-text"><span class="fa fa-handshake"></span></span>
            <select formControlName="collab" class="form-select">
              <option selected="" value="">Werken met of zonder collega's</option>
              <option *ngFor="let collab of collabs;" [value]="collab.value">{{collab.label}}
              </option>
            </select>
          </div>
        </div>

        <h3 class="mt-3 mt-lg-4" *ngIf="enabledFilters.frequency || enabledFilters.period || enabledFilters.timespan">
          Wanneer
        </h3>

        <div class="mt-2 mt-lg-3" *ngIf="enabledFilters.frequency">
          <div class="input-group">
            <span class="input-group-text"><span class="fa fa-rotate-left"></span></span>
            <select formControlName="frequency" class="form-select">
              <option selected="" value="">Frequentie</option>
              <option *ngFor="let freq of frequentions;" [value]="freq.value">{{freq.label}}
              </option>
            </select>
          </div>
        </div>

        <div class="mt-2 mt-lg-3" *ngIf="enabledFilters.period && (theme?.id != 8)">
          <div class="row">
            <div class="col-md-2 d-flex align-items-center">
              Van
            </div>
            <div class="col-md-10">
              <div class="input-group">
                <span class="input-group-text"></span>
                <input formControlName="from" type="date" class="form-control ps-0" style="padding-right: 1.15rem;"
                  placeholder="Wat? Zoekterm of interesse">
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2 mt-lg-3" *ngIf="enabledFilters.period && (theme?.id != 8)">
          <div class="row">
            <div class="col-md-2 d-flex align-items-center">
              Tot
            </div>
            <div class="col-md-10">
              <div class="input-group">
                <span class="input-group-text"></span>
                <input formControlName="till" type="date" class="form-control ps-0" style="padding-right: 1.15rem;"
                  placeholder="Wat? Zoekterm of interesse">
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2 mt-lg-3" *ngIf="enabledFilters.timespan">
          <div class="input-group">
            <span class="input-group-text"><span class="fa fa-clock"></span></span>
            <ng-multiselect-dropdown [placeholder]="'Selecteer tijdspanne'" [settings]="dropdownSettings"
              [data]="timeSpans" formControlName="timespan">
            </ng-multiselect-dropdown>
          </div>
        </div>

        <h3 class="mt-3 mt-lg-4">Toegankelijkheid</h3>

        <div class="list-group mt-2 mt-lg-3 d-none">
          <!-- VSVw heeft beslist deze optie weg te laten -->
          <label class="list-group-item d-flex justify-content-between align-items-center">
            <span class="d-flex">
              <input class="form-check-input me-2" type="checkbox" value="" formControlName="disability">
              Mogelijkheden voor mensen met een beperking
            </span>
          </label>
        </div>

        <div class="list-group mt-2 mt-lg-3">
          <label class="list-group-item d-flex justify-content-between align-items-center">
            <span class="d-flex">
              <input class="form-check-input me-2" type="checkbox" value="" formControlName="canPracticeDutch">
              Mensen die Nederlands willen oefenen, zijn welkom
            </span>
          </label>
        </div>

        <div class="list-group mt-2 mt-lg-3">
          <!-- Deze optie stond er nog niet tussen, maar moet nog nagekeken worden. Ik heb in afwachting de 'formControlName' verwijderd. -->
          <label class="list-group-item d-flex justify-content-between align-items-center">
            <span class="d-flex">
              <input class="form-check-input me-2" type="checkbox" value="" formControlName="noDutch">
              Mensen die geen Nederlands kennen, zijn welkom
            </span>
          </label>
        </div>

        <div class="list-group mt-2 mt-lg-3">
          <label class="list-group-item d-flex justify-content-between align-items-center">
            <span class="d-flex">
              <input class="form-check-input me-2" type="checkbox" value="" formControlName="extraDisability">
              Extra ondersteuning voor mensen met een beperking
            </span>
          </label>
        </div>
      </div>
    </form>
  </div>
</ng-template>
