<section>
    <div class="pxp-container">
        <div class="pxp-single-job-cover pxp-cover" style="background-color: var(--pxpMainColorLight);height: 110px;">
        </div>
        <!--
      <div class="pxp-single-job-cover pxp-cover" [ngStyle]="{'background-image': 'url(' + getUrl(job.organization.bannerUrl) + ')'}"></div>
      <div class="pxp-single-job-cover-logo bg-white" [ngStyle]="{'background-image': 'url(' +  getUrl(job.organization.logoUrl) + ')'}"></div>
    -->
        <div class="pxp-single-job-content mt-4 mt-lg-5">
            <div class="row">
                <div class="col-lg-7 col-xl-8">
                    <div class="row justify-content-between">
                        <div class="col-12">
                            <div *ngIf="job.featured" class="pxp-jobs-card-5-feat-label">
                                <span class="badge rounded-pill bg-success mb-2"><i class="fa fa-star"></i>
                                    Uitgelicht</span>
                            </div>
                            <h1>{{job.name}}</h1>
                        </div>
                        <div class="col-11">
                            <div class="pxp-single-job-company-location" *ngIf="job.jobLocation && !job.isAtHome">
                                <h5>
                                    bij <a
                                        routerLink="/organisatie/{{job.organization.nameForUrl}}/{{job.organizationId}}"
                                        class="pxp-single-job-company">{{job.organization.name}}</a> in
                                    <a href="#" class="pxp-single-job-location">{{job.jobLocation.location}}</a>
                                </h5>
                            </div>
                            <div class="pxp-single-job-company-location" *ngIf="job.isAtHome || !job.jobLocation">
                                <h5>
                                    bij <a
                                        routerLink="/organisatie/{{job.organization.nameForUrl}}/{{job.organizationId}}"
                                        class="pxp-single-job-company">{{job.organization.name}}</a> van thuis uit
                                </h5>
                            </div>
                        </div>
                        <div class="col-3 d-flex justify-content-end d-lg-none">
                            <div class="pxp-single-job-options">
                                <app-favorite-button [job]="job"></app-favorite-button>
                                <div class="dropdown ms-2">
                                    <button class="btn pxp-single-job-share-btn dropdown-toggle" type="button"
                                        id="socialShareBtn" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span class="fa fa-share-alt">
                                        </span>
                                    </button>
                                    <ul class="dropdown-menu pxp-single-job-share-dropdown"
                                        aria-labelledby="socialShareBtn">
                                        <li *ngIf="false">
                                            <a class="dropdown-item" target="_blank"
                                                href="https://www.facebook.com/sharer/sharer.php?u={{url}}"><span
                                                    class="fa-brands fa-facebook"></span> Facebook</a>
                                        </li>
                                        <li *ngIf="false">
                                            <a class="dropdown-item"
                                                href="https://twitter.com/intent/tweet?text={{url}}"><span
                                                    class="fa-brands fa-instagram"></span> Instagram</a>
                                        </li>
                                        <li *ngIf="false">
                                            <a class="dropdown-item" target="_blank"
                                                href="https://twitter.com/intent/tweet?text={{url}}"><span
                                                    class="fa-brands fa-twitter"></span> Twitter</a>
                                        </li>
                                        <li *ngIf="false">
                                            <a class="dropdown-item" target="_blank"
                                                href="https://www.linkedin.com/sharing/share-offsite/?url={{url}}"><span
                                                    class="fa-brands fa-linkedin"></span> LinkedIn</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" ngxClipboard [cbContent]="url"
                                                style="cursor: pointer"><span class="fa fa-copy"></span> Kopieer de link
                                                naar deze vacature</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pxp-single-job-content-details mt-3 mt-lg-4">
                        <span [innerHtml]="job.description"></span>
                        <div class="mt-4 pt-1" *ngIf="job.volunteerTaskDescription">
                            <h5>Taakomschrijving</h5>
                            <span [innerHtml]="job.volunteerTaskDescription"></span>
                        </div>
                        <div class="mt-4 pt-1" *ngIf="job.volunteerProfileDescription">
                            <h5>Jouw profiel</h5>
                            <span [innerHtml]="job.volunteerProfileDescription"></span>
                        </div>
                        <div class="mt-4 pt-1" *ngIf="job.volunteerOfferDescription">
                            <h5>Wat kunnen wij je bieden</h5>
                            <span [innerHtml]="job.volunteerOfferDescription"></span>
                        </div>
                        <div class="mt-4 pt-1">
                            <h5>Wanneer</h5>
                            <app-job-moment [job]="job"></app-job-moment>
                        </div>
                        <div class="mt-4 pt-1">
                            <h5>Waar</h5>
                            <app-job-location [job]="job"></app-job-location>
                        </div>
                        <div *ngIf="job.dutchSkillType==0 || job.canPracticeDutch || job.hasExtraAdaptationsForDisabledPersons || job.hasFee || job.collaborationTypeId==1 || job.collaborationTypeId==2"
                            class="mt-4 pt-1">
                            <h5>Goed om weten</h5>
                            <div class="">
                                <ul>
                                    <li *ngIf="job.dutchSkillType==0">Vrijwilligers die geen Nederlands kennen zijn
                                        welkom
                                    </li>
                                    <li *ngIf="job.canPracticeDutch">Vrijwilligers die Nederlands willen oefenen zijn
                                        welkom
                                    </li>
                                    <li *ngIf="job.hasExtraAdaptationsForDisabledPersons">Vrijwilligers met een
                                        beperking of met een psychische kwetsbaarheid kunnen naast de verplichte
                                        redelijke aanpassingen rekenen op extra ondersteuning</li>
                                    <li *ngIf="job.hasFee">Er wordt een vergoeding voorzien</li>
                                    <li *ngIf="job.collaborationTypeId==1">Dit vrijwilligerswerk doe je alleen</li>
                                    <li *ngIf="job.collaborationTypeId==2">Dit vrijwilligerswerk doe je samen</li>
                                </ul>
                            </div>
                        </div>
                        <!--
                        <span class="d-block pt-4">
                            Sectors: {{job.sectors.length}}<br />
                            Thema's: {{job.themes.length}}
                        </span>
                        -->
                        <div *ngIf="job.attachmentUrl || job.sectors.length > 0" class="mt-4">
                            <h5>Meer info</h5>
                            <div class="">
                                <ul>
                                    <li *ngIf="job.sectors.length == 1">
                                        <strong>Sector:&nbsp;</strong>{{job.sectors[0].sector.name}}
                                    </li>
                                    <li *ngIf="job.sectors.length > 1">
                                        <strong>Sectoren:</strong>&nbsp;{{sectors}}
                                    </li>
                                    <li *ngIf="job.themes.length == 1">
                                        <strong>Thema:&nbsp;</strong>{{job.themes[0].theme.name}}
                                    </li>
                                    <li *ngIf="job.themes.length > 1">
                                        <strong>Thema's:</strong>&nbsp;{{themes}}
                                    </li>
                                    <li *ngIf="job.attachmentUrl">
                                        <a href="{{job.attachmentUrl}}" target="_blank">{{job.attachmentUrl}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="text-center text-lg-start mt-5 mb-3">
                            <a (click)="openRegistrationModal()"
                                class="btn rounded-pill pxp-section-cta mx-2 my-2 ms-lg-0">Stel je kandidaat</a>
                            <!--
                              <button *ngIf="job.contactPerson" class="btn rounded-pill pxp-section-cta mx-2 my-2 ms-lg-1" (click)="contactForm()">Stuur een bericht</button>
                            -->
                        </div>
                        <!--
                        <div *ngIf="job.hasBeenFilled" class="text-center text-lg-start mt-5 mb-3">
                            <button type="button" class="btn btn-success pe-none">Vacature ingevuld</button>
                        </div>
                        -->
                    </div>
                </div>
                <div class="col-lg-5 col-xl-4">
                    <div
                        class="pxp-single-job-options mt-1 mb-3 mx-0 col-xl-0 d-none d-lg-flex justify-content-between">
                        <div>
                            <button class="btn pxp-single-job-apply-btn rounded-pill"
                                (click)="openRegistrationModal()">Stel je kandidaat</button>
                        </div>
                        <!--
                        <div *ngIf="job.hasBeenFilled">
                            <button type="button" class="btn btn-success pe-none">Vacature ingevuld</button>
                        </div>
                        -->
                        <div class="d-flex">
                            <app-favorite-button [job]="job"></app-favorite-button>
                            <div class="dropdown ms-2">
                                <button class="btn pxp-single-job-share-btn dropdown-toggle" type="button"
                                    id="socialShareBtn" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span class="fa fa-share-alt">
                                    </span>
                                </button>
                                <ul class="dropdown-menu pxp-single-job-share-dropdown"
                                    aria-labelledby="socialShareBtn">
                                    <li *ngIf="false">
                                        <a class="dropdown-item" target="_blank"
                                            href="https://www.facebook.com/sharer/sharer.php?u={{url}}"><span
                                                class="fa-brands fa-facebook"></span> Facebook</a>
                                    </li>
                                    <li *ngIf="false">
                                        <a class="dropdown-item"
                                            href="https://twitter.com/intent/tweet?text={{url}}"><span
                                                class="fa-brands fa-instagram"></span> Instagram</a>
                                    </li>
                                    <li *ngIf="false">
                                        <a class="dropdown-item" target="_blank"
                                            href="https://twitter.com/intent/tweet?text={{url}}"><span
                                                class="fa-brands fa-twitter"></span> Twitter</a>
                                    </li>
                                    <li *ngIf="false">
                                        <a class="dropdown-item" target="_blank"
                                            href="https://www.linkedin.com/sharing/share-offsite/?url={{url}}"><span
                                                class="fa-brands fa-linkedin"></span> LinkedIn</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" ngxClipboard [cbContent]="url"
                                            style="cursor: pointer"><span class="fa fa-copy"></span> Kopieer de link
                                            naar deze vacature</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="pxp-single-job-side-panel mt-5 mt-lg-0">
                        <div>
                            <!-- <div *ngIf="job.organization.logoUrl" class="pxp-single-job-side-company-logo pxp-cover" [ngStyle]="{'background-image': 'url(' + getUrl(job.organization.logoUrl) + ')'}"></div> -->
                            <div class="pxp-single-job-side-info-data">Bij wie?</div>
                            <div class="pxp-single-job-side-info-label">
                                <strong>{{job.organization.name}}</strong>
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="pxp-single-job-side-info-data">Wanneer?</div>
                            <div class="pxp-single-job-side-info-label">
                                <app-job-moment [job]="job"></app-job-moment>
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="pxp-single-job-side-info-data">Waar?</div>
                            <div class="pxp-single-job-side-info-label">
                                <app-job-location [job]="job"></app-job-location>
                            </div>
                        </div>
                        <div class="mt-4" *ngIf="job.jobLocation">
                            <div class="map-responsive" style="height: 200px;">
                                <app-location-map [location]="job.jobLocation"></app-location-map>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 mt-lg-4 pxp-single-job-side-panel">
                        <div *ngIf="job.contactPerson" class="mb-4">
                            <div class="pxp-single-job-side-info-data">Contactpersoon voor deze vacature</div>
                            <div class="pxp-single-job-side-info-label pxp-text-light">
                                {{job.contactPerson.firstName}} {{job.contactPerson.lastName}}
                                <span *ngIf="job.contactPerson.telephone"><br />{{job.contactPerson.telephone}}</span>
                                <span *ngIf="job.contactPerson.email"><br /><a
                                        [attr.href]="'mailto:' + job.contactPerson.email">{{job.contactPerson.email}}</a></span>
                            </div>
                        </div>
                        <div>
                            <div class="pxp-single-job-side-info-data">
                                {{job.organization.name}}
                            </div>
                        </div>
                        <div class="mt-2 d-flex align-items-center">
                            <div class="d-inline-block">
                                <a [attr.href]="job.organization.website | hrefCheck"
                                    class="btn btn-sm rounded-pill pxp-section-cta me-4 px-3 py-1 fs-6"
                                    target="_blank">Website</a>
                            </div>
                            <div class="d-inline-block">
                                <app-social-media [organization]="job.organization"></app-social-media>
                            </div>
                        </div>
                        <div class="mt-4 d-flex justify-content-center">
                            <div class="d-inline-block">
                                <a routerLink="/organisatie/{{job.organization.nameForUrl}}/{{job.organizationId}}"
                                    fragment="organisation-vacatures"
                                    class="btn btn-sm rounded-pill pxp-section-cta mt-3 mt-sm-0 px-3 py-1 fs-6"
                                    target="_blank">
                                    Alle vacatures
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 mt-lg-4 pxp-single-job-side-panel" *ngIf="job.spotlightName">
                        <app-volunteer-card [volunteer]="job"></app-volunteer-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="mt-100 pt-100 pb-100" style="background-color: var(--pxpSecondaryColorLight);">
    <div class="pxp-container">
        <h2 class="pxp-section-h2 text-center">Zij zijn vrijwilligers</h2>
        <app-volunteer-list [volunteers]="volunteers"></app-volunteer-list>
    </div>
</section>

<section class="mt-100" *ngIf="similarJobs && similarJobs.length > 0">
    <div class="pxp-container">
        <h2 class="pxp-section-h2 text-center">Misschien ook iets voor jou?</h2>

        <app-job-list [jobs$]="similarJobs" [col]="'col-md-4'"></app-job-list>

        <!-- <div class="row mt-4 mt-lg-5 justify-content-center p-0">
      <div class="col-auto p-0">
        <a href="vacatures.html" class="btn rounded-pill pxp-section-cta mt-3 mt-sm-0">Bekijk alle 16 vacatures</a>
      </div>
    </div> -->
    </div>
</section>

<app-google-job [job]="job"></app-google-job>
<app-registration [job]="job"></app-registration>