import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Style, Widget } from './model';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { JobService } from '../../services/job.service';
import { WidgetData } from '../../models/jobOfferWidgetData';

@Component({
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit {
  widget!: WidgetData;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private route: ActivatedRoute, private http: HttpClient, private jobService: JobService,) { }

  widget2: Widget = {
    "filters": {
      "name": "test",
      "sectors": [
        { "id": 1, "name": "Armoede" }
      ]
    },
    "display": {
      "title": "Zoek vrijwilligersvacatures in Genk",
      "worksOnDomains": ["http:\/\/genk.be", "https:\/\/genk.be", "http:\/\/www.genk.be", "https:\/\/www.genk.be", "http:\/\/genk.lcp.be", "https:\/\/genk.lcp.be", "http:\/\/www.genk.lcp.be", "https:\/\/www.genk.lcp.be"],
      "domNodeId": "vrijwilligerswerk",
      "maxResults": "100",
      "filters": {
        "searchTerm": true,
        "location": true,
        "sectors": true,
        "talents": true,
        "audiences": true,
        "themes": true,
        "collab": true,
        "frequency": true,
        "period": true,
        "timespan": true,
        "languages": true,
        "accessibility": true,
        "no_dutch": true,
        "practice_dutch": true
      }
    },
    "style": {
      "withCss": true,
      "maxHeight": "1500",
      "colors": {
        "background": {
          "color": "#f0fcc2",
          "alpha": 100
        },
        "overlay": {
          "color": "#efefef",
          "alpha": 100
        },
        "link": {
          "color": "#99bf0d",
          "alpha": 100
        },
        "tagBackground": {
          "color": "#cbe7f2",
          "alpha": 100
        },
        "tagText": {
          "color": "#386a80",
          "alpha": 100
        },
        "text": {
          "color": "#444444",
          "alpha": 100
        },
        "title": {
          "color": "#234757",
          "alpha": 100
        }
      }
    },
    "type": "search",
    "id": 57,
    "userId": 43,
    "script": "<script src=\"https:\/\/cdn.vrijwilligerswerk.be\/6521e868-648e-4dd5-b1f7-0211ce6b6cfa\/search\/v1\/widget.js?t=20211130112441\" defer><\/script>\n",
    "cdnUrlRoot": "https:\/\/cdn.vrijwilligerswerk.be",
    "apiKey": "e84abf5272d6c1408095f88e88ef4392",
    "indexName": "vrijwilligerswerk.be (master)",
    "cssUrl": "https:\/\/cdn.vrijwilligerswerk.be\/6521e868-648e-4dd5-b1f7-0211ce6b6cfa\/search\/v1\/widget.css?t=20211130112441"
  }

  ngOnInit(): void {
    this.route.data.subscribe(({ widget }) => {
      this.widget = widget;

      if (isPlatformBrowser(this.platformId)) {
        document.body.classList.add('overflow-x-hidden')

        document.documentElement.style.setProperty('--pxpMainColorLight', this.widget.style.colors.background.color);
        document.documentElement.style.setProperty('--widget-title', this.widget.style.colors.title.color);
        document.documentElement.style.setProperty('--pxpMainColorDark', this.widget.style.colors.link.color);
        document.documentElement.style.setProperty('--pxpMainColor', this.widget.style.colors.link.color);
      }
    });
    //  this.getJSON().subscribe(data => {
    //  this.widget = data;

    //  if (isPlatformBrowser(this.platformId)) {
    //    document.body.classList.add('overflow-x-hidden')

    //    document.documentElement.style.setProperty('--pxpMainColorLight', this.widget.style.colors.background.color);
    //    document.documentElement.style.setProperty('--widget-title', this.widget.style.colors.title.color);
    //    document.documentElement.style.setProperty('--pxpMainColorDark', this.widget.style.colors.link.color);
    //    document.documentElement.style.setProperty('--pxpMainColor', this.widget.style.colors.link.color);
    //  }
    //});
  }

  public getJSON(): Observable<any> {
    return of(JSON.parse(JSON.stringify(this.widget2)));
  }

}
