<section class="pxp-page-header-simple" style="background-color: var(--pxpMainColorLight)">
  <div class="pxp-container" *ngIf="searchForm">
    <h1>Vind vrijwilligerswerk</h1>
    <div class="pxp-hero-subtitle pxp-text-light">Zoek uit <strong>{{ totalJobs$ | async }}</strong> vacatures</div>
    <div class="pxp-hero-form pxp-hero-form-round pxp-large mt-3 mt-lg-4">
      <form [formGroup]="searchForm" class="row gx-3 align-items-center">
        <div class="col-12 col-lg">
          <div class="input-group mb-3 mb-lg-0">
            <span class="input-group-text"><span class="fa fa-search fs-5"></span></span>
            <input [formControl]="searchForm.name" [value]="searchForm.name.value" type="text" class="form-control fs-5"
                   placeholder="Wat? Zoekterm of interesse">
          </div>
        </div>
        <div class="col-12 col-lg pxp-has-left-border">
          <div class="input-group mb-3 mb-lg-0">
            <span class="input-group-text"><span class="fa-solid fa-earth-americas fs-5"></span></span>
            <input type="text" class="form-control fs-5" [formControl]="searchForm.post" [value]="formatLocation(searchForm.post.value)" [ngbTypeahead]="searchCity"
                   [inputFormatter]="formatter" [resultFormatter]="formatter" [editable]='false'  (selectItem)="selectCity($event)"
                   [placeholder]="getPlaceholder()">
          </div>
        </div>
        <div class="col-12 col-lg pxp-has-left-border">
          <div class="input-group mb-3 mb-lg-0">
            <span class="input-group-text"><span class="fa .a-folder fs-5"></span></span>
            <ng-multiselect-dropdown [placeholder]="'Sectoren'" [settings]="dropdownSettings"
                                     [data]="searchComponent? searchComponent.sectors : []" [formControl]="searchForm.sectors">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="col-12 col-lg-auto">
          <button type="submit">Zoeken</button>
        </div>
      </form>
    </div>
  </div>
</section>

<section class="mt-5">
  <div class="pxp-container">
    <div class="row">
      <app-search *ngIf="searchForm" [searchForm]="searchForm" [locationEnabled]="locationEnabled" (resetSecondForm)="resetForm()" (updateSecondForm)="updateForm($event)" [enableStorage]="true"></app-search>
    </div>
  </div>
</section>
