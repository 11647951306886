import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class FavoriteService {
    //add favorite to local storage
    addFavorite(id: number)  {
        let favorites = this.getFavorites();
        favorites.push(id);
        localStorage.setItem('favorites', JSON.stringify(favorites));
    }

    removeFavorite(id: number) {
        let favorites = this.getFavorites();
        let index = favorites.indexOf(id);
        if (index > -1) {
            favorites.splice(index, 1);
        }
        localStorage.setItem('favorites', JSON.stringify(favorites));
    }

    //get favorites from local storage
    getFavorites(): number[] {
        let favorites = localStorage.getItem('favorites');
        if (favorites) {
            return JSON.parse(favorites);
        }
        return [];
    }

    isFavorite(id: number): boolean {
        let favorites = this.getFavorites();
        return favorites.includes(id);
    }
}